// src/components/Administration/ThumbnailUpdate.js

import React, { useState } from 'react';
import axios from 'axios';
import { db, storage } from '../../config/firebase'; // Sicherstellen, dass 'storage' exportiert wird
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage Funktionen importieren
import { Button, Typography, Box, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import { v4 as uuidv4 } from 'uuid'; // Importiere uuidv4 für eindeutige Dateinamen

const ThumbnailUpdate = () => {
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  // Funktion zur korrekten Extraktion der Vimeo-ID aus der URL
  const extractVimeoId = (vimeoUrl) => {
    if (!vimeoUrl) {
      return null;
    }
    const regex = /vimeo\.com\/(\d+)/;  // Extrahiert die numerische Video-ID nach "vimeo.com/"
    const match = vimeoUrl.match(regex);
    return match ? match[1] : null;
  };

  const fetchThumbnails = async () => {
    try {
      setLoading(true);
      setStatus((prev) => [...prev, 'Starte Aktualisierung der Thumbnails...']);

      // Abrufen aller Videos aus der Firestore-Datenbank
      const videosSnapshot = await getDocs(collection(db, 'videos'));
      const videos = videosSnapshot.docs.map(doc => ({
        id: doc.id,
        vimeoUrl: doc.data().vimeoUrl || doc.data().vimeo_id, // Anpassung je nach Feldnamen
      }));

      // Prüfen, ob Videos gefunden wurden
      if (videos.length === 0) {
        setStatus((prev) => [...prev, '❌ Keine Videos in der Datenbank gefunden.']);
        setLoading(false);
        return;
      }

      // Für jedes Video Thumbnails abrufen und speichern
      for (const video of videos) {
        let vimeoId;
        try {
          vimeoId = extractVimeoId(video.vimeoUrl);

          if (!vimeoId) {
            setStatus((prev) => [
              ...prev,
              `❌ Fehler: Vimeo ID konnte aus der URL "${video.vimeoUrl}" nicht extrahiert werden.`,
            ]);
            continue;
          }

          // Aufruf der Cloud Function zum Abrufen des Thumbnails
          const response = await axios.post(
            'https://europe-west3-keytohorses-firebase.cloudfunctions.net/api/vimeo-thumbnail',
            { videoId: vimeoId }
          );

          if (response.data && response.data.thumbnailUrl) {
            const downloadURL = response.data.thumbnailUrl;

            // Überprüfen, ob der Download-URL gültig ist
            if (!downloadURL.startsWith('https://')) {
              setStatus((prev) => [
                ...prev,
                `❌ Fehler: Ungültiger Thumbnail-URL für Video ${vimeoId}.`,
              ]);
              continue;
            }

            // Schritt 1: Thumbnail-Bild herunterladen als Blob
            let imageBlob;
            try {
              const imageResponse = await axios.get(downloadURL, { responseType: 'blob' });
              imageBlob = imageResponse.data;
              console.log(`✅ Thumbnail für Video ${vimeoId} heruntergeladen.`);
            } catch (imageError) {
              setStatus((prev) => [
                ...prev,
                `❌ Fehler beim Herunterladen des Thumbnails für Video ${vimeoId}: ${imageError.message}`,
              ]);
              continue;
            }

            // Schritt 2: Einen eindeutigen Dateinamen erstellen
            const imageName = `${uuidv4()}_thumbnail.jpg`;

            // Schritt 3: Speicherreferenz erstellen mit videoId
            const thumbStorageRefObj = storageRef(storage, `thumbnails/${vimeoId}/${imageName}`);
            console.log(`Speicherpfad für Thumbnail: thumbnails/${vimeoId}/${imageName}`);

            // Schritt 4: Bild zu Firebase Storage hochladen
            try {
              await uploadBytes(thumbStorageRefObj, imageBlob);
              console.log(`✅ Thumbnail erfolgreich zu ${thumbStorageRefObj.fullPath} hochgeladen.`);
            } catch (uploadError) {
              setStatus((prev) => [
                ...prev,
                `❌ Fehler beim Hochladen des Thumbnails zu Firebase Storage für Video ${vimeoId}: ${uploadError.message}`,
              ]);
              continue;
            }

            // Schritt 5: Download-URL von Firebase Storage abrufen
            let firebaseThumbnailUrl;
            try {
              firebaseThumbnailUrl = await getDownloadURL(thumbStorageRefObj);
              console.log(`Firebase Thumbnail URL für Video ${vimeoId}: ${firebaseThumbnailUrl}`);
              // Da die Thumbnails jetzt öffentlich lesbar sind, ist keine weitere Überprüfung notwendig
            } catch (urlError) {
              setStatus((prev) => [
                ...prev,
                `❌ Fehler beim Abrufen der Firebase Storage URL für Video ${vimeoId}: ${urlError.message}`,
              ]);
              continue;
            }

            // Schritt 6: Speichern der Firebase Storage URL in Firestore
            try {
              const videoDocRef = doc(db, 'videos', video.id);
              await updateDoc(videoDocRef, {
                thumbnailUrl: firebaseThumbnailUrl, // Speichert die URL direkt
              });

              setStatus((prev) => [
                ...prev,
                `✅ Thumbnail für Video ${vimeoId} erfolgreich abgerufen, hochgeladen und in Firestore gespeichert.`,
              ]);
            } catch (firestoreError) {
              setStatus((prev) => [
                ...prev,
                `❌ Fehler beim Speichern der Thumbnail-URL in Firestore für Video ${vimeoId}: ${firestoreError.message}`,
              ]);
              continue;
            }

          } else {
            setStatus((prev) => [
              ...prev,
              `❌ Fehler: Keine Thumbnail-URL für Video ${vimeoId} erhalten.`,
            ]);
          }
        } catch (error) {
          if (vimeoId) {
            if (error.response && error.response.status === 404) {
              setStatus((prev) => [
                ...prev,
                `❌ Fehler: Video ${vimeoId} nicht gefunden (404).`,
              ]);
            } else if (error.response && error.response.status === 401) {
              setStatus((prev) => [
                ...prev,
                `❌ Fehler: Unauthorized - Zugriff verweigert.`,
              ]);
            } else {
              setStatus((prev) => [
                ...prev,
                `❌ Fehler beim Abrufen des Thumbnails für Video ${vimeoId}: ${error.message}`,
              ]);
            }
          } else {
            setStatus((prev) => [
              ...prev,
              `❌ Fehler beim Verarbeiten des Videos mit ID ${video.id}: ${error.message}`,
            ]);
          }
          continue;
        }
      }
    } catch (error) {
      console.error('Allgemeiner Fehler:', error);
      setStatus((prev) => [...prev, `❌ Allgemeiner Fehler: ${error.message}`]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: '2rem', maxWidth: '800px', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Thumbnail Update
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={fetchThumbnails}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        {loading ? 'Lädt...' : 'Thumbnails für alle Videos aktualisieren'}
      </Button>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Statusmeldungen:</Typography>
        <List>
          {status.map((msg, index) => (
            <ListItem key={index}>
              <ListItemText primary={msg} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ThumbnailUpdate;

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { ReactComponent as HorseIcon } from '../../assets/icons/horse.svg';
import LogoutIcon from '@mui/icons-material/Logout';

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [avatarUrl, setAvatarUrl] = React.useState(
    '/path/to/default/profile/picture.jpg'
  );
  const [isAdmin, setIsAdmin] = React.useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  React.useEffect(() => {
    const fetchUserProfileAndRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.role === 'admin') {
          setIsAdmin(true);
        }

        if (user.photoURL) {
          setAvatarUrl(user.photoURL);
        } else {
          try {
            const docRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const data = docSnap.data();
              if (data.profilePicturePath) {
                const storageRef = ref(storage, data.profilePicturePath);
                const url = await getDownloadURL(storageRef);
                setAvatarUrl(url);
              }
            }
          } catch (error) {
            console.error('Fehler beim Abrufen des Profilbildes:', error);
          }
        }
      } else {
        console.warn('Benutzer ist nicht angemeldet.');
      }
    };
    fetchUserProfileAndRole();
  }, [auth, db, storage]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = async (path) => {
    if (path === '/logout') {
      await auth.signOut();
      navigate('/');
    } else {
      navigate(path);
    }
    handleCloseUserMenu();
  };

  const pages = [
    { label: 'Startseite', path: '/' },
    { label: 'Lernplattform', path: '/videostage' },
    { label: 'Community', path: '/community' },
    ...(isAdmin ? [{ label: 'Administrator', path: '/admin' }] : []),
  ];

  const userSettings = [
    {
      label: 'Mein Profil',
      icon: <PersonIcon sx={{ color: '#d5bc8b' }} />,
      path: '/profile',
    },
    {
      label: 'Meine Pferde',
      icon: <HorseIcon sx={{ color: '#d5bc8b' }} />,
      path: '/profile?tab=horses',
    },
    {
      label: 'Mein Account',
      icon: <SettingsIcon sx={{ color: '#d5bc8b' }} />,
      path: '/profile?tab=account',
    },
  ];

  const logoutSetting = {
    label: 'Logout',
    icon: <LogoutIcon sx={{ color: '#d5bc8b' }} />,
    path: '/logout',
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        borderBottom: '1px solid #d5bc8b',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            src="/images/logos/logo-kth.webp"
            alt="Logo"
            sx={{
              display: { xs: 'none', md: 'flex' },
              height: 40,
              mr: 2,
            }}
          />

          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {/* Logo */}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page) => (
                <MenuItem key={page.label} onClick={() => navigate(page.path)}>
                  <Typography sx={{ textAlign: 'center' }}>
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            component="img"
            src="/images/logo-kth.webp"
            alt="Logo"
            sx={{
              display: { xs: 'flex', md: 'none' },
              height: 30,
              mr: 2,
            }}
          />

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {/* Logo */}
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'flex-end',
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.label}
                onClick={() => navigate(page.path)}
                sx={{ my: 2, color: 'white', display: 'block', mr: 3 }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={(e) => setAnchorElUser(e.currentTarget)}
                sx={{ p: 0 }}
              >
                <Avatar alt="Profilbild" src={avatarUrl} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {userSettings.map((setting) => (
                <MenuItem
                  key={setting.label}
                  onClick={() => handleMenuClick(setting.path)}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ mr: 2 }}>{setting.icon}</Box>
                    <Typography sx={{ textAlign: 'left' }}>
                      {setting.label}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
              <Divider sx={{ my: 1 }} />
              <MenuItem
                key={logoutSetting.label}
                onClick={() => handleMenuClick(logoutSetting.path)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ mr: 2 }}>{logoutSetting.icon}</Box>
                  <Typography sx={{ textAlign: 'left' }}>
                    {logoutSetting.label}
                  </Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;

// src/App.js

import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { AuthProvider, useAuth } from './components/context/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';

// Import der Seiten und Komponenten
import Login from './components/pages/Login';
import Dashboard from './components/pages/Community/Dashboard';
import AdminDashboard from './components/pages/Administrator/AdminDashboard';
import UserProfile from './components/pages/Community/UserProfile';
import JsonImport from './components/pages/Administrator/Jsonimport';
import VideoStage from './components/pages/Lernplattform/VideoStage';
import VideoPlayerPage from './components/pages/Lernplattform/VideoPlayerPage';
import VimeoAuthorization from './components/pages/VimeoAuthorization';
import OAuthCallback from './components/pages/OAuthCallback';
import ThumbnailsUpdate from './components/Administration/ThumnailUpdate';
import MapComponent from './components/pages/Community/User_map/MapComponent';
import Navbar from './components/layout/navbar';
import LogoutButton from './components/LogoutButton';
import ProtectedRoute from './components/ProtectedRoute';
import UserManagement from './components/Administration/UserManagement';
import AdminRoute from './components/Administration/AdminRoute';
import MultiStepForm from './components/Einschätzungstest/test_neu';
import './index.css';
import HostArea from './components/Administration/Livestream/HostArea';
import { UpdateDurationsButton } from './components/pages/Administrator/UpdateDurationsButton';

// Neue Seiten
import MeetingOverview from './components/pages/Livemeetings/MeetingOverview';
import MeetingManagement from './components/Administration/Livestream/MeetingManagement';
import CommunityPage from './components/pages/Community/CommunityPage';

// Optional: ErrorPage, falls genutzt
import ErrorPage from './components/pages/ErrorPage';

// react-dnd Provider
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const AppContent = () => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return (
      <div className="loading-spinner">
        <CircularProgress style={{ color: '#d5bc8b' }} />
      </div>
    );
  }

  // Navbar nicht auf der Login-Seite oder bestimmten anderen Seiten anzeigen
  const shouldShowNavbar =
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/CheckoutPrepare';

  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <div className="app">
          {shouldShowNavbar && <Navbar />}

          <Routes>
            {/* Login-Seite */}
            <Route path="/login" element={<Login />} />
            <Route path="/authorize" element={<VimeoAuthorization />} />
            <Route path="/callback" element={<OAuthCallback />} />
            <Route path="/update-thumbnails" element={<ThumbnailsUpdate />} />

            {/* Meetings */}
            <Route path="/meeting-overview" element={<MeetingOverview />} />
            <Route
              path="/admin/meeting-management"
              element={
                <AdminRoute>
                  <MeetingManagement />
                </AdminRoute>
              }
            />

            {/* Community */}
            <Route
              path="/community"
              element={
                <ProtectedRoute>
                  <CommunityPage />
                </ProtectedRoute>
              }
            />

            {/* Geschützte Routen */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            {/* Hier werden die Parameter horseId und horseName per Query-Parameter erwartet */}
            <Route
              path="/Einschaetzungstest"
              element={
                <ProtectedRoute>
                  <MultiStepForm />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dbtest"
              element={
                <ProtectedRoute>
                  <HostArea />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/jsonimport"
              element={
                <ProtectedRoute>
                  <JsonImport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/videostage"
              element={
                <ProtectedRoute>
                  <VideoStage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test"
              element={
                <ProtectedRoute>
                  <UpdateDurationsButton />
                </ProtectedRoute>
              }
            />

            {/* Video Player */}
            <Route
              path="/video/:videoId"
              element={
                <ProtectedRoute>
                  <VideoPlayerPage />
                </ProtectedRoute>
              }
            />

            {/* Admin User Management */}
            <Route
              path="/admin/user-management"
              element={
                <AdminRoute>
                  <UserManagement />
                </AdminRoute>
              }
            />

            {/* Kartenkomponente */}
            <Route
              path="/map"
              element={
                <AdminRoute>
                  <MapComponent />
                </AdminRoute>
              }
            />

            {/* Fehlerseite (optional) */}
            <Route path="/error" element={<ErrorPage />} />

            {/* Test-Route */}
            <Route
              path="/test-route"
              element={<div>Test Route funktioniert!</div>}
            />

            {/* Standardpfad */}
            <Route
              path="/"
              element={
                currentUser ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            {/* Fallback */}
            <Route
              path="*"
              element={<Navigate to={currentUser ? '/dashboard' : '/login'} />}
            />
          </Routes>
        </div>
      </Router>
    </DndProvider>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;

// src/components/pages/Community/UserDashboard_components/Post.js
import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../../config/firebase'; // Passe den Importpfad entsprechend an
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Collapse,
  Box,
  AvatarGroup,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import {
  Favorite,
  Comment,
  Edit,
  Delete,
  PhotoCamera,
  VideoCameraBack,
} from '@mui/icons-material';
import CommentSection from './CommentSection';
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  serverTimestamp,
  collection,
  getDocs,
} from 'firebase/firestore';
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from 'firebase/storage';
import { useAuth } from '../../../context/AuthContext'; // Passe den Importpfad entsprechend an
import { getUserData } from './userService';
import imageCompression from 'browser-image-compression'; // Für Bildkomprimierung

// Importiere date-fns für die Zeitformatierung
import { formatDistanceToNow, format } from 'date-fns';
import { de } from 'date-fns/locale';

const Post = ({ post, onDelete }) => {
  // onDelete als Prop hinzufügen
  const { currentUser } = useAuth();
  const [expanded, setExpanded] = useState(false);
  const [likes, setLikes] = useState(
    Array.isArray(post.likes) ? post.likes : []
  );
  const [authorAvatarURL, setAuthorAvatarURL] = useState('');
  const [userHasLiked, setUserHasLiked] = useState(false);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [likesWithProfilePics, setLikesWithProfilePics] = useState([]);
  const [commentCount, setCommentCount] = useState(0);

  // Zustände für das Bearbeiten des Beitrags
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedTitle, setEditedTitle] = useState(post.title);
  const [editedContent, setEditedContent] = useState(post.content);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [uploading, setUploading] = useState(false);

  const MAX_IMAGE_SIZE_MB = 20; // Max 20 MB für Bilder
  const MAX_VIDEO_SIZE_MB = 100; // Max 100 MB für Videos

  const isAuthor = currentUser && currentUser.uid === post.authorId;

  useEffect(() => {
    const fetchAuthorAvatar = async () => {
      if (post.authorAvatarPath) {
        try {
          const avatarRef = ref(storage, post.authorAvatarPath);
          const url = await getDownloadURL(avatarRef);
          setAuthorAvatarURL(url);
        } catch (error) {
          console.error('Fehler beim Abrufen des Profilbildes:', error);
        }
      }
    };
    fetchAuthorAvatar();
  }, [post.authorAvatarPath]);

  useEffect(() => {
    const fetchCurrentUserData = async () => {
      if (currentUser) {
        const data = await getUserData(currentUser.uid);
        setCurrentUserData(data);
      }
    };
    fetchCurrentUserData();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      if (Array.isArray(likes)) {
        const hasLiked = likes.some((like) => like.userId === currentUser.uid);
        setUserHasLiked(hasLiked);
      } else {
        setUserHasLiked(false);
      }
    }
  }, [likes, currentUser]);

  // Laden der Profilbilder der likenden Nutzer
  useEffect(() => {
    const fetchLikesProfilePictures = async () => {
      const updatedLikes = await Promise.all(
        likes.map(async (like) => {
          if (like.profilePicturePath) {
            try {
              const profilePictureRef = ref(storage, like.profilePicturePath);
              const profilePictureURL = await getDownloadURL(profilePictureRef);
              return { ...like, profilePictureURL };
            } catch (error) {
              console.error('Fehler beim Abrufen des Profilbildes:', error);
              return { ...like, profilePictureURL: '' };
            }
          } else {
            return { ...like, profilePictureURL: '' };
          }
        })
      );
      setLikesWithProfilePics(updatedLikes);
    };

    if (likes.length > 0) {
      fetchLikesProfilePictures();
    } else {
      setLikesWithProfilePics([]);
    }
  }, [likes]);

  // Anzahl der Kommentare abrufen
  useEffect(() => {
    const fetchCommentCount = async () => {
      const commentsRef = collection(db, 'posts', post.id, 'comments');
      const commentsSnapshot = await getDocs(commentsRef);
      setCommentCount(commentsSnapshot.size);
    };
    fetchCommentCount();
  }, [post.id]);

  const handleLike = async () => {
    if (!currentUser) {
      alert('Bitte melde dich an, um Beiträge zu liken.');
      return;
    }

    if (!currentUserData) {
      console.error('Benutzerdaten nicht verfügbar.');
      return;
    }

    const postRef = doc(db, 'posts', post.id);

    const userLikeData = {
      userId: currentUser.uid,
      firstName: currentUserData.firstName,
      lastName: currentUserData.lastName,
      profilePicturePath: currentUserData.profilePicturePath || '',
    };

    try {
      if (userHasLiked) {
        // Like entfernen
        await updateDoc(postRef, {
          likes: arrayRemove(userLikeData),
        });
        setLikes(likes.filter((like) => like.userId !== currentUser.uid));
      } else {
        // Like hinzufügen
        await updateDoc(postRef, {
          likes: arrayUnion(userLikeData),
        });
        setLikes([...likes, userLikeData]);
      }
      setUserHasLiked(!userHasLiked);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Likes:', error);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const fullName =
    `${post.authorFirstName || ''} ${post.authorLastName || ''}`.trim();

  // Farbe für das Herzchen, wenn geliked
  const heartColor = userHasLiked ? '#e63946' : 'inherit';

  // Zeitformatierung
  const getFormattedTimestamp = () => {
    const now = new Date();
    const postDate = post.timestamp?.toDate();

    if (!postDate) return '';

    const diffInSeconds = (now - postDate) / 1000;

    if (diffInSeconds < 60) {
      return 'gepostet vor wenigen Sekunden';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `gepostet vor ${minutes} Minute${minutes !== 1 ? 'n' : ''}`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `gepostet vor ${hours} Stunde${hours !== 1 ? 'n' : ''}`;
    } else {
      return format(postDate, 'Pp', { locale: de });
    }
  };

  // Funktionen für die Auswahl von Bild und Video
  const handleImageSelect = (file) => {
    if (file && file.size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
      alert(
        `Bitte wähle ein Bild, das kleiner als ${MAX_IMAGE_SIZE_MB} MB ist.`
      );
      return;
    }
    setSelectedImage(file);
  };

  const handleVideoSelect = (file) => {
    if (file && file.size / 1024 / 1024 > MAX_VIDEO_SIZE_MB) {
      alert(
        `Bitte wähle ein Video, das kleiner als ${MAX_VIDEO_SIZE_MB} MB ist.`
      );
      return;
    }
    setSelectedVideo(file);
  };

  const handleImageUpload = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error('Fehler bei der Bildkomprimierung:', error);
      return null;
    }
  };

  const handleEditSubmit = async () => {
    if (!editedContent.trim() && !selectedImage && !selectedVideo) {
      alert(
        'Bitte füge Inhalt, ein Bild oder ein Video zu deinem Beitrag hinzu.'
      );
      return;
    }

    setUploading(true);

    try {
      const postRef = doc(db, 'posts', post.id);
      let imageUrl = post.imageUrl || '';
      let videoUrl = post.videoUrl || '';

      // Altes Bild löschen, wenn neues hochgeladen wird
      if (selectedImage && post.imageUrl) {
        const oldImageRef = ref(storage, post.imageUrl);
        await deleteObject(oldImageRef);
        imageUrl = '';
      }

      // Altes Video löschen, wenn neues hochgeladen wird
      if (selectedVideo && post.videoUrl) {
        const oldVideoRef = ref(storage, post.videoUrl);
        await deleteObject(oldVideoRef);
        videoUrl = '';
      }

      // Neues Bild hochladen
      if (selectedImage) {
        const compressedImage = await handleImageUpload(selectedImage);
        if (compressedImage) {
          const imageRef = ref(
            storage,
            `posts/${currentUser.uid}/images/${Date.now()}_${compressedImage.name}`
          );
          const snapshot = await uploadBytes(imageRef, compressedImage);
          imageUrl = await getDownloadURL(snapshot.ref);
        }
      }

      // Neues Video hochladen
      if (selectedVideo) {
        const videoRef = ref(
          storage,
          `posts/${currentUser.uid}/videos/${Date.now()}_${selectedVideo.name}`
        );
        const snapshot = await uploadBytes(videoRef, selectedVideo);
        videoUrl = await getDownloadURL(snapshot.ref);
      }

      // Beitrag aktualisieren
      await updateDoc(postRef, {
        title: editedTitle,
        content: editedContent,
        imageUrl: imageUrl,
        videoUrl: videoUrl,
        timestamp: serverTimestamp(), // Aktualisiere den Zeitstempel
      });

      // Zustände aktualisieren
      setEditDialogOpen(false);
      setSelectedImage(null);
      setSelectedVideo(null);
      setEditedTitle(editedTitle);
      setEditedContent(editedContent);

      // Beitrag im lokalen Zustand aktualisieren
      post.title = editedTitle;
      post.content = editedContent;
      post.imageUrl = imageUrl;
      post.videoUrl = videoUrl;
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Beitrags:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      'Möchtest du diesen Beitrag wirklich löschen?'
    );
    if (!confirmDelete) return;

    try {
      const postRef = doc(db, 'posts', post.id);

      // Medien löschen
      if (post.imageUrl) {
        const imageRef = ref(storage, post.imageUrl);
        await deleteObject(imageRef);
      }

      if (post.videoUrl) {
        const videoRef = ref(storage, post.videoUrl);
        await deleteObject(videoRef);
      }

      // Beitrag löschen
      await deleteDoc(postRef);

      // Rufe die onDelete Callback-Funktion auf, um den Beitrag aus dem NewsFeed zu entfernen
      if (onDelete && typeof onDelete === 'function') {
        onDelete(post.id);
      }
    } catch (error) {
      console.error('Fehler beim Löschen des Beitrags:', error);
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 1400,
        margin: '20px auto',
        backgroundColor: '#252833', // Blauer Hintergrund
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)', // Leichter Schatten zur Hervorhebung
        borderRadius: '20px', // Stärkere Abrundung der Ecken
        color: '#fff', // Weißer Text für bessere Lesbarkeit
        transition: 'transform 0.3s ease', // Für Parallax-Effekt
        '&:hover': {
          transform: 'translateY(-5px)', // Subtiler Parallax-Effekt beim Hover
        },
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            src={authorAvatarURL}
            alt={fullName}
            sx={{ bgcolor: '#d5bc8b' }}
          >
            {post.authorFirstName?.charAt(0)}
          </Avatar>
        }
        action={
          isAuthor && (
            <>
              <IconButton
                onClick={() => setEditDialogOpen(true)}
                aria-label="Bearbeiten"
                sx={{ color: '#d5bc8b' }}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={handleDelete}
                aria-label="Löschen"
                sx={{ color: '#d5bc8b' }}
              >
                <Delete />
              </IconButton>
            </>
          )
        }
        title={
          <Typography variant="h6" sx={{ color: '#d5bc8b' }}>
            {fullName || 'Anonym'}
          </Typography>
        }
        subheader={
          <Typography variant="body2" sx={{ color: '#fff' }}>
            {getFormattedTimestamp()}
          </Typography>
        }
      />
      <CardContent>
        {post.title && (
          <Typography
            variant="h6"
            component="div"
            sx={{ mb: 1, color: '#d5bc8b' }}
          >
            {post.title}
          </Typography>
        )}
        <Typography variant="body1" color="inherit">
          {post.content}
        </Typography>

        {/* Bild anzeigen */}
        {post.imageUrl && (
          <Box sx={{ mt: 2 }}>
            <img
              src={post.imageUrl}
              alt="Post Bild"
              style={{
                width: '100%',
                maxHeight: '500px',
                objectFit: 'contain',
                borderRadius: '12px',
              }}
            />
          </Box>
        )}

        {/* Video anzeigen */}
        {post.videoUrl && (
          <Box sx={{ mt: 2 }}>
            <video
              controls
              src={post.videoUrl}
              style={{
                width: '100%',
                maxHeight: '500px',
                objectFit: 'contain',
                borderRadius: '12px',
              }}
            />
          </Box>
        )}
      </CardContent>
      <CardActions disableSpacing sx={{ borderTop: '1px solid #3f4b54' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={handleLike}
            aria-label="Like"
            sx={{ color: heartColor }}
          >
            <Favorite />
          </IconButton>
          <Typography>{likes.length}</Typography>
          {/* AvatarGroup für die likenden Nutzer */}
          {likesWithProfilePics.length > 0 && (
            <AvatarGroup
              max={3}
              sx={{
                ml: 2,
                '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 12 },
              }}
              onClick={handleAvatarClick}
            >
              {likesWithProfilePics.slice(0, 3).map((like) => (
                <Avatar
                  key={like.userId}
                  src={like.profilePictureURL}
                  alt={`${like.firstName} ${like.lastName}`}
                  sx={{ cursor: 'pointer' }}
                >
                  {like.firstName?.charAt(0)}
                </Avatar>
              ))}
            </AvatarGroup>
          )}
        </Box>

        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <Typography
            onClick={handleExpandClick}
            sx={{ cursor: 'pointer', mr: 1, color: '#fff' }}
          >
            {commentCount}
          </Typography>
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Kommentare anzeigen"
            sx={{ color: '#d5bc8b' }}
          >
            <Comment />
          </IconButton>
        </Box>
      </CardActions>

      {/* Popover für die Liste der likenden Nutzer */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {likesWithProfilePics.map((like) => (
            <ListItem key={like.userId}>
              <ListItemAvatar>
                <Avatar
                  src={like.profilePictureURL}
                  alt={`${like.firstName} ${like.lastName}`}
                >
                  {like.firstName?.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${like.firstName} ${like.lastName}`} />
            </ListItem>
          ))}
        </List>
      </Popover>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CommentSection postId={post.id} />
      </Collapse>

      {/* Bearbeiten-Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Beitrag bearbeiten</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Titel"
            type="text"
            fullWidth
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            sx={{ input: { color: '#fff' }, label: { color: '#d5bc8b' } }}
          />
          <TextField
            margin="dense"
            label="Inhalt"
            type="text"
            fullWidth
            multiline
            minRows={4}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            sx={{ input: { color: '#fff' }, label: { color: '#d5bc8b' } }}
          />

          {/* Bild-Upload */}
          <Button
            variant="contained"
            component="label"
            startIcon={<PhotoCamera />}
            sx={{ mt: 2, mr: 2 }}
          >
            Neues Bild hochladen
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={(e) => handleImageSelect(e.target.files[0])}
            />
          </Button>

          {/* Video-Upload */}
          <Button
            variant="contained"
            component="label"
            startIcon={<VideoCameraBack />}
            sx={{ mt: 2 }}
          >
            Neues Video hochladen
            <input
              type="file"
              accept="video/*"
              hidden
              onChange={(e) => handleVideoSelect(e.target.files[0])}
            />
          </Button>

          {/* Anzeige des ausgewählten Bildes */}
          {selectedImage && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Ausgewähltes Bild: {selectedImage.name}
            </Typography>
          )}

          {/* Anzeige des ausgewählten Videos */}
          {selectedVideo && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Ausgewähltes Video: {selectedVideo.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setEditDialogOpen(false)}
            sx={{ color: '#d5bc8b' }}
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleEditSubmit}
            variant="contained"
            disabled={uploading}
            sx={{ backgroundColor: '#d5bc8b' }}
          >
            {uploading ? 'Wird aktualisiert...' : 'Speichern'}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Post;

// src/components/pages/Community/CommunityPage.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import NewsFeed from './UserDashboard_components/NewsFeed';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  padding: '20px',
  alignItems: 'center',
});

const MainContent = styled(Box)({
  flex: '1',
  maxWidth: '1200px', // Breitere Anzeige für den NewsFeed
  width: '100%',
  padding: '20px',
});

const CommunityPage = () => {
  return (
    <Container>
      <Typography variant="h4" sx={{ color: '#d5bc8b', mb: 3 }}>
        Community
      </Typography>
      <MainContent>
        <NewsFeed />
      </MainContent>
    </Container>
  );
};

export default CommunityPage;

// src/components/Administration/CustomNode.js
import React from 'react';
import { Handle } from 'reactflow';
import styled from 'styled-components';
import { FaQuestionCircle, FaLink } from 'react-icons/fa'; // Importiere das Fragesymbol und ein Verknüpfungssymbol
import { colors } from '../../../styles/colors'; // Importiere die Farbkonstanten

// Styled Component für den benutzerdefinierten Knoten
const NodeContainer = styled.div`
  padding: 10px;
  border: 2px solid ${colors.gold}; /* Rahmenfarbe auf Gold gesetzt */
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? colors.darkBlue : colors.background)};
  text-align: center;
  cursor: pointer;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${(props) => (props.selected ? '0 0 15px rgba(213, 188, 139, 0.7)' : '0 2px 5px rgba(0, 0, 0, 0.1)')};
  user-select: none; /* Verhindert das Selektieren von Text beim Ziehen */
  transition: background-color 0.3s, box-shadow 0.3s;
  position: relative; /* Für das Positionieren des Symbols */

  &:hover {
    box-shadow: 0 0 15px rgba(213, 188, 139, 0.7);
  }
`;

const IconContainer = styled.div`
  margin-bottom: 8px; /* Abstand zwischen Symbol und Text */
  color: ${colors.gold}; /* Farbe des Symbols */
  font-size: 24px; /* Größe des Symbols */
`;

const NodeLabel = styled.div`
  font-weight: bold;
  color: ${colors.white}; /* Weißer Text */
  margin-bottom: 5px;
`;

const NodeInfo = styled.div`
  font-size: 12px;
  color: ${colors.lightGold};
`;

const LinkIcon = styled(FaLink)`
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${colors.gold};
  font-size: 14px;
  display: ${(props) => (props.hasLinks ? 'block' : 'none')};
`;

const CustomNode = ({ data, id, selected, hasLinks }) => {
  return (
    <NodeContainer selected={selected}>
      <IconContainer>
        <FaQuestionCircle />
      </IconContainer>
      <NodeLabel>{data.label}</NodeLabel>
      {data.maxSelectableAnswers && (
        <NodeInfo>
          Max. Antworten: {data.maxSelectableAnswers}
        </NodeInfo>
      )}
      <LinkIcon hasLinks={hasLinks} title="Verknüpfte Fragen vorhanden" />
      <Handle
        type="target"
        position="top"
        style={{ background: colors.gold }} /* Goldene Handles */
      />
      <Handle
        type="source"
        position="bottom"
        style={{ background: colors.gold }} /* Goldene Handles */
      />
    </NodeContainer>
  );
};

export default CustomNode;

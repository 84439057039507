import React, { useState, useEffect } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as HorseIcon } from '../../../assets/icons/horse.svg';
import PersonIcon from '@mui/icons-material/Person';
import EditUserProfile from './UserProfile_components/EditUserProfile';
import MyHorses from './UserProfile_components/MyHorses';
import ProfileImageUploader from './UserProfile_components/ProfileImageUploader';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ForumIcon from '@mui/icons-material/Forum';
import MySubscription from './UserProfile_components/MySubscription';
import { useLocation } from 'react-router-dom'; // WICHTIG

const drawerWidth = 240;

const DrawerContainer = styled(Box)({
  display: 'flex',
});

const AnimatedListItem = styled(({ selected, ...other }) => (
  <ListItem {...other} />
))(({ selected }) => ({
  margin: '4px 0',
  transition: '0.3s',
  backgroundColor: selected ? 'rgba(213, 188, 139, 0.1)' : 'transparent',
  color: selected ? '#d5bc8b' : 'inherit',
  '&:hover': {
    color: '#d5bc8b',
    transform: 'scale(1.05)',
    backgroundColor: 'rgba(213, 188, 139, 0.05)',
  },
}));

const UserProfile = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [content, setContent] = useState(<EditUserProfile />);
  const [avatarUrl, setAvatarUrl] = useState(
    '/path/to/default/profile/picture.jpg'
  );
  const [imageUploaderOpen, setImageUploaderOpen] = useState(false);

  const location = useLocation();

  // Effekt zum Auslesen des URL-Parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab === 'horses') {
      setSelectedIndex(1);
      setContent(<MyHorses />);
    } else if (tab === 'account') {
      // Wenn der Tab "account" gesetzt ist, MySubscription anzeigen
      setSelectedIndex(2);
      setContent(<MySubscription />);
    }
  }, [location]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    switch (index) {
      case 0:
        setContent(
          <EditUserProfile
            avatarUrl={avatarUrl}
            onImageUploaderOpen={() => setImageUploaderOpen(true)}
          />
        );
        break;
      case 1:
        setContent(<MyHorses />);
        break;
      case 2:
        setContent(<MySubscription />);
        break;
      case 3:
        //setContent(<Privacy />);
        break;
      case 4:
        //setContent(<CommunityContributions />);
        break;
      default:
        setContent(
          <EditUserProfile
            avatarUrl={avatarUrl}
            onImageUploaderOpen={() => setImageUploaderOpen(true)}
          />
        );
    }
  };

  const handleImageUpload = (url) => {
    setAvatarUrl(url);
    setImageUploaderOpen(false);
  };

  const drawer = (
    <div>
      <Divider />
      <List>
        <AnimatedListItem
          button
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <PersonIcon
              style={{ stroke: '#d5bc8b', fill: 'none', strokeWidth: 2 }}
            />
          </ListItemIcon>
          <ListItemText primary="Mein Profil" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <HorseIcon style={{ stroke: '#d5bc8b', fill: 'none' }} />
          </ListItemIcon>
          <ListItemText primary="Meine Pferde" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemIcon>
            <SubscriptionsIcon style={{ color: '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Mein Abonnement" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <PrivacyTipIcon style={{ color: '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Datenschutz" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
        >
          <ListItemIcon>
            <ForumIcon style={{ color: '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Communitybeiträge" />
        </AnimatedListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <DrawerContainer
      sx={{
        backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            marginTop: '79px',
            backgroundColor: '#252833',
            color: '#fff',
          },
        }}
      >
        {drawer}
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {content}
      </Box>

      <ProfileImageUploader
        open={imageUploaderOpen}
        onClose={() => setImageUploaderOpen(false)}
        onImageUpload={handleImageUpload}
      />
    </DrawerContainer>
  );
};

export default UserProfile;

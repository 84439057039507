// src/styles/colors.js
export const colors = {
    gold: '#D5BC8B',        // Original Goldton
    darkGold: '#B8860B',    // Kräftigeres Gold
    lightGold: '#FFD700',   // Helleres Gold (für andere Zwecke)
    darkBlue: '#252833',    // Dunkles Blau für Eingabefelder und Modal Hintergrund
    darkerBlue: '#1E2128',  // Dunkleres Blau für die Kopfzeile
    white: '#FFFFFF',
    grey: '#A0A0A0',
    darkGrey: '#707070',
    red: '#FF4C4C',
    darkRed: '#CC0000',
    background: '#252833',  // Dunkles Blau
    darkBlueText: '#1E2128', // Dunkelblauer Text für Edge-Labels
  };
  
// src/components/pages/Lernplattform/VideoCard.js

import React from 'react';
import {
  Card,
  CardMedia,
  Typography,
  IconButton,
  Chip,
  Tooltip,
  Box,
} from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

// Gestylter Card-Component mit subtilerem Glow-Effekt beim Hover
const StyledVideoCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  transition: 'box-shadow 0.5s ease-in-out',
  height: 150,
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: '#252833',
  borderRadius: theme.spacing(2),
  color: '#FFFFFF',
  '&:hover': {
    boxShadow: '0 0 8px 2px rgba(214,188,139,0.4)', // Subtilerer Glow
  },
}));

const VideoCard = ({ video, onClick, isBookmarked, onBookmarkToggle }) => {
  const formatDuration = (durationInSeconds) => {
    if (!durationInSeconds || isNaN(durationInSeconds)) return '00:00';
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    const hoursDisplay = hours > 0 ? `${hours}:` : '';
    const minutesDisplay =
      minutes < 10 && hours > 0 ? `0${minutes}:` : `${minutes}:`;
    const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;

    return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`;
  };

  return (
    <motion.div
      whileHover={{ scale: 1.01 }} // Minimaler Scale-Effekt
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <StyledVideoCard onClick={onClick}>
        <Box sx={{ position: 'relative', flexShrink: 0 }}>
          <CardMedia
            component="img"
            sx={{
              width: 200,
              height: '100%',
              objectFit: 'cover',
              borderTopLeftRadius: (theme) => theme.spacing(2),
              borderBottomLeftRadius: (theme) => theme.spacing(2),
            }}
            image={
              video.thumbnailUrl ||
              'https://via.placeholder.com/300x200.png?text=Video+Thumbnail'
            }
            alt={video.video_titel}
            onClick={onClick}
          />
          {video.duration && (
            <Chip
              label={formatDuration(video.duration)}
              size="small"
              sx={{
                position: 'absolute',
                bottom: 8,
                left: 8,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: '#fff',
              }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            padding: (theme) => theme.spacing(2),
            position: 'relative',
            backgroundColor: '#252833',
            borderTopRightRadius: (theme) => theme.spacing(2),
            borderBottomRightRadius: (theme) => theme.spacing(2),
          }}
          onClick={onClick}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 'bold', flexGrow: 1, color: '#d6bc8b' }}
            >
              {video.video_titel}
            </Typography>
            <Tooltip
              title={
                isBookmarked
                  ? 'Entfernen aus Lesezeichen'
                  : 'Zu Lesezeichen hinzufügen'
              }
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onBookmarkToggle();
                }}
                aria-label="bookmark"
                sx={{
                  color: isBookmarked ? '#d6bc8b' : '#FFFFFF',
                }}
              >
                {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
              </IconButton>
            </Tooltip>
          </Box>

          <Typography
            variant="body2"
            sx={{
              mt: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              color: '#FFFFFF',
            }}
          >
            {video.text}
          </Typography>
        </Box>
      </StyledVideoCard>
    </motion.div>
  );
};

VideoCard.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    video_titel: PropTypes.string.isRequired,
    text: PropTypes.string,
    vimeo_id: PropTypes.string,
    score: PropTypes.number,
    thumbnailUrl: PropTypes.string,
    duration: PropTypes.number,
    description: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  onBookmarkToggle: PropTypes.func.isRequired,
};

export default VideoCard;

// CreatePost.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Avatar,
  LinearProgress,
  Typography,
  Autocomplete,
  Chip,
  Stack,
} from '@mui/material';
import { PhotoCamera, VideoCameraBack } from '@mui/icons-material';
import { db, storage } from '../../../../config/firebase';
import { addDoc, collection, serverTimestamp, getDocs, query } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../../context/AuthContext';
import { getUserData } from './userService';
import imageCompression from 'browser-image-compression';

const CreatePost = ({ onPostCreated }) => {
  const [open, setOpen] = useState(false);
  const [postContent, setPostContent] = useState('');
  const [postTitle, setPostTitle] = useState('');
  const [userData, setUserData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [mainTags, setMainTags] = useState([]);
  const [selectedMainTags, setSelectedMainTags] = useState([]);
  const [subTags, setSubTags] = useState([]);
  const { currentUser: user } = useAuth();

  const MAX_IMAGE_SIZE_MB = 5;
  const MAX_VIDEO_SIZE_MB = 50;

  // Benutzerinformationen abrufen
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const data = await getUserData(user.uid);
        setUserData(data);
      }
    };
    fetchUserData();
  }, [user]);

  // Haupt-Tags aus Firestore abrufen
  useEffect(() => {
    const fetchMainTags = async () => {
      try {
        const tagsQuery = query(collection(db, 'mainTags'));
        const tagsSnapshot = await getDocs(tagsQuery);
        const tags = tagsSnapshot.docs.map(doc => ({ label: doc.data().name, id: doc.id }));
        console.log('Abgerufene Haupt-Tags:', tags); // Debug-Log
        setMainTags(tags);
      } catch (error) {
        console.error('Fehler beim Abrufen der Haupt-Tags:', error);
      }
    };
    fetchMainTags();
  }, []);

  const handleOpen = () => {
    if (!user) {
      alert('Bitte melde dich an, um einen Beitrag zu erstellen.');
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPostContent('');
    setPostTitle('');
    setSelectedImage(null);
    setSelectedVideo(null);
    setUploadProgress(0);
    setSelectedMainTags([]);
    setSubTags([]);
  };

  const handleImageSelect = (file) => {
    if (file && file.size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
      alert(`Bitte wähle ein Bild, das kleiner als ${MAX_IMAGE_SIZE_MB} MB ist.`);
      return;
    }
    setSelectedImage(file);
  };

  const handleVideoSelect = (file) => {
    if (file && file.size / 1024 / 1024 > MAX_VIDEO_SIZE_MB) {
      alert(`Bitte wähle ein Video, das kleiner als ${MAX_VIDEO_SIZE_MB} MB ist.`);
      return;
    }
    setSelectedVideo(file);
  };

  const handleImageUpload = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error('Fehler bei der Bildkomprimierung:', error);
      return null;
    }
  };

  const uploadFileWithProgress = (file, folderPath) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, folderPath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Upload-Fehler:', error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handlePostSubmit = async () => {
    // Validierung
    if (selectedMainTags.length === 0) {
      alert('Bitte wähle mindestens einen Haupt-Tag aus.');
      return;
    }

    if (!postTitle.trim()) {
      alert('Bitte gib einen Titel für deinen Beitrag ein.');
      return;
    }

    if (!postContent.trim() && !selectedImage && !selectedVideo) {
      alert('Bitte füge Inhalt, ein Bild oder ein Video zu deinem Beitrag hinzu.');
      return;
    }

    setUploading(true);

    try {
      let imageUrl = '';
      let videoUrl = '';

      // Bild hochladen, falls ausgewählt
      if (selectedImage) {
        const compressedImage = await handleImageUpload(selectedImage);
        if (compressedImage) {
          imageUrl = await uploadFileWithProgress(
            compressedImage,
            `posts/${user.uid}/images/${Date.now()}_${compressedImage.name}`
          );
        }
      }

      // Video hochladen, falls ausgewählt
      if (selectedVideo) {
        videoUrl = await uploadFileWithProgress(
          selectedVideo,
          `posts/${user.uid}/videos/${Date.now()}_${selectedVideo.name}`
        );
      }

      // Beitrag in Firestore speichern
      await addDoc(collection(db, 'posts'), {
        title: postTitle,
        content: postContent,
        timestamp: serverTimestamp(),
        authorId: user.uid,
        authorFirstName: userData?.firstName || 'Anonym',
        authorLastName: userData?.lastName || '',
        authorAvatarPath: userData?.profilePicturePath || '',
        likes: [],
        imageUrl: imageUrl || '',
        videoUrl: videoUrl || '',
        mainTags: selectedMainTags.map(tag => tag.label), // Array von String-Tags
        subTags: subTags, // Array von String-Sub-Tags
      });

      // Nach dem Erstellen des Beitrags die Modal schließen und den NewsFeed aktualisieren
      handleClose();
      if (onPostCreated) {
        onPostCreated();
      }
    } catch (error) {
      console.error('Fehler beim Erstellen des Beitrags:', error);
      alert('Beim Erstellen des Beitrags ist ein Fehler aufgetreten. Bitte versuche es erneut.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      {/* Beitrags-Eingabe-Feld */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          border: '1px solid #ccc',
          borderRadius: 2,
          cursor: 'pointer',
        }}
        onClick={handleOpen}
      >
        <Avatar
          src={userData?.profilePictureURL}
          alt={`${userData?.firstName} ${userData?.lastName}`}
          sx={{ mr: 2 }}
        >
          {userData?.firstName?.charAt(0)}
        </Avatar>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Was gibt's Neues?"
          InputProps={{
            readOnly: true,
            sx: {
              color: '#d5bc8b',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d5bc8b',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d5bc8b',
              },
              '& input::placeholder': {
                color: '#d5bc8b',
                opacity: 1,
              },
            },
          }}
          onClick={handleOpen}
        />
      </Box>

      {/* Dialog zum Erstellen eines neuen Beitrags */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Neuen Beitrag erstellen</DialogTitle>
        <DialogContent>
          {/* Titel-Textfeld */}
          <TextField
            autoFocus
            margin="dense"
            label="Titel"
            type="text"
            fullWidth
            value={postTitle}
            onChange={(e) => setPostTitle(e.target.value)}
            sx={{ mt: 2 }}
          />

          {/* Inhalts-Textfeld */}
          <TextField
            margin="dense"
            label="Dein Beitrag"
            type="text"
            fullWidth
            multiline
            minRows={4}
            value={postContent}
            onChange={(e) => setPostContent(e.target.value)}
            sx={{ mt: 2 }}
          />

          {/* Haupt-Tags Auswahl */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Posten in:
            </Typography>
            <Autocomplete
              multiple
              options={mainTags}
              getOptionLabel={(option) => option.label}
              value={selectedMainTags}
              onChange={(event, newValue) => {
                setSelectedMainTags(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.label}
                    {...getTagProps({ index })}
                    key={option.id}
                    sx={{
                      backgroundColor: selectedMainTags.some(selected => selected.id === option.id) ? '#d5bc8b' : '#3f4b54',
                      color: selectedMainTags.some(selected => selected.id === option.id) ? '#000' : '#fff',
                      '&:hover': {
                        backgroundColor: selectedMainTags.some(selected => selected.id === option.id) ? '#d5bc8b' : '#3f4b54',
                      },
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Wähle mindestens einen Haupt-Tag aus"
                />
              )}
            />
          </Box>

          {/* Sub-Tags Eingabe */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Sub-Tags:
            </Typography>
            <Autocomplete
              multiple
              freeSolo
              options={[]} // Keine vordefinierten Optionen
              value={subTags}
              onChange={(event, newValue) => {
                // Entfernt doppelte Sub-Tags
                const uniqueTags = Array.from(new Set(newValue));
                setSubTags(uniqueTags);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    key={`${option}-${index}`}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Füge Sub-Tags hinzu"
                />
              )}
            />
          </Box>

          {/* Bild-Upload */}
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
              sx={{ mr: 2 }}
            >
              Bild hochladen
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => handleImageSelect(e.target.files[0])}
              />
            </Button>

            {/* Video-Upload */}
            <Button
              variant="contained"
              component="label"
              startIcon={<VideoCameraBack />}
            >
              Video hochladen
              <input
                type="file"
                accept="video/*"
                hidden
                onChange={(e) => handleVideoSelect(e.target.files[0])}
              />
            </Button>
          </Box>

          {/* Bild- und Video-Vorschau */}
          {selectedImage && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">Bildvorschau:</Typography>
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Vorschau"
                style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
              />
            </Box>
          )}

          {selectedVideo && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">Videovorschau:</Typography>
              <video
                controls
                style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
              >
                <source src={URL.createObjectURL(selectedVideo)} type={selectedVideo.type} />
                Dein Browser unterstützt kein Video-Tag.
              </video>
            </Box>
          )}

          {/* Ladebalken für den Upload */}
          {uploading && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">Upload-Fortschritt:</Typography>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" sx={{ mt: 1 }}>
                {Math.round(uploadProgress)}%
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button
            onClick={handlePostSubmit}
            variant="contained"
            disabled={uploading}
          >
            {uploading ? 'Wird hochgeladen...' : 'Veröffentlichen'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreatePost;

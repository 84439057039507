// src/components/Einschätzungstest/test_neu.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
} from '@mui/material';
import axios from 'axios';
import { loadLiveTestFromDB } from '../../firebaseFunctions'; // Pfad prüfen
import { styled } from '@mui/material/styles';
import { CheckCircle } from '@mui/icons-material';
import ButtonBase from '@mui/material/ButtonBase';
import { ReactComponent as HorseIcon } from '../../assets/icons/horse.svg'; // Pfad prüfen
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  getDocs,
} from 'firebase/firestore';

import { useAuth } from '../context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useHorses from '../context/useHorses'; // Falls genutzt

const db = getFirestore();

const AnswerCard = styled(Card)(({ theme, selected }) => ({
  position: 'relative',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  transition: 'transform 0.3s, box-shadow 0.3s',
  transform: selected ? 'scale(1.05)' : 'scale(1)',
  boxShadow: selected ? theme.shadows[6] : theme.shadows[2],
  border: selected ? `2px solid ${theme.palette.primary.main}` : 'none',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[4],
  },
}));

const StyledButtonBase = styled(ButtonBase)({
  width: '100%',
  height: '100%',
  textAlign: 'left',
});

const SelectedIcon = styled(CheckCircle)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.primary.main,
  fontSize: 32,
}));

const GoldHorseIcon = styled(HorseIcon)(({ theme }) => ({
  width: 60,
  height: 60,
  fill: '#d5bc8b',
}));

const MultiStepForm = () => {
  const [testData, setTestData] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [questionHistory, setQuestionHistory] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [videoRecommendations, setVideoRecommendations] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [saveProgressDialogOpen, setSaveProgressDialogOpen] = useState(false);
  const [status, setStatus] = useState('');

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { horses, loadingHorses, error: horsesError } = useHorses();
  const [showHorseSelection, setShowHorseSelection] = useState(false);
  const [selectedHorse, setSelectedHorse] = useState('');

  const horseId = searchParams.get('horseId');
  const horseName = searchParams.get('horseName');

  useEffect(() => {
    if (!horseId || !horseName) {
      setShowHorseSelection(true);
    }
  }, [horseId, horseName]);

  useEffect(() => {
    if (horseId && horseName) {
      const fetchLiveTest = async () => {
        try {
          const liveTest = await loadLiveTestFromDB();
          if (liveTest) {
            setTestData(liveTest);
            const startingNode = liveTest.nodes.find(
              (node) => node.data.isStartNode
            );
            if (startingNode) {
              setCurrentQuestionId(startingNode.id);
              setQuestionHistory([startingNode.id]);
            } else {
              setSnackbarMessage('Kein Startknoten gefunden.');
              setSnackbarOpen(true);
            }
          } else {
            setSnackbarMessage('Kein "live" Test gefunden.');
            setSnackbarOpen(true);
          }
        } catch (error) {
          console.error('Fehler beim Laden des "live" Tests:', error);
          setSnackbarMessage('Fehler beim Laden des "live" Tests.');
          setSnackbarOpen(true);
        }
      };

      fetchLiveTest();
    }
  }, [horseId, horseName]);

  const getQuestionById = (id) => {
    if (!testData || !testData.nodes) return null;
    return testData.nodes.find((node) => node.id === id);
  };

  const getNextQuestionId = (currentNodeId, selectedAnswers) => {
    const currentQuestion = getQuestionById(currentNodeId);
    if (!currentQuestion) return null;

    const selectedAnswerIndex = selectedAnswers[0];
    const selectedAnswer = currentQuestion.data.answers[selectedAnswerIndex];

    if (selectedAnswer && selectedAnswer.next) {
      if (selectedAnswer.next === 'end') {
        return null;
      } else {
        return selectedAnswer.next;
      }
    }

    const outgoingEdges = testData.edges.filter(
      (edge) => edge.source === currentNodeId
    );

    const defaultEdge = outgoingEdges.find(
      (edge) => !edge.data || edge.data.answerIndex === undefined
    );

    if (defaultEdge) {
      return defaultEdge.target;
    }

    console.warn(`Keine Standardkante für Knoten ${currentNodeId} gefunden.`);
    return null;
  };

  const handleNext = () => {
    const currentQuestion = getQuestionById(currentQuestionId);
    const selectedAnswers = answers[currentQuestionId];

    if (!selectedAnswers || selectedAnswers.length === 0) {
      setSnackbarMessage('Bitte wähle mindestens eine Antwort aus.');
      setSnackbarOpen(true);
      return;
    }

    const nextQuestionId = getNextQuestionId(
      currentQuestionId,
      selectedAnswers
    );
    if (nextQuestionId) {
      setQuestionHistory((prevHistory) => [...prevHistory, nextQuestionId]);
      setCurrentQuestionId(nextQuestionId);
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (questionHistory.length > 1) {
      setQuestionHistory((prevHistory) => {
        const newHistory = [...prevHistory];
        newHistory.pop();
        const previousQuestionId = newHistory[newHistory.length - 1];
        setCurrentQuestionId(previousQuestionId);
        return newHistory;
      });
    } else {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setCancelDialogOpen(true);
  };

  const handleCancelConfirm = () => {
    setCancelDialogOpen(false);
    setSaveProgressDialogOpen(true);
  };

  const handleCancelClose = () => {
    setCancelDialogOpen(false);
  };

  const handleSaveProgress = async () => {
    setSaveProgressDialogOpen(false);
    setLoading(true);

    try {
      await addDoc(
        collection(db, 'users', currentUser.uid, 'horses', horseId, 'test'),
        {
          testName: 'Einschätzungstest',
          date: new Date(),
          status: 'in_progress',
          answers: answers,
          videoIds: [],
        }
      );

      setSnackbarMessage('Testfortschritt erfolgreich gespeichert.');
      setSnackbarOpen(true);
      navigate('/dashboard');
    } catch (error) {
      console.error('Fehler beim Speichern des Testfortschritts:', error);
      setSnackbarMessage('Fehler beim Speichern des Testfortschritts.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveProgressCancel = () => {
    setSaveProgressDialogOpen(false);
    navigate('/dashboard');
  };

  const handleSubmit = async () => {
    setLoading(true);
    setStatus('pending');

    const preparedAnswers = testData.nodes
      .filter((node) => node.type === 'questionNode')
      .map((node) => {
        const selectedAnswerIndices = answers[node.id] || [];
        const userAnswers = node.data.answers.map((answer, index) => ({
          title: answer.text,
          userAnswer: selectedAnswerIndices.includes(index),
        }));
        return {
          title: node.data.text, // Unverändert für die API
          answers: userAnswers, // Unverändert für die API
        };
      });

    const dataToSend = {
      testResult: {
        identifier: 'g4hgahtb',
        finished: true,
        finishedDate: new Date().toISOString(),
        horse: {
          name: horseName,
          age: 4,
        },
        questions: preparedAnswers,
      },
    };

    try {
      const response = await axios.post(
        'https://nativehorses-recommendations-980d73394eee.herokuapp.com/get_recommendations',
        dataToSend
      );

      const taskID = response.data.task_id;
      const testDocRef = await addDoc(
        collection(db, 'users', currentUser.uid, 'horses', horseId, 'test'),
        {
          testName: 'Einschätzungstest',
          date: new Date(),
          status: 'sent',
          answers: answers,
          taskId: taskID,
          videoIds: [],
        }
      );

      await checkTaskStatus(taskID, testDocRef.id);
    } catch (error) {
      console.error('Fehler bei der Kommunikation mit der API:', error);
      setLoading(false);
      setSnackbarMessage(
        'Fehler beim Durchführen des Tests. Bitte versuche es später erneut.'
      );
      setSnackbarOpen(true);
    }
  };

  const checkTaskStatus = async (taskID, testId, retries = 12) => {
    if (retries <= 0) {
      setSnackbarMessage(
        'Der Test konnte nicht abgeschlossen werden. Bitte versuche es später erneut.'
      );
      setSnackbarOpen(true);
      setLoading(false);
      setStatus('');
      return;
    }

    try {
      const response = await axios.get(
        `https://nativehorses-recommendations-980d73394eee.herokuapp.com/task_status/${taskID}`
      );

      if (response.data.state === 'PENDING') {
        setTimeout(() => checkTaskStatus(taskID, testId, retries - 1), 5000);
      } else if (response.data.state === 'SUCCESS') {
        if (!response.data.result) {
          setSnackbarMessage(
            'Erfolgreiche Antwort von der API, aber kein Ergebnis erhalten.'
          );
          setSnackbarOpen(true);
          setLoading(false);
          return;
        }

        setStatus('compiling');

        const videoIds = await extractVideoIds(response.data.result, db);

        await updateDoc(
          doc(db, 'users', currentUser.uid, 'horses', horseId, 'test', testId),
          {
            status: 'completed',
            videoIds: videoIds,
          }
        );

        await setDoc(
          doc(
            db,
            'users',
            currentUser.uid,
            'horses',
            horseId,
            'test_result',
            testId
          ),
          {
            videoIds: videoIds,
            date: new Date(),
          }
        );

        // Anzahl der gefundenen Videos berechnen und ausgeben
        const totalVideosFound = Object.values(videoIds).reduce(
          (acc, arr) => acc + arr.length,
          0
        );
        console.log(
          `Es wurden insgesamt ${totalVideosFound} Videos gefunden und gespeichert.`
        );

        setStatus('redirecting');

        setTimeout(() => {
          setLoading(false);
          setSnackbarMessage('Einschätzungstest erfolgreich abgeschlossen.');
          setSnackbarOpen(true);
          navigate('/videostage', {
            search: `?horseId=${encodeURIComponent(horseId)}&horseName=${encodeURIComponent(horseName)}`,
          });
        }, 2000);
      } else if (response.data.state === 'FAILURE') {
        setSnackbarMessage(
          'Der Test ist fehlgeschlagen. Bitte versuche es erneut.'
        );
        setSnackbarOpen(true);
        setLoading(false);
        setStatus('');
      }
    } catch (error) {
      console.error('Fehler beim Überprüfen des Task-Status:', error);
      setLoading(false);
      setSnackbarMessage('Fehler beim Überprüfen des Task-Status.');
      setSnackbarOpen(true);
      setStatus('');
    }
  };

  const extractVideoIds = async (apiResult, db) => {
    const videoIdsByCategory = {};

    // Lade alle Videos aus der Datenbank
    const allVideosSnapshot = await getDocs(collection(db, 'videos'));
    const allVideos = allVideosSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    for (const categoryObj of apiResult) {
      const [category, videos] = Object.entries(categoryObj)[0];
      videoIdsByCategory[category] = [];

      for (const video of videos) {
        const [title, url, score] = video;

        if (!url) {
          console.warn(
            `Video-Eintrag ohne URL gefunden: ${JSON.stringify(video)}`
          );
          continue;
        }

        const vimeoId = extractVimeoID(url);

        if (!vimeoId) {
          console.warn(`Keine gültige Vimeo-ID für URL: ${url}`);
          continue;
        }

        const matchedVideo = allVideos.find((v) => {
          const docUrl = v.vimeo_id;
          if (!docUrl) return false;
          const docVimeoId = extractVimeoID(docUrl);
          return docVimeoId === vimeoId;
        });

        if (matchedVideo) {
          videoIdsByCategory[category].push(matchedVideo.id);
        } else {
          console.warn(
            `Kein passendes Video-Dokument für URL ${url} gefunden.`
          );
        }
      }
    }

    return videoIdsByCategory;
  };

  const extractVimeoID = (url) => {
    const regex = /vimeo\.com\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : '';
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAnswerSelect = (index) => {
    const currentQuestion = getQuestionById(currentQuestionId);
    if (!currentQuestion) return;
    const maxSelectable = currentQuestion.data.maxSelectableAnswers || 1;
    const selectedAnswers = answers[currentQuestionId] || [];

    if (selectedAnswers.includes(index)) {
      const updatedAnswers = selectedAnswers.filter((i) => i !== index);
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [currentQuestionId]: updatedAnswers,
      }));
    } else {
      if (maxSelectable === 1) {
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          [currentQuestionId]: [index],
        }));
      } else {
        if (selectedAnswers.length < maxSelectable) {
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [currentQuestionId]: [...selectedAnswers, index],
          }));
        } else {
          setSnackbarMessage(
            'Du hast die maximale Anzahl von Auswahlmöglichkeiten erreicht!'
          );
          setSnackbarOpen(true);
        }
      }
    }
  };

  const renderAnswers = () => {
    const currentQuestion = getQuestionById(currentQuestionId);
    if (!currentQuestion || !currentQuestion.data) return null;

    const selectedAnswers = answers[currentQuestionId] || [];

    return (
      <Grid container spacing={4} sx={{ mt: 2 }}>
        {currentQuestion.data.answers.map((answer, index) => {
          const isSelected = selectedAnswers.includes(index);
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <AnswerCard
                onClick={() => handleAnswerSelect(index)}
                selected={isSelected ? 1 : 0}
              >
                <StyledButtonBase>
                  <CardContent>
                    <Typography variant="body1">{answer.text}</Typography>
                  </CardContent>
                  {isSelected && <SelectedIcon />}
                </StyledButtonBase>
              </AnswerCard>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const handleHorseSelection = () => {
    if (!selectedHorse) {
      setSnackbarMessage('Bitte wähle ein Pferd aus.');
      setSnackbarOpen(true);
      return;
    }

    const chosenHorse = horses.find((horse) => horse.id === selectedHorse);
    if (chosenHorse) {
      navigate('/Einschaetzungstest', {
        search: `?horseId=${encodeURIComponent(chosenHorse.id)}&horseName=${encodeURIComponent(chosenHorse.name)}`,
      });
    } else {
      setSnackbarMessage('Ausgewähltes Pferd ist ungültig.');
      setSnackbarOpen(true);
    }
  };

  return (
    <Box sx={{ width: '95%', margin: '0 auto', mt: 5 }}>
      {/* Pferdauswahl-Dialog */}
      <Dialog
        open={showHorseSelection && (!horseId || !horseName)}
        onClose={() => {}}
        disableEscapeKeyDown
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: 3,
          }}
        >
          <GoldHorseIcon />
          <DialogTitle sx={{ textAlign: 'center', mt: 2 }}>
            Wähle ein Pferd für den Einschätzungstest
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ textAlign: 'center', mb: 2 }}>
              Bitte wähle ein Pferd aus, für das du den Einschätzungstest
              durchführen möchtest.
            </DialogContentText>
            {loadingHorses ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <CircularProgress />
              </Box>
            ) : horsesError ? (
              <Typography color="error" sx={{ textAlign: 'center', mb: 2 }}>
                Fehler beim Laden der Pferde. Bitte versuche es später erneut.
              </Typography>
            ) : horses.length > 0 ? (
              <Grid container spacing={2} justifyContent="center">
                {horses.map((horse) => (
                  <Grid item key={horse.id}>
                    <Card
                      sx={{
                        width: 120,
                        cursor: 'pointer',
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: 6,
                        },
                        textAlign: 'center',
                        position: 'relative',
                      }}
                      onClick={() => setSelectedHorse(horse.id)}
                    >
                      <Box sx={{ p: 2 }}>
                        <Avatar
                          src={horse.profilePictureURL || ''}
                          alt={horse.name}
                          sx={{
                            width: 60,
                            height: 60,
                            margin: '0 auto',
                            mb: 1,
                          }}
                        >
                          {horse.name.charAt(0)}
                        </Avatar>
                        <Typography variant="body1" noWrap>
                          {horse.name}
                        </Typography>
                      </Box>
                      {selectedHorse === horse.id && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bgcolor: 'primary.main',
                            color: 'white',
                            borderRadius: '50%',
                            p: 0.5,
                          }}
                        >
                          <CheckCircle />
                        </Box>
                      )}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <Typography sx={{ mb: 2 }}>
                  Du hast noch kein Pferd angelegt. Bitte füge mindestens ein
                  Pferd unter "Einstellungen &rarr; Meine Pferde" hinzu, bevor
                  du den Test durchführen kannst.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/settings/myhorses')}
                >
                  Zu meinen Pferden
                </Button>
              </Box>
            )}
          </DialogContent>
          {horses.length > 0 && (
            <DialogActions sx={{ justifyContent: 'center', width: '100%' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleHorseSelection}
                disabled={!selectedHorse}
              >
                Test starten
              </Button>
            </DialogActions>
          )}
        </Box>
      </Dialog>

      {horseId && horseName && testData && (
        <Card sx={{ p: 5 }}>
          {/* Anzeige des ausgewählten Pferdes */}
          <Box sx={{ mb: 3, textAlign: 'center' }}>
            <Avatar
              src={
                horses.find((horse) => horse.id === horseId)
                  ?.profilePictureURL || ''
              }
              alt={horseName}
              sx={{ width: 80, height: 80, margin: '0 auto', mb: 1 }}
            >
              {horseName.charAt(0)}
            </Avatar>
            <Typography variant="h6" color="textSecondary">
              Test für: {horseName}
            </Typography>
          </Box>

          {/* Testfragen */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" sx={{ mb: 3 }}>
              {getQuestionById(currentQuestionId)?.data.text.replace(
                /dein Pferd|deinem Pferd/g,
                horseName
              )}
            </Typography>
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
              Test abbrechen
            </Button>
          </Box>

          {getQuestionById(currentQuestionId)?.data.maxSelectableAnswers >
            1 && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              Wähle bis zu{' '}
              {getQuestionById(currentQuestionId)?.data.maxSelectableAnswers}{' '}
              Antworten aus
            </Typography>
          )}

          {renderAnswers()}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }}>
            <Button variant="outlined" onClick={handleBack}>
              Zurück
            </Button>
            <Button variant="contained" onClick={handleNext} disabled={loading}>
              Weiter
            </Button>
          </Box>
        </Card>
      )}

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <CircularProgress />
        </Box>
      )}

      {status && (
        <Typography sx={{ mt: 3 }} align="center">
          Status: {status}
        </Typography>
      )}

      {/* Dialog zum Abbrechen des Tests */}
      <Dialog open={cancelDialogOpen} onClose={handleCancelClose}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: 3,
          }}
        >
          <GoldHorseIcon />
          <DialogTitle sx={{ textAlign: 'center', mt: 2 }}>
            Test abbrechen
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ textAlign: 'center' }}>
              Bist du sicher, dass du den Test abbrechen möchtest? Alle
              bisherigen Eingaben gehen verloren, wenn du nicht speicherst.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={handleCancelClose} color="primary">
              Nein
            </Button>
            <Button onClick={handleCancelConfirm} color="primary" autoFocus>
              Ja, abbrechen
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* Dialog zum Speichern des Testfortschritts */}
      <Dialog
        open={saveProgressDialogOpen}
        onClose={() => setSaveProgressDialogOpen(false)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: 3,
          }}
        >
          <GoldHorseIcon />
          <DialogTitle sx={{ textAlign: 'center', mt: 2 }}>
            Test fortsetzen
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ textAlign: 'center' }}>
              Möchtest du deinen Testfortschritt speichern, damit du ihn später
              fortsetzen kannst?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={handleSaveProgressCancel} color="primary">
              Nein, abbrechen
            </Button>
            <Button onClick={handleSaveProgress} color="primary" autoFocus>
              Ja, speichern
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* Snackbar für Benachrichtigungen */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MultiStepForm;

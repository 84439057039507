// src/components/pages/Community/UserDashboard_components/Comment.js
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Avatar,
  Box,
  IconButton,
  AvatarGroup,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from '@mui/material';
import { Favorite, Edit, Delete, Check, Close } from '@mui/icons-material';
import { storage, db } from '../../../../config/firebase'; // Passe den Importpfad entsprechend an
import { ref, getDownloadURL } from 'firebase/storage';
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { useAuth } from '../../../context/AuthContext'; // Passe den Importpfad entsprechend an
import { getUserData } from './userService';

// Importiere date-fns für die Zeitformatierung
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

// Importiere useTheme und styled von Material-UI
import { useTheme, styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';

// Snackbar Alert-Komponente
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Styled Component für das Kommentar-Kästchen mit voller Breite
const CommentBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff', // Weißer Hintergrund
  borderRadius: '16px', // Stärker abgerundete Ecken
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1), // Reduzierter Abstand: 8px
  width: '100%', // Volle Breite des übergeordneten Containers
  boxShadow: theme.shadows[1], // Leichter Schatten für Tiefe
}));

const Comment = React.memo(({ comment, postId }) => {
  const { currentUser } = useAuth();
  const theme = useTheme();

  const [authorAvatarURL, setAuthorAvatarURL] = useState('');
  const [likes, setLikes] = useState(Array.isArray(comment.likes) ? comment.likes : []);
  const [userHasLiked, setUserHasLiked] = useState(false);
  const [currentUserData, setCurrentUserData] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [likesWithProfilePics, setLikesWithProfilePics] = useState([]);

  // Zustände für die Inline-Bearbeitung des Kommentars
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');

  // Zustände für das Löschen des Kommentars
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Zustände für das Anzeigen von Snackbar-Feedback
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' | 'error' | 'warning' | 'info'

  // Zustand für das Hochladen
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchAuthorAvatar = async () => {
      if (comment.authorAvatarPath) {
        try {
          const avatarRef = ref(storage, comment.authorAvatarPath);
          const url = await getDownloadURL(avatarRef);
          setAuthorAvatarURL(url);
        } catch (error) {
          console.error('Fehler beim Abrufen des Profilbildes:', error);
        }
      }
    };
    fetchAuthorAvatar();
  }, [comment.authorAvatarPath]);

  useEffect(() => {
    const fetchCurrentUserData = async () => {
      if (currentUser) {
        const data = await getUserData(currentUser.uid);
        setCurrentUserData(data);
      }
    };
    fetchCurrentUserData();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      if (Array.isArray(likes)) {
        const hasLiked = likes.some((like) => like.userId === currentUser.uid);
        setUserHasLiked(hasLiked);
      } else {
        setUserHasLiked(false);
      }
    }
  }, [likes, currentUser]);

  // Laden der Profilbilder der likenden Nutzer
  useEffect(() => {
    const fetchLikesProfilePictures = async () => {
      const updatedLikes = await Promise.all(
        likes.map(async (like) => {
          if (like.profilePicturePath) {
            try {
              const profilePictureRef = ref(storage, like.profilePicturePath);
              const profilePictureURL = await getDownloadURL(profilePictureRef);
              return { ...like, profilePictureURL };
            } catch (error) {
              console.error('Fehler beim Abrufen des Profilbildes:', error);
              return { ...like, profilePictureURL: '' };
            }
          } else {
            return { ...like, profilePictureURL: '' };
          }
        })
      );
      setLikesWithProfilePics(updatedLikes);
    };

    if (likes.length > 0) {
      fetchLikesProfilePictures();
    } else {
      setLikesWithProfilePics([]);
    }
  }, [likes]);

  const handleLike = useCallback(async () => {
    if (!currentUser) {
      alert('Bitte melde dich an, um Kommentare zu liken.');
      return;
    }

    if (!currentUserData) {
      console.error('Benutzerdaten nicht verfügbar.');
      return;
    }

    const commentRef = doc(db, 'posts', postId, 'comments', comment.id);

    const userLikeData = {
      userId: currentUser.uid,
      firstName: currentUserData.firstName,
      lastName: currentUserData.lastName,
      profilePicturePath: currentUserData.profilePicturePath || '',
    };

    try {
      if (userHasLiked) {
        // Like entfernen
        await updateDoc(commentRef, {
          likes: arrayRemove(userLikeData),
        });
        setLikes((prevLikes) => prevLikes.filter((like) => like.userId !== currentUser.uid));
      } else {
        // Like hinzufügen
        await updateDoc(commentRef, {
          likes: arrayUnion(userLikeData),
        });
        setLikes((prevLikes) => [...prevLikes, userLikeData]);
      }
      setUserHasLiked((prev) => !prev);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Likes:', error);
      setSnackbarMessage('Fehler beim Aktualisieren des Likes.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [currentUser, currentUserData, userHasLiked, postId, comment.id]);

  const handleAvatarClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const fullName = `${comment.authorFirstName || ''} ${comment.authorLastName || ''}`.trim();

  // Farbe für das Herzchen, wenn geliked
  const heartColor = userHasLiked ? '#e63946' : 'inherit';

  // Zeitformatierung
  const getFormattedTimestamp = useCallback(() => {
    const now = new Date();
    const commentDate = comment.timestamp?.toDate();

    if (!commentDate) return '';

    const diffInSeconds = (now - commentDate) / 1000;

    if (diffInSeconds < 60) {
      return 'gepostet vor wenigen Sekunden';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `gepostet vor ${minutes} Minute${minutes !== 1 ? 'n' : ''}`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `gepostet vor ${hours} Stunde${hours !== 1 ? 'n' : ''}`;
    } else {
      return format(commentDate, 'Pp', { locale: de });
    }
  }, [comment.timestamp]);

  const handleEditClick = useCallback(() => {
    setEditedContent(comment.content);
    setIsEditing(true);
  }, [comment.content]);

  const handleSaveClick = useCallback(async () => {
    if (!editedContent.trim()) {
      alert('Der Kommentar darf nicht leer sein.');
      return;
    }

    setUploading(true);

    try {
      const commentRef = doc(db, 'posts', postId, 'comments', comment.id);

      // Kommentar aktualisieren
      await updateDoc(commentRef, {
        content: editedContent,
        timestamp: serverTimestamp(), // Aktualisiere den Zeitstempel
      });

      setIsEditing(false);
      setSnackbarMessage('Kommentar erfolgreich aktualisiert!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Kommentars:', error);
      setSnackbarMessage('Fehler beim Aktualisieren des Kommentars.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setUploading(false);
    }
  }, [editedContent, postId, comment.id]);

  const handleCancelClick = useCallback(() => {
    setIsEditing(false);
  }, []);

  const handleDelete = useCallback(async () => {
    setDeleteDialogOpen(false);
    try {
      const commentRef = doc(db, 'posts', postId, 'comments', comment.id);
      await deleteDoc(commentRef);
      setSnackbarMessage('Kommentar erfolgreich gelöscht!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Fehler beim Löschen des Kommentars:', error);
      setSnackbarMessage('Fehler beim Löschen des Kommentars.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [postId, comment.id]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <CommentBox>
      <Box sx={{ display: 'flex' }}>
        <Avatar src={authorAvatarURL} sx={{ mr: 2 }}>
          {comment.authorFirstName?.charAt(0)}
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          {/* Header: Name und Bearbeiten/Löschen-Schaltflächen */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {fullName || 'Anonym'}
            </Typography>
            {/* Anzeige der Bearbeiten- und Löschen-Schaltflächen für den Autor */}
            {currentUser && currentUser.uid === comment.authorId && !isEditing && (
              <Box>
                <Tooltip title="Bearbeiten">
                  <IconButton onClick={handleEditClick} size="small">
                    <Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Löschen">
                  <IconButton onClick={() => setDeleteDialogOpen(true)} size="small">
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          {/* Zeitstempel direkt unter dem Namen mit reduziertem Abstand */}
          <Typography variant="caption" color="textSecondary" sx={{ mt: 0.2 }}>
            {getFormattedTimestamp()}
          </Typography>
          {/* Kommentarinhalt: Anzeige oder Bearbeitungsmodus */}
          {isEditing ? (
            <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                multiline
                minRows={3}
                variant="outlined"
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
              />
              <IconButton
                onClick={handleSaveClick}
                color="primary"
                sx={{ ml: 1 }}
                disabled={uploading}
              >
                <Check />
              </IconButton>
              <IconButton onClick={handleCancelClick} color="secondary" sx={{ ml: 0.5 }}>
                <Close />
              </IconButton>
            </Box>
          ) : (
            <Typography variant="body1" sx={{ mt: 0.5, fontSize: '1rem' }}>
              {comment.content}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Steuerelemente am unteren Rand */}
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Linke Seite: Like-Button und Anzahl */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleLike} aria-label="Like">
            <Favorite sx={{ color: heartColor }} />
          </IconButton>
          <Typography>{likes.length}</Typography>
        </Box>

        {/* Rechte Seite: AvatarGroup */}
        {likesWithProfilePics.length > 0 && (
          <AvatarGroup
            max={3}
            sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 12 } }}
            onClick={handleAvatarClick}
          >
            {likesWithProfilePics.slice(0, 3).map((like) => (
              <Avatar
                key={like.userId}
                src={like.profilePictureURL}
                alt={`${like.firstName} ${like.lastName}`}
                sx={{ cursor: 'pointer' }}
              >
                {like.firstName?.charAt(0)}
              </Avatar>
            ))}
          </AvatarGroup>
        )}
      </Box>

      {/* Popover für die Liste der likenden Nutzer */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {likesWithProfilePics.map((like) => (
            <ListItem key={like.userId}>
              <ListItemAvatar>
                <Avatar src={like.profilePictureURL} alt={`${like.firstName} ${like.lastName}`}>
                  {like.firstName?.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${like.firstName} ${like.lastName}`} />
            </ListItem>
          ))}
        </List>
      </Popover>

      {/* Löschen-Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Kommentar löschen</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Bist du sicher, dass du diesen Kommentar löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Abbrechen</Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar für Benutzer-Feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </CommentBox>
  );
});

Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    authorId: PropTypes.string.isRequired,
    authorFirstName: PropTypes.string,
    authorLastName: PropTypes.string,
    authorAvatarPath: PropTypes.string,
    content: PropTypes.string.isRequired,
    likes: PropTypes.arrayOf(
      PropTypes.shape({
        userId: PropTypes.string.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profilePicturePath: PropTypes.string,
      })
    ),
    timestamp: PropTypes.object.isRequired, // Firestore Timestamp
  }).isRequired,
  postId: PropTypes.string.isRequired,
};

export default Comment;

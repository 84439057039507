// src/components/Administration/Livestream/MeetingManagement.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  IconButton,
  Avatar, // <--- Hinzugefügt
} from '@mui/material';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateMeeting from './CreateMeeting';
import { motion } from 'framer-motion';
import { styled } from '@mui/system';
import { db } from '../../../config/firebase';
import { useNavigate } from 'react-router-dom';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  overflow: 'auto',
  padding: '20px',
});

const MeetingManagement = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCreate, setOpenCreate] = useState(false);
  const [meetingToEdit, setMeetingToEdit] = useState(null);

  useEffect(() => {
    const q = query(collection(db, 'meetings'), orderBy('start_time', 'desc'));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const meetingsData = [];
        snapshot.forEach((docSnap) => {
          const data = docSnap.data();
          const imageUrl = data.headerImageUrl
            ? data.headerImageUrl
            : '/images/default-meeting.jpg';
          meetingsData.push({
            id: docSnap.id,
            title: data.title,
            imageUrl: imageUrl,
            shortDescription:
              data.shortDescription || 'Keine Kurzbeschreibung verfügbar.',
            longDescription:
              data.longDescription || 'Keine Langbeschreibung verfügbar.',
            start_time: data.start_time, // Timestamp-Objekt
            trainerId: data.trainerId,
            trainerName: data.trainerName || 'Nicht zugewiesen',
            meetingLink: data.meetingLink,
            meetingPassword: data.meetingPassword || '',
          });
        });
        setMeetings(meetingsData);
        setLoading(false);
        console.log('Meetings aktualisiert:', meetingsData);
      },
      (error) => {
        console.error('Fehler beim Abrufen der Meetings:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleDeleteMeeting = async (id) => {
    if (
      window.confirm('Bist du sicher, dass du dieses Meeting löschen möchtest?')
    ) {
      try {
        await deleteDoc(doc(db, 'meetings', id));
        console.log(`Meeting mit ID ${id} wurde gelöscht.`);
      } catch (error) {
        console.error('Fehler beim Löschen des Meetings:', error);
      }
    }
  };

  const handleOpenCreate = () => {
    setMeetingToEdit(null);
    setOpenCreate(true);
    console.log('CreateMeeting Dialog wird geöffnet.');
  };

  const handleEditMeeting = (meeting) => {
    setMeetingToEdit(meeting);
    setOpenCreate(true);
    console.log(
      'CreateMeeting Dialog im Bearbeitungsmodus geöffnet für Meeting:',
      meeting.id
    );
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
    setMeetingToEdit(null);
    console.log('CreateMeeting Dialog wird geschlossen.');
  };

  const handleMeetingClick = (id) => {
    navigate(`/meetings/${id}`);
  };

  return (
    <Container>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: '#d5bc8b', fontSize: '2.5rem' }}
      >
        Meeting Management
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenCreate}
        sx={{ mb: 2 }}
      >
        Neues Meeting erstellen
      </Button>

      {/* CreateMeeting Dialog */}
      <CreateMeeting
        open={openCreate}
        handleClose={handleCloseCreate}
        meetingToEdit={meetingToEdit}
      />

      {/* Meetings List */}
      {loading ? (
        <CircularProgress
          sx={{ color: '#d5bc8b', margin: 'auto', display: 'block' }}
        />
      ) : (
        <Box>
          {meetings.length === 0 ? (
            <Typography
              variant="body1"
              sx={{ color: '#fff', textAlign: 'center' }}
            >
              Keine Meetings vorhanden.
            </Typography>
          ) : (
            meetings.map((meeting) => (
              <motion.div
                key={meeting.id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    mb: 2,
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => handleMeetingClick(meeting.id)}
                >
                  {/* Abschnitt 1: Bild */}
                  <Box
                    sx={{
                      width: { xs: '100%', sm: '25%' },
                      height: 150,
                      backgroundImage: `url(${meeting.imageUrl})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      borderRadius: '8px',
                      mr: { sm: 2 },
                      mb: { xs: 2, sm: 0 },
                    }}
                  />

                  {/* Abschnitt 2: Text */}
                  <Box sx={{ flex: '2' }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                      {meeting.title}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                      Datum:{' '}
                      {format(
                        new Date(meeting.start_time.toDate()),
                        'dd.MM.yyyy'
                      )}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                      Uhrzeit:{' '}
                      {format(new Date(meeting.start_time.toDate()), 'HH:mm')}{' '}
                      Uhr
                    </Typography>
                    <Typography variant="body1">
                      Link:{' '}
                      <a
                        href={meeting.meetingLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#1976d2', textDecoration: 'none' }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {meeting.meetingLink}
                      </a>
                    </Typography>
                  </Box>

                  {/* Abschnitt 3: Trainer inkl. Avatar */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flex: '1',
                      mr: 2,
                    }}
                  >
                    <Avatar
                      src={meeting.avatarUrl || '/images/default-avatar.png'}
                      alt={meeting.trainerName}
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body1">
                      {meeting.trainerName || 'Nicht zugewiesen'}
                    </Typography>
                  </Box>

                  {/* Abschnitt 4: Steuerelemente zum Bearbeiten und Löschen */}
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditMeeting(meeting);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteMeeting(meeting.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Paper>
              </motion.div>
            ))
          )}
        </Box>
      )}
    </Container>
  );
};

export default MeetingManagement;

import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, setDoc, updateDoc } from 'firebase/firestore';
import Papa from 'papaparse';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Firebase initialisieren
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const VideoUpload = () => {
  const [file, setFile] = useState(null);
  const [outputData, setOutputData] = useState('');

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const sanitizeFieldNames = (entry) => {
    const sanitizedEntry = {};
    for (const [key, value] of Object.entries(entry)) {
      const sanitizedKey = key.replace(/[~*/[\]]/g, ''); // Entfernt ungültige Zeichen
      sanitizedEntry[sanitizedKey] = value;
    }
    return sanitizedEntry;
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Bitte wählen Sie eine Datei aus!');
      return;
    }

    // Schritt 1: Bestehende Daten aus Firebase laden und nach vimeo_id strukturieren
    const videosCollectionRef = collection(db, 'videos');
    const querySnapshot = await getDocs(videosCollectionRef);
    const existingData = {};

    querySnapshot.forEach((doc) => {
      existingData[doc.data().vimeo_id] = doc.id; // Speichern der existierenden vimeo_id mit Firebase-Dokument-ID
    });

    // Schritt 2: CSV-Datei einlesen
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const csvData = results.data;

        // Schritt 3: Verarbeiten der CSV-Daten
        for (const entry of csvData) {
          const sanitizedEntry = sanitizeFieldNames(entry);
          const vimeoId = sanitizedEntry['Vimeo-ID'] || ''; // CSV-Feld für die eindeutige ID

          if (vimeoId && existingData[vimeoId]) {
            // Falls das Dokument existiert, ergänzen
            const docRef = doc(db, 'videos', existingData[vimeoId]);
            await updateDoc(docRef, sanitizedEntry);
            setOutputData(prev => prev + `\nEintrag ${vimeoId} wurde aktualisiert.`);
          } else if (vimeoId) {
            // Neues Dokument mit vimeoId erstellen, falls nicht vorhanden
            const newDocRef = doc(db, 'videos', vimeoId);
            await setDoc(newDocRef, sanitizedEntry);
            setOutputData(prev => prev + `\nNeuer Eintrag ${vimeoId} wurde hinzugefügt.`);
          } else {
            // Fallback: Dokument mit generierter ID erstellen
            const newDocRef = doc(videosCollectionRef); // Erzeugt eine automatisch generierte ID
            await setDoc(newDocRef, sanitizedEntry);
            setOutputData(prev => prev + `\nNeuer Eintrag mit generierter ID wurde hinzugefügt.`);
          }
        }
      },
      error: (error) => {
        console.error('Fehler beim Einlesen der CSV-Datei:', error);
        setOutputData('Fehler beim Einlesen der Datei.');
      }
    });
  };

  return (
    <div>
      <h1>CSV- und JSON-Upload zu Firebase</h1>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload}>Dateien hochladen</button>
      <p>{outputData}</p>
    </div>
  );
};

export default VideoUpload;

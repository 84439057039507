// src/components/Administration/AddThemeModal.jsx

import React, { useState } from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Avatar 
} from '@mui/material';
import { storage } from '../../../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';

const AddThemeModal = ({ open, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    imageFile: null,
  });

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFormData(prev => ({
        ...prev,
        imageFile: file,
      }));
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Neuen Themenbereich hinzufügen
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          {/* Bild Upload */}
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="theme-image-upload"
              type="file"
              onChange={handleImageChange}
            />
            <label htmlFor="theme-image-upload">
              <Avatar
                src={imagePreview || 'https://via.placeholder.com/100x75'}
                variant="square"
                sx={{
                  width: 100,
                  height: 75,
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                  },
                }}
              />
            </label>
            <Typography variant="body2" sx={{ ml: 2 }}>
              Bild hochladen
            </Typography>
          </Box>
          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            Hinzufügen
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddThemeModal;

// src/components/pages/Lernplattform/VideoStage.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardMedia,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../context/AuthContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import VideoCard from './VideoCard';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { ReactComponent as HorseIcon } from '../../../assets/icons/horse.svg';

// Hilfsfunktion zur Formatierung der Gesamtdauer in lesbares Format
const formatDuration = (durationInSeconds) => {
  if (!durationInSeconds || isNaN(durationInSeconds)) return '00:00';
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  const hoursDisplay = hours > 0 ? `${hours}h ` : '';
  const minutesDisplay = minutes > 0 ? `${minutes}m ` : '';
  const secondsDisplay = seconds > 0 ? `${seconds}s` : '';

  return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`.trim();
};

// Gestylter Card-Component mit den gewünschten Styles
const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(6),
  textAlign: 'center',
  backgroundColor: '#252833',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[6],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4),
  color: '#FFFFFF',
  maxWidth: 500,
  margin: '0 auto',
}));

// Angepasstes GoldHorseIcon
const GoldHorseIcon = styled(HorseIcon)(({ theme }) => ({
  width: 80,
  height: 80,
  fill: 'none',
  stroke: '#d5bc8b',
  strokeWidth: 2,
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        // Container mit maxWidth 1400px zentriert
        <Box sx={{ p: 3, maxWidth: '1400px', margin: '0 auto' }}>
          {children}
        </Box>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Styled Components für die Pferde-Avatare
const HorsesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  overflowX: 'auto',
  overflowY: 'hidden',
  gap: '20px',
  padding: '20px 0',
  scrollBehavior: 'smooth',
  justifyContent: 'center',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const HorseAvatarWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  flexShrink: 0,
  cursor: 'pointer',
  transition: 'transform 0.5s ease, box-shadow 0.5s ease',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& .horse-image': {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 1s ease',
  },

  '& .overlay': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    top: 0,
    left: 0,
    transition: 'opacity 1s ease',
    opacity: 1, // Standard: dunkel
  },

  '& .horse-name': {
    marginTop: '8px',
    color: '#ffffff',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    textAlign: 'center',
  },

  '&:hover .horse-name': {
    opacity: 1,
  },
  '&:hover .overlay': {
    opacity: 0, // beim Hover Overlay ausblenden
  },

  // Wenn ausgewählt
  '&.selected': {
    '& .horse-image': {
      width: '100px',
      height: '100px',
      boxShadow: '0 0 10px #d6bc8b',
      border: '3px solid #d6bc8b',
    },
    '& .overlay': {
      opacity: 0,
    },
  },
}));

const VideoStage = () => {
  const [value, setValue] = useState(0);
  const [allVideos, setAllVideos] = useState([]);
  const [bookmarkedVideos, setBookmarkedVideos] = useState([]);
  const { currentUser } = useAuth();

  const [themenbereiche, setThemenbereiche] = useState([]);
  const [selectedThemenbereich, setSelectedThemenbereich] = useState(null);
  const [kapitel, setKapitel] = useState([]);
  const [selectedKapitel, setSelectedKapitel] = useState(null);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingKapitel, setLoadingKapitel] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [error, setError] = useState(null);

  const [openVideo, setOpenVideo] = useState(false);
  const [currentVideoData, setCurrentVideoData] = useState(null);

  const [horses, setHorses] = useState([]);
  const [selectedHorse, setSelectedHorse] = useState('');
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState('');
  const [loadingHorses, setLoadingHorses] = useState(true);
  const [loadingTests, setLoadingTests] = useState(false);

  const [videoRecommendations, setVideoRecommendations] = useState({});
  const [loadingRecommendations, setLoadingRecommendations] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const navigate = useNavigate();
  const horsesContainerRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue !== 1) {
      setSelectedThemenbereich(null);
      setSelectedKapitel(null);
      setKapitel([]);
      setFilteredVideos([]);
    }
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const db = getFirestore();
        const videosRef = collection(db, 'videos');
        const videosSnapshot = await getDocs(videosRef);
        const videosList = videosSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllVideos(videosList);

        const uniqueThemenbereiche = [
          ...new Set(
            videosList
              .filter(
                (video) =>
                  video.themenbereich &&
                  typeof video.themenbereich === 'string' &&
                  video.themenbereich.trim() !== ''
              )
              .map((video) => video.themenbereich.trim())
          ),
        ].filter(Boolean);
        setThemenbereiche(uniqueThemenbereiche);
      } catch (error) {
        console.error('Fehler beim Abrufen der Videos:', error);
        setError(
          'Fehler beim Abrufen der Videos. Bitte versuche es später erneut.'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const fetchKapitel = (themenbereich) => {
    setLoadingKapitel(true);
    const gefilterteKapitel = [
      ...new Set(
        allVideos
          .filter(
            (video) =>
              video.themenbereich &&
              video.themenbereich.trim() === themenbereich &&
              video.kapitel &&
              typeof video.kapitel === 'string' &&
              video.kapitel.trim() !== ''
          )
          .map((video) => video.kapitel.trim())
      ),
    ].filter(Boolean);

    const kapitelDetails = gefilterteKapitel.map((kapitelName) => {
      const videosInKapitel = allVideos.filter(
        (video) =>
          video.kapitel &&
          video.kapitel.trim() === kapitelName &&
          video.themenbereich &&
          video.themenbereich.trim() === themenbereich
      );
      const totalDuration = videosInKapitel.reduce(
        (acc, video) => acc + (video.duration || 0),
        0
      );
      const totalVideos = videosInKapitel.length;
      return {
        name: kapitelName,
        totalDuration,
        totalVideos,
      };
    });

    setKapitel(kapitelDetails);
    setLoadingKapitel(false);
  };

  const fetchVideosByKapitel = (kapitelItem) => {
    setLoadingVideos(true);
    const gefilterteVideos = allVideos.filter(
      (video) =>
        video.kapitel &&
        video.kapitel.trim() === kapitelItem.name &&
        video.themenbereich &&
        video.themenbereich.trim() === selectedThemenbereich
    );
    setFilteredVideos(gefilterteVideos);
    setLoadingVideos(false);
  };

  useEffect(() => {
    const fetchBookmarks = async () => {
      if (currentUser) {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', currentUser.uid);

        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setBookmarkedVideos(userData.bookmarks || []);
          }
        } catch (error) {
          console.error('Fehler beim Abrufen der Lesezeichen:', error);
          setError(
            'Fehler beim Abrufen der Lesezeichen. Bitte versuche es später erneut.'
          );
        }
      }
    };

    fetchBookmarks();
  }, [currentUser]);

  const handleBookmarkToggle = async (video) => {
    if (!currentUser) {
      setError('Bitte melde dich an, um Lesezeichen zu verwenden.');
      return;
    }

    const db = getFirestore();
    const userDocRef = doc(db, 'users', currentUser.uid);
    let updatedBookmarks = [...bookmarkedVideos];
    const isBookmarked = bookmarkedVideos.includes(video.id);

    if (isBookmarked) {
      updatedBookmarks = updatedBookmarks.filter((v) => v !== video.id);
    } else {
      updatedBookmarks.push(video.id);
    }

    try {
      await updateDoc(userDocRef, { bookmarks: updatedBookmarks });
      setBookmarkedVideos(updatedBookmarks);
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Lesezeichen:', error);
      setError(
        'Fehler beim Aktualisieren der Lesezeichen. Bitte erneut versuchen.'
      );
    }
  };

  const handleThemenbereichClick = (themenbereich) => {
    setSelectedThemenbereich(themenbereich);
    setSelectedKapitel(null);
    setKapitel([]);
    setFilteredVideos([]);
    fetchKapitel(themenbereich);
  };

  const handleKapitelClick = (kapitelItem) => {
    setSelectedKapitel(kapitelItem);
    fetchVideosByKapitel(kapitelItem);
  };

  const handleBackToThemenbereiche = () => {
    setSelectedThemenbereich(null);
    setSelectedKapitel(null);
    setKapitel([]);
    setFilteredVideos([]);
  };

  const handleBackToKapitel = () => {
    setSelectedKapitel(null);
    setFilteredVideos([]);
  };

  useEffect(() => {
    const fetchHorses = async () => {
      if (currentUser) {
        try {
          const db = getFirestore();
          const storage = getStorage();
          const horsesRef = collection(db, 'users', currentUser.uid, 'horses');
          const q = query(horsesRef, where('horse_deleted', '==', false));
          const horsesSnapshot = await getDocs(q);
          const horsesList = await Promise.all(
            horsesSnapshot.docs.map(async (doc) => {
              const horseData = doc.data();
              let profilePictureURL = '';
              if (horseData.profilePicturePath) {
                try {
                  profilePictureURL = await getDownloadURL(
                    ref(storage, horseData.profilePicturePath)
                  );
                } catch (error) {
                  console.error(
                    `Fehler beim Profilbild für Pferd ${doc.id}:`,
                    error
                  );
                }
              }
              return { id: doc.id, ...horseData, profilePictureURL };
            })
          );
          setHorses(horsesList);
          setLoadingHorses(false);
        } catch (error) {
          console.error('Fehler beim Abrufen der Pferde:', error);
          setError(
            'Fehler beim Abrufen der Pferde. Bitte später erneut versuchen.'
          );
          setLoadingHorses(false);
        }
      } else {
        setLoadingHorses(false);
      }
    };

    fetchHorses();
  }, [currentUser]);

  useEffect(() => {
    if (!selectedHorse && horses.length > 0) {
      setSelectedHorse(horses[0].id);
    }
  }, [horses, selectedHorse]);

  useEffect(() => {
    const fetchTests = async () => {
      if (currentUser && selectedHorse) {
        setLoadingTests(true);
        try {
          const db = getFirestore();
          const testsRef = collection(
            db,
            'users',
            currentUser.uid,
            'horses',
            selectedHorse,
            'test'
          );
          const testsSnapshot = await getDocs(testsRef);
          const testsList = testsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // Nur abgeschlossene Tests anzeigen
          const completedTests = testsList.filter(
            (test) => test.status === 'completed'
          );
          completedTests.sort((a, b) => b.date.seconds - a.date.seconds);
          setTests(completedTests);

          if (completedTests.length > 0 && selectedTest === '') {
            setSelectedTest(completedTests[0].id);
            await fetchTestResult(completedTests[0].id);
          }
        } catch (error) {
          console.error('Fehler beim Abrufen der Tests:', error);
          setError(
            'Fehler beim Abrufen der Tests. Bitte später erneut versuchen.'
          );
        } finally {
          setLoadingTests(false);
        }
      } else {
        setTests([]);
        setSelectedTest('');
      }
    };

    fetchTests();
  }, [currentUser, selectedHorse]);

  const handleHorseClick = (horseId) => {
    setSelectedHorse(horseId);
    setSelectedTest('');
    setVideoRecommendations({});
  };

  useEffect(() => {
    if (horsesContainerRef.current && selectedHorse) {
      const container = horsesContainerRef.current;
      const selectedIndex = horses.findIndex((h) => h.id === selectedHorse);
      if (selectedIndex >= 0) {
        const horseElements = container.querySelectorAll('.horse-avatar');
        const selectedElement = horseElements[selectedIndex];
        if (selectedElement) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = selectedElement.getBoundingClientRect();
          const offset =
            elementRect.left -
            containerRect.left +
            elementRect.width / 2 -
            containerRect.width / 2;
          container.scrollBy({ left: offset, behavior: 'smooth' });
        }
      }
    }
  }, [selectedHorse, horses]);

  const handleTestChange = async (event) => {
    const value = event.target.value;
    if (value === 'new') {
      const selectedHorseObj = horses.find((h) => h.id === selectedHorse);
      const horseNameValue = selectedHorseObj ? selectedHorseObj.name : '';
      navigate(
        `/Einschaetzungstest?horseId=${encodeURIComponent(
          selectedHorse
        )}&horseName=${encodeURIComponent(horseNameValue)}`
      );
    } else {
      setSelectedTest(value);
      await fetchTestResult(value);
    }
  };

  const fetchTestResult = async (testId) => {
    setLoadingRecommendations(true);
    try {
      const db = getFirestore();
      const testResultDocRef = doc(
        db,
        'users',
        currentUser.uid,
        'horses',
        selectedHorse,
        'test_result',
        testId
      );
      const testResultDoc = await getDoc(testResultDocRef);

      if (testResultDoc.exists()) {
        const testResult = testResultDoc.data();
        const videoIdsByCategory = testResult.videoIds;

        if (
          videoIdsByCategory &&
          typeof videoIdsByCategory === 'object' &&
          Object.keys(videoIdsByCategory).length > 0
        ) {
          const enrichedData = enrichVideoDataByIds(
            videoIdsByCategory,
            allVideos
          );
          setVideoRecommendations(enrichedData);
        } else {
          setError('Testergebnis ohne gültige Videos gefunden.');
        }
      } else {
        setError('Kein Testergebnis vorhanden.');
      }
    } catch (error) {
      console.error('Fehler beim Abrufen des Testergebnisses:', error);
      setError('Fehler beim Abrufen des Testergebnisses.');
    } finally {
      setLoadingRecommendations(false);
    }
  };

  const enrichVideoDataByIds = (videoIdsByCategory, allVideos) => {
    if (!videoIdsByCategory || typeof videoIdsByCategory !== 'object') {
      console.error('videoIdsByCategory ist ungültig:', videoIdsByCategory);
      return {};
    }

    const enrichedResult = {};

    for (const [category, videoIds] of Object.entries(videoIdsByCategory)) {
      if (!Array.isArray(videoIds)) {
        console.warn(
          `videoIds für Kategorie "${category}" ist kein Array:`,
          videoIds
        );
        continue;
      }
      enrichedResult[category] = videoIds
        .map((videoId) => {
          const videoDoc = allVideos.find((v) => v.id === videoId);
          if (!videoDoc) {
            console.warn(`Video mit ID ${videoId} nicht gefunden.`);
            return null;
          }
          return {
            id: videoId,
            video_titel: videoDoc.video_titel || 'Unbekannter Titel',
            text: videoDoc.text || '',
            url: videoDoc.vimeo_id || '#',
            thumbnailUrl: videoDoc.thumbnailUrl || '',
            duration: videoDoc.duration || 0,
            description: videoDoc.description || '',
          };
        })
        .filter(Boolean);
    }

    return enrichedResult;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleOpenVideo = (video) => {
    if (video.url && video.url !== '#') {
      setCurrentVideoData(video);
      setOpenVideo(true);
    } else {
      setSnackbarMessage('Dieses Video ist derzeit nicht verfügbar.');
      setSnackbarOpen(true);
    }
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
    setCurrentVideoData(null);
  };

  // Beispiel für Beschreibung pro Kapitel (Kategorie).
  // Hier könnte man später dynamisch Beschreibungen laden.
  const getCategoryDescription = (category) => {
    return `In diesem Kapitel findest du hilfreiche Videos rund um das Thema "${capitalizeFirstLetter(
      category.replace('_', ' ')
    )}". Bitte klicke auf die Überschrift, um die Inhalte anzuzeigen.`;
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/backgrounds/kth_fasern.png)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        minHeight: '100vh',
        padding: '20px',
        overflowY: 'auto',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          textAlign: 'center',
          color: '#d6bc8b',
          mb: 4,
        }}
      >
        Lernplattform
      </Typography>

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Video Tabs"
        sx={{
          borderBottom: '2px solid #3f4b54',
          mb: 4,
          '& .MuiTabs-indicator': {
            backgroundColor: '#d6bc8b',
          },
        }}
        TabIndicatorProps={{
          style: { height: '3px' },
        }}
      >
        <Tab
          label="Alle Videos"
          {...a11yProps(0)}
          sx={{
            fontWeight: 'bold',
            color: value === 0 ? '#d6bc8b' : '#c0c0c0',
            '&:hover': {
              ...(value !== 0 && { color: '#e0c890' }),
            },
            minWidth: 100,
          }}
        />
        <Tab
          label="Meine Videos"
          {...a11yProps(1)}
          sx={{
            fontWeight: 'bold',
            color: value === 1 ? '#d6bc8b' : '#c0c0c0',
            '&:hover': {
              ...(value !== 1 && { color: '#e0c890' }),
            },
            minWidth: 100,
          }}
        />
        <Tab
          label="Lesezeichen"
          {...a11yProps(2)}
          sx={{
            fontWeight: 'bold',
            color: value === 2 ? '#d6bc8b' : '#c0c0c0',
            '&:hover': {
              ...(value !== 2 && { color: '#e0c890' }),
            },
            minWidth: 100,
          }}
        />
      </Tabs>

      {/* Fehler-Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setError(null)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>

      {/* Allgemeine Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Video-Dialog */}
      {currentVideoData && (
        <Dialog
          open={openVideo}
          onClose={handleCloseVideo}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              backgroundColor: '#252833',
              color: '#FFFFFF',
            },
          }}
        >
          <DialogTitle sx={{ color: '#FFFFFF' }}>
            {currentVideoData.video_titel}
            <IconButton
              aria-label="close"
              onClick={handleCloseVideo}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#FFFFFF',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <ReactPlayer url={currentVideoData.url} controls width="100%" />
            <Typography variant="body1" sx={{ mt: 2 }}>
              {currentVideoData.description}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseVideo} sx={{ color: '#FFFFFF' }}>
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Tab 0: Alle Videos */}
      <TabPanel value={value} index={0}>
        {!selectedThemenbereich ? (
          <>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: '#ffffff',
                backgroundColor: '#3f4b54',
                padding: '10px',
                borderRadius: '8px',
              }}
            >
              Unsere Themenbereiche
            </Typography>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '50vh',
                }}
              >
                <CircularProgress />
              </Box>
            ) : themenbereiche.length > 0 ? (
              <Grid container spacing={3}>
                {themenbereiche.map((themenbereich) => (
                  <Grid item xs={12} sm={6} md={4} key={themenbereich}>
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Card
                        sx={{
                          cursor: 'pointer',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'scale(1.05)',
                            boxShadow: 6,
                          },
                          borderRadius: 2,
                          backgroundColor: '#252833',
                          color: '#FFFFFF',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        onClick={() => handleThemenbereichClick(themenbereich)}
                      >
                        <CardMedia
                          component="img"
                          image="https://via.placeholder.com/300x200.png?text=Themenbereich"
                          alt={themenbereich}
                          sx={{
                            borderTopLeftRadius: 2,
                            borderTopRightRadius: 2,
                            height: 140,
                            objectFit: 'cover',
                          }}
                        />
                        <Box sx={{ p: 2, textAlign: 'center' }}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold', color: '#d6bc8b' }}
                          >
                            {themenbereich}
                          </Typography>
                        </Box>
                      </Card>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>Keine Themenbereiche verfügbar.</Typography>
            )}
          </>
        ) : !selectedKapitel ? (
          <>
            <Box sx={{ mb: 2 }}>
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={handleBackToThemenbereiche}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <NavigateBeforeIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Themenbereiche
                </Link>
                <Typography
                  color="text.primary"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <NavigateBeforeIcon fontSize="small" sx={{ mr: 0.5 }} />
                  {selectedThemenbereich}
                </Typography>
              </Breadcrumbs>
            </Box>

            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: '#ffffff',
                backgroundColor: '#3f4b54',
                padding: '10px',
                borderRadius: '8px',
              }}
            >
              Kapitel in "{selectedThemenbereich}"
            </Typography>
            {loadingKapitel ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '50vh',
                }}
              >
                <CircularProgress />
              </Box>
            ) : kapitel.length > 0 ? (
              <Grid container spacing={3}>
                {kapitel.map((kapitelItem) => (
                  <Grid item xs={12} key={kapitelItem.name}>
                    <motion.div
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.97 }}
                    >
                      <Card
                        sx={{
                          cursor: 'pointer',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'scale(1.03)',
                            boxShadow: 6,
                          },
                          borderRadius: 2,
                          backgroundColor: '#252833',
                          color: '#FFFFFF',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        onClick={() => handleKapitelClick(kapitelItem)}
                      >
                        <CardMedia
                          component="img"
                          image="https://via.placeholder.com/300x200.png?text=Kapitel+Bild"
                          alt={kapitelItem.name}
                          sx={{
                            borderTopLeftRadius: 2,
                            borderTopRightRadius: 2,
                            height: 140,
                            objectFit: 'cover',
                          }}
                        />
                        <Box sx={{ p: 2, textAlign: 'center' }}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold', color: '#d6bc8b', mb: 1 }}
                          >
                            {kapitelItem.name}
                          </Typography>
                          <Divider
                            sx={{
                              mb: 1,
                              backgroundColor: '#d6bc8b',
                              width: '80%',
                              mx: 'auto',
                            }}
                          />
                          <Typography variant="body2" sx={{ color: '#ffffff' }}>
                            Gesamtdauer:{' '}
                            {formatDuration(kapitelItem.totalDuration)}
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#ffffff' }}>
                            Anzahl Videos: {kapitelItem.totalVideos}
                          </Typography>
                        </Box>
                      </Card>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>
                Keine Kapitel in diesem Themenbereich gefunden.
              </Typography>
            )}
          </>
        ) : (
          <>
            <Box sx={{ mb: 2 }}>
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={handleBackToThemenbereiche}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <NavigateBeforeIcon fontSize="small" sx={{ mr: 0.5 }} />
                  Themenbereiche
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={handleBackToKapitel}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <NavigateBeforeIcon fontSize="small" sx={{ mr: 0.5 }} />
                  {selectedThemenbereich}
                </Link>
                <Typography
                  color="text.primary"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <NavigateBeforeIcon fontSize="small" sx={{ mr: 0.5 }} />
                  {selectedKapitel.name}
                </Typography>
              </Breadcrumbs>
            </Box>

            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: '#ffffff',
                backgroundColor: '#3f4b54',
                padding: '10px',
                borderRadius: '8px',
              }}
            >
              Videos in "{selectedKapitel.name}"
            </Typography>
            {loadingVideos ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '50vh',
                }}
              >
                <CircularProgress />
              </Box>
            ) : filteredVideos.length > 0 ? (
              <Grid container spacing={2}>
                {filteredVideos.map((video) => (
                  <Grid item xs={12} key={video.id}>
                    <VideoCard
                      video={video}
                      onClick={() => handleOpenVideo(video)}
                      isBookmarked={bookmarkedVideos.includes(video.id)}
                      onBookmarkToggle={() => handleBookmarkToggle(video)}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>Keine Videos in diesem Kapitel gefunden.</Typography>
            )}
          </>
        )}
      </TabPanel>

      {/* Tab 1: Meine Videos */}
      <TabPanel value={value} index={1}>
        {loadingHorses ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress size={24} />
          </Box>
        ) : horses.length > 0 ? (
          <Box sx={{ width: '100%', maxWidth: '100%' }}>
            <HorsesContainer ref={horsesContainerRef}>
              {horses.map((horse) => {
                const isSelected = horse.id === selectedHorse;
                return (
                  <HorseAvatarWrapper
                    key={horse.id}
                    className={`horse-avatar ${isSelected ? 'selected' : ''}`}
                    onClick={() => handleHorseClick(horse.id)}
                  >
                    <div className="horse-image">
                      <img
                        src={horse.profilePictureURL || ''}
                        alt={horse.name}
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                      <div className="overlay"></div>
                    </div>
                    <div className="horse-name">{horse.name}</div>
                  </HorseAvatarWrapper>
                );
              })}
            </HorsesContainer>

            {selectedHorse && (
              <Box sx={{ mt: 4 }}>
                {loadingTests ? (
                  <CircularProgress size={24} />
                ) : tests.length > 0 ? (
                  <>
                    <Box
                      sx={{
                        color: '#ffffff',
                        backgroundColor: '#3f4b54',
                        padding: '10px',
                        borderRadius: '8px',
                        mb: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ display: 'inline-block', mr: 2 }}
                      >
                        Videoempfehlungen für{' '}
                        {horses.find((horse) => horse.id === selectedHorse)
                          ?.name || '...'}
                        :
                      </Typography>
                      <Select
                        value={selectedTest}
                        onChange={handleTestChange}
                        sx={{
                          backgroundColor: '#ffffff',
                          borderRadius: '8px',
                          height: '40px',
                          verticalAlign: 'middle',
                        }}
                      >
                        {tests.map((test) => {
                          const testDate = new Date(
                            test.date.seconds * 1000
                          ).toLocaleDateString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          });
                          return (
                            <MenuItem key={test.id} value={test.id}>
                              {`${test.testName} (${testDate})`}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value="new">
                          Neuen Einschätzungstest starten
                        </MenuItem>
                      </Select>
                    </Box>
                    {Object.keys(videoRecommendations).length > 0 ? (
                      Object.entries(videoRecommendations).map(
                        ([category, videos], index) => (
                          <Accordion
                            key={index}
                            sx={{
                              backgroundColor: '#3f4b54',
                              color: '#ffffff',
                              mb: 2,
                              borderRadius: '8px',
                              overflow: 'hidden',
                            }}
                            TransitionProps={{ unmountOnExit: true }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon sx={{ color: '#d6bc8b' }} />
                              }
                              aria-controls={`panel-content-${index}`}
                              id={`panel-header-${index}`}
                              sx={{
                                '& .MuiAccordionSummary-content': {
                                  display: 'flex',
                                  flexDirection: 'column',
                                },
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 'bold',
                                  color: '#d6bc8b',
                                  mb: 1,
                                }}
                              >
                                {capitalizeFirstLetter(
                                  category.replace('_', ' ')
                                )}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: '#d0d0d0' }}
                              >
                                {getCategoryDescription(category)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={2}>
                                {videos.map((video) => (
                                  <Grid item xs={12} key={video.id}>
                                    <VideoCard
                                      video={video}
                                      onClick={() => handleOpenVideo(video)}
                                      isBookmarked={bookmarkedVideos.includes(
                                        video.id
                                      )}
                                      onBookmarkToggle={() =>
                                        handleBookmarkToggle(video)
                                      }
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )
                      )
                    ) : selectedTest ? (
                      <Typography>
                        Keine Videoempfehlungen für den ausgewählten Test
                        gefunden.
                      </Typography>
                    ) : (
                      <Typography>
                        Wähle einen Einschätzungstest aus, um die zugehörigen
                        Videos anzuzeigen.
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography>
                    Keine abgeschlossenen Tests verfügbar oder noch kein Pferd
                    ausgewählt.
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <StyledCard>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <GoldHorseIcon />
            </Box>
            <Typography variant="h5">Keine aktiven Pferde verfügbar</Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Bitte füge mindestens ein Pferd unter "Einstellungen → Meine
              Pferde" hinzu, um Einschätzungstests durchzuführen und Videos
              anzuzeigen.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/profile?tab=horses')}
              sx={{
                backgroundColor: '#d6bc8b',
                '&:hover': {
                  backgroundColor: '#c0a57b',
                },
              }}
            >
              Pferd hinzufügen
            </Button>
          </StyledCard>
        )}
      </TabPanel>

      {/* Tab 2: Lesezeichen */}
      <TabPanel value={value} index={2}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: '#ffffff',
            backgroundColor: '#3f4b54',
            padding: '10px',
            borderRadius: '8px',
          }}
        >
          Lesezeichen
        </Typography>
        {bookmarkedVideos.length > 0 ? (
          <Grid container spacing={2}>
            {allVideos
              .filter((video) => bookmarkedVideos.includes(video.id))
              .map((video) => (
                <Grid item xs={12} key={video.id}>
                  <VideoCard
                    video={video}
                    onClick={() => handleOpenVideo(video)}
                    isBookmarked={bookmarkedVideos.includes(video.id)}
                    onBookmarkToggle={() => handleBookmarkToggle(video)}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <Typography>Keine Videos mit Lesezeichen vorhanden.</Typography>
        )}
      </TabPanel>
    </Box>
  );
};

export default VideoStage;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import ProfileImageUploader from './ProfileImageUploader';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

// Styled components
const ProfileContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  height: 'calc(100vh - 150px)',
  marginBottom: '150px',
});

const UserProfileImageContainer = styled(Box)({
  width: '40%',
  maxWidth: '300px',
  marginRight: '20px',
  backgroundColor: '#252833',
  borderRadius: '8px',
  padding: '16px',
  textAlign: 'center',
  position: 'relative',
  height: 'calc(100vh - 150px)',
  opacity: 0.9,
});

const UserProfileDataContainer = styled(Box)({
  width: '60%',
  backgroundColor: '#252833',
  borderRadius: '8px',
  padding: '20px',
  opacity: 0.9,
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: '#d5bc8b' },
    '&:hover fieldset': { borderColor: '#ffffff' },
    '&.Mui-focused fieldset': { borderColor: '#d5bc8b' },
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
});

// Main Component for User Profile Editing
const EditUserProfile = () => {
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(
    '/path/to/default/profile/picture.jpg'
  ); // Standard-Avatar-URL
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();

          // Überprüfe, ob der Profilbildpfad existiert
          if (data.profilePicturePath) {
            const storageRef = ref(storage, data.profilePicturePath); // Referenz zum Bild
            try {
              const url = await getDownloadURL(storageRef); // Download-URL abrufen
              setAvatarUrl(url); // Setze die Avatar-URL
              console.log('Profilbildpfad:', url); // Debugging: Pfad in der Konsole anzeigen
            } catch (error) {
              console.error('Fehler beim Abrufen der Bild-URL:', error);
              // Setze die Avatar-URL auf einen Platzhalter
              setAvatarUrl('/path/to/default/profile/picture.jpg');
            }
          } else {
            console.warn('Kein Profilbildpfad vorhanden, benutze Platzhalter.');
            // Setze die Avatar-URL auf einen Platzhalter
            setAvatarUrl('/path/to/default/profile/picture.jpg');
          }

          // Setze Vorname und Nachname
          setFirstName(data.firstName || ''); // Vorname setzen
          setLastName(data.lastName || ''); // Nachname setzen
        } else {
          console.error('Dokument existiert nicht!');
        }
      } else {
        console.warn('Benutzer ist nicht angemeldet.');
      }
    };
    fetchUserData();
  }, [auth, db, storage]);

  const handleSaveChanges = async () => {
    const user = auth.currentUser;
    if (user) {
      const docRef = doc(db, 'users', user.uid);
      await updateDoc(docRef, {
        profilePicturePath: avatarUrl,
        firstName, // Vorname speichern
        lastName, // Nachname speichern
      });
      console.log('Profilbildpfad und Benutzerdaten aktualisiert');
    }
  };

  return (
    <ProfileContainer>
      <UserProfileImageContainer>
        <Box
          component="img"
          alt="User Profile Picture"
          src={avatarUrl}
          sx={{
            width: '150px',
            height: '150px',
            borderRadius: '50%',
            cursor: 'pointer',
            display: 'block', // Block-Element für zentrierte Ausrichtung
            margin: '0 auto', // Zentriere das Bild
          }}
          onClick={() => setImageDialogOpen(true)}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>
            Benutzername
          </Typography>
          <Divider sx={{ backgroundColor: '#3f4b54', mb: 2 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 1,
            }}
          >
            <Typography variant="body2" sx={{ color: 'white' }}>
              Communitystatus: Neuling
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ color: 'white' }}>
            Beiträge: 5
          </Typography>
          <Typography variant="body2" sx={{ color: 'white' }}>
            Beteiligung an Beiträgen: 2
          </Typography>
        </Box>
      </UserProfileImageContainer>

      <UserProfileDataContainer>
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', mb: 2, color: 'white' }}
        >
          Benutzereinstellungen
        </Typography>
        <Divider sx={{ backgroundColor: '#3f4b54', mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Vorname"
              variant="outlined"
              color="secondary"
              value={firstName} // Füll den Vorname TextField
              onChange={(e) => setFirstName(e.target.value)} // Update den Vorname Zustand
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Nachname"
              variant="outlined"
              color="secondary"
              value={lastName} // Füll den Nachname TextField
              onChange={(e) => setLastName(e.target.value)} // Update den Nachname Zustand
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Telefonnummer"
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Erzähle etwas über Dich"
              variant="outlined"
              color="secondary"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>

        {/* Save Changes Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveChanges}
          >
            Änderungen speichern
          </Button>
        </Box>
      </UserProfileDataContainer>

      <ProfileImageUploader
        open={imageDialogOpen}
        onClose={() => setImageDialogOpen(false)}
      />
    </ProfileContainer>
  );
};

export default EditUserProfile;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Collapse,
  Avatar,
  Divider,
} from '@mui/material';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { format } from 'date-fns';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const Container = styled(Box)({
  padding: '20px',
  maxWidth: '1200px',
  margin: 'auto',
});

const HeroBox = styled(Box)(({ expanded }) => ({
  position: 'relative',
  width: '100%',
  height: '300px', // Hero-Bereich im 8:3-Format
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px 8px 0 0', // Nur die oberen Ecken sind abgerundet
  cursor: 'pointer',
  overflow: 'hidden',
  '&:hover .watermark': {
    opacity: 0, // Wasserzeichen beim Hovern ausblenden
  },
}));

const HeroOverlay = styled(motion.div)(({ expanded }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  padding: '10px',
  background: expanded
    ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), #3f4b54)'
    : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3))',
  transition: 'background 0.8s ease',
}));

const Watermark = styled(Box)(({ isHovered, isExpanded }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  aspectRatio: '1 / 2', // Seitenverhältnis beibehalten
  backgroundImage: 'url(/images/logos/kth_logo_livecall.png)',
  backgroundSize: '200% auto', // Doppelte Breite, damit die linke Hälfte angezeigt wird
  backgroundPosition: 'left center', // Linke Hälfte des Bildes anzeigen
  backgroundRepeat: 'no-repeat',
  opacity: isHovered || isExpanded ? 0 : 0.3, // Wasserzeichen ausblenden, wenn Hover oder geöffnet
  pointerEvents: 'none', // Keine Interaktion mit dem Wasserzeichen erlauben
  transition: 'opacity 0.8s ease', // Langsamer Übergang beim Ein- und Ausblenden
}));

const MeetingDetails = styled(Box)({
  display: 'flex',
  padding: '20px',
  backgroundColor: '#3f4b54', // Anthrazit-Farbe
  borderRadius: '0 0 8px 8px', // Nur die unteren Ecken sind abgerundet
  boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
  color: '#fff',
});

const DetailsSection = styled(Box)({
  flex: 1,
  padding: '10px',
});

const TrainerSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const PlaceholderBox = styled(Box)({
  padding: '20px',
  textAlign: 'center',
  backgroundColor: '#3f4b54',
  color: '#fff',
  borderRadius: '8px',
  marginTop: '20px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
});

const MeetingOverview = () => {
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedMeeting, setExpandedMeeting] = useState(null);

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const q = query(
          collection(db, 'meetings'),
          where('start_time', '>=', new Date()), // Nur zukünftige Meetings
          orderBy('start_time', 'asc')
        );
        const querySnapshot = await getDocs(q);
        const meetingsData = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setMeetings(meetingsData);
      } catch (error) {
        console.error('Fehler beim Abrufen der Meetings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMeetings();
  }, []);

  const toggleExpandMeeting = (id) => {
    setExpandedMeeting((prev) => (prev === id ? null : id));
  };

  if (loading) {
    return (
      <CircularProgress sx={{ margin: 'auto', display: 'block', mt: 5 }} />
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
        Nächste Meetings
      </Typography>
      {meetings.map((meeting) => (
        <Box key={meeting.id} sx={{ marginBottom: '20px' }}>
          {/* Hero-Bereich */}
          <HeroBox
            expanded={expandedMeeting === meeting.id}
            sx={{
              backgroundImage: `url(${meeting.headerImageUrl || '/images/default-meeting.jpg'})`,
            }}
            onClick={() => toggleExpandMeeting(meeting.id)}
          >
            {/* Watermark */}
            <Watermark
              className="watermark"
              isHovered={expandedMeeting === meeting.id}
              isExpanded={expandedMeeting === meeting.id}
            />
            <HeroOverlay
              expanded={expandedMeeting === meeting.id}
              animate={{
                background:
                  expandedMeeting === meeting.id
                    ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), #3f4b54)'
                    : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3))',
              }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h5" sx={{ color: '#d5bc8b' }}>
                {meeting.title}
              </Typography>
            </HeroOverlay>
          </HeroBox>

          {/* Meeting-Details */}
          <Collapse in={expandedMeeting === meeting.id} timeout="auto">
            <MeetingDetails>
              {/* Datum & Uhrzeit */}
              <DetailsSection>
                <Typography variant="body1">
                  <strong>Datum:</strong>{' '}
                  {format(new Date(meeting.start_time.toDate()), 'dd.MM.yyyy')}
                </Typography>
                <Typography variant="body1">
                  <strong>Uhrzeit:</strong>{' '}
                  {format(new Date(meeting.start_time.toDate()), 'HH:mm')} Uhr
                </Typography>
              </DetailsSection>

              {/* Beschreibung */}
              <DetailsSection>
                <Typography variant="body1">
                  {meeting.longDescription || 'Keine Beschreibung verfügbar.'}
                </Typography>
              </DetailsSection>

              {/* Trainer */}
              <DetailsSection>
                <TrainerSection>
                  <Avatar
                    src={meeting.avatarUrl || '/images/default-avatar.png'}
                    alt={meeting.trainerName}
                    sx={{ width: 50, height: 50 }}
                  />
                  <Box>
                    <Typography variant="body1">
                      <strong>
                        {meeting.trainerName || 'Nicht zugewiesen'}
                      </strong>
                    </Typography>
                    <Typography variant="body2">
                      {meeting.trainerBio || 'Keine Beschreibung verfügbar.'}
                    </Typography>
                  </Box>
                </TrainerSection>
              </DetailsSection>
            </MeetingDetails>
          </Collapse>
        </Box>
      ))}

      {/* Abschnitt für vergangene Meetings */}
      <Divider sx={{ my: 4, backgroundColor: '#d5bc8b' }} />
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
        Aufzeichnungen vergangener Livestreams
      </Typography>
      <PlaceholderBox>
        <Typography variant="body1">
          Hier werden später die Aufzeichnungen vergangener Livestreams
          angezeigt.
        </Typography>
      </PlaceholderBox>
    </Container>
  );
};

export default MeetingOverview;

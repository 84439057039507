// src/components/pages/Community/UserDashboard_components/RightSidebar.js

import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Avatar } from '@mui/material';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
} from 'firebase/firestore';
import { db } from '../../../../config/firebase';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { styled } from '@mui/system';
import { format } from 'date-fns';

// Styled Components
const SidebarContainer = styled(Box)({
  width: '300px',
  padding: '20px',
  background: '', // Dunkler, transparenter Hintergrund
  color: '#fff',
  fontFamily: 'Jost, sans-serif',
  overflowY: 'auto',
  height: '350px', // Vollständige Höhe für Scrollbarkeit
  borderRadius: '8px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
});

const WidgetPaper = styled(Box)({
  position: 'relative',
  marginBottom: '20px',
  borderRadius: '8px',
  overflow: 'hidden',
  cursor: 'pointer',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  transition: 'box-shadow 0.3s',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  },
});

const ImageBox = styled(Box)({
  width: '100%',
  height: '150px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
    background: 'linear-gradient(to bottom, transparent, #2c2c2c)',
  },
});

const TextOverlay = styled(Box)({
  position: 'relative',
  marginTop: '-30px',
  padding: '10px 15px',
  background:
    'linear-gradient(to bottom, rgba(44, 44, 44, 0.8), rgba(44, 44, 44, 1))',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
});

const NoMeetingBox = styled(Box)({
  padding: '20px',
  textAlign: 'center',
  backgroundColor: '#2c2c2c',
  color: '#d5bc8b',
  borderRadius: '8px',
  marginTop: '20px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
});

const SidebarTitle = styled(Typography)({
  fontWeight: '600',
  marginBottom: '20px',
  textAlign: 'center',
  color: '#d5bc8b',
});

const RightSidebar = () => {
  const navigate = useNavigate();

  const [nextMeeting, setNextMeeting] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const now = new Date();
    const q = query(
      collection(db, 'meetings'),
      where('start_time', '>=', now),
      orderBy('start_time', 'asc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        if (!snapshot.empty) {
          const docSnap = snapshot.docs[0];
          const data = docSnap.data();
          const imageUrl = data.headerImageUrl
            ? data.headerImageUrl
            : '/images/default-meeting.jpg';
          setNextMeeting({
            id: docSnap.id,
            title: data.title,
            imageUrl: imageUrl,
            start_time: data.start_time, // Timestamp-Objekt
            trainerName: data.trainerName || 'Nicht zugewiesen',
            avatarUrl: data.avatarUrl || '/images/default-avatar.png',
          });
        } else {
          setNextMeeting(null); // Keine kommenden Meetings
        }
        setLoading(false);
      },
      (error) => {
        console.error(
          'Fehler beim Abrufen des nächsten Meetings in RightSidebar:',
          error
        );
        setError('Fehler beim Abrufen des nächsten Meetings.');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleWidgetClick = () => {
    navigate('/meeting-overview');
  };

  return (
    <SidebarContainer>
      <SidebarTitle variant="h5" gutterBottom>
        Nächster Livestream
      </SidebarTitle>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress sx={{ color: '#d5bc8b' }} />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>
          {error}
        </Typography>
      ) : nextMeeting ? (
        <motion.div
          key={nextMeeting.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <WidgetPaper onClick={handleWidgetClick}>
            {/* Abschnitt 1: Bild */}
            <ImageBox
              sx={{ backgroundImage: `url(${nextMeeting.imageUrl})` }}
            />

            {/* Abschnitt 2: Text */}
            <TextOverlay>
              <Typography
                variant="h6"
                sx={{ color: '#d5bc8b', fontWeight: '600' }}
              >
                {nextMeeting.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#fff' }}>
                Datum:{' '}
                {format(
                  new Date(nextMeeting.start_time.toDate()),
                  'dd.MM.yyyy'
                )}
              </Typography>
              <Typography variant="body2" sx={{ color: '#fff' }}>
                Uhrzeit:{' '}
                {format(new Date(nextMeeting.start_time.toDate()), 'HH:mm')} Uhr
              </Typography>
              <Typography variant="body2" sx={{ color: '#fff' }}>
                Trainer: {nextMeeting.trainerName}
              </Typography>
            </TextOverlay>
          </WidgetPaper>
        </motion.div>
      ) : (
        <NoMeetingBox>
          <Typography variant="body1">
            Keine kommenden Livestreams verfügbar.
          </Typography>
        </NoMeetingBox>
      )}
    </SidebarContainer>
  );
};

export default RightSidebar;

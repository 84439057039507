// src/components/pages/Community/UserDashboard_components/ExpandableCards.js
import React, { useEffect, useState } from 'react';
import { db, storage } from '../../../../config/firebase';
import { collection, getDocs, query, limit } from 'firebase/firestore';
import {
  Card,
  CardMedia,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  maxWidth: '100%', // Füllt den gesamten verfügbaren Platz
  width: '100%',
  overflow: 'hidden', // Verhindert das Anzeigen von Scrollbars
  gap: '16px', // Abstand zwischen den Karten
  padding: '16px 50px', // 50px Abstand zu den Sidebars
  marginBottom: '',
  justifyContent: 'center', // Zentriert die Karten innerhalb des Containers
  [theme.breakpoints.down('sm')]: {
    padding: '16px 25px', // Angepasster Abstand für kleinere Bildschirme
  },
}));

// Stil für jede Karte mit stärkerer Abrundung und Parallax-Effekt
const StyledCard = styled(Card)(({ theme, isopen }) => ({
  borderRadius: '20px', // Stärkere Abrundung der Ecken
  transition: 'width 0.6s ease, opacity 0.8s ease, transform 0.3s ease',
  boxShadow:
    isopen === 'true'
      ? '0 4px 12px rgba(0, 0, 0, 0.5)'
      : '0 2px 6px rgba(0, 0, 0, 0.2)',
  height: '170px', // Feste Höhe
  flexShrink: 0, // Verhindert das Schrumpfen der Karten
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: isopen === 'true' ? 1 : 0.25, // Abgedunkelt für nicht aktive Karten
  width: isopen === 'true' ? '45%' : '10%', // Dynamische Breite
  backgroundColor: '#252833', // Blauer Hintergrund
  border: 'none', // Kein Rahmen
  color: '#fff', // Weißer Text
  '&:hover': {
    opacity: isopen === 'true' ? 1 : 0.85, // Leichtes Aufhellen beim Hover
    transform: 'translateY(-5px)', // Subtiler Parallax-Effekt beim Hover
  },
  [theme.breakpoints.down('sm')]: {
    width: isopen === 'true' ? '90%' : '30%', // Anpassung für kleine Bildschirme
    height: '100px',
  },
}));

// Titelbereich der Karte, nur sichtbar für die geöffnete Karte
const TitleBox = styled(Box)({
  width: '100%',
  background: 'rgba(0, 0, 0, 0.6)', // Stärker abgedunkelter Hintergrund für besseren Kontrast
  color: '#fff',
  padding: '8px',
  position: 'absolute',
  bottom: 0,
  left: 0,
  textAlign: 'center',
  opacity: 1, // Immer sichtbar für die geöffnete Karte
  transition: 'opacity 0.3s ease', // Schnellere Transition
  pointerEvents: 'none', // Verhindert, dass der Titelbereich Klicks empfängt
});

const ExpandableCards = () => {
  const [videos, setVideos] = useState([]);
  const [openCard, setOpenCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        // Verwende den korrekten Sammlungsnamen "videos" (kleingeschrieben)
        const q = query(collection(db, 'videos'), limit(6));
        console.log('Abfrage an Firestore gesendet:', q);
        const querySnapshot = await getDocs(q);
        const videosData = [];
        querySnapshot.forEach((doc) => {
          console.log('Dokument gefunden:', doc.id, doc.data());
          videosData.push({ id: doc.id, ...doc.data() });
        });
        console.log('Abgerufene Videos:', videosData);
        setVideos(videosData);
        if (videosData.length > 0) {
          setOpenCard(videosData[0].id); // Initial geöffnet
        }
        setLoading(false);
      } catch (err) {
        console.error('Fehler beim Abrufen der Videos:', err);
        setError('Fehler beim Laden der Videos.');
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const handleCardClick = (id) => {
    setOpenCard(id);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (videos.length === 0) {
    return (
      <Box sx={{ padding: 2 }}>
        <Alert severity="info">Keine Videos gefunden.</Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '100%',
        width: '100%',
        margin: '0 auto',
        padding: '16px 0',
      }}
    >
      {/* Header für Neue Videos */}
      <Typography
        variant="h5"
        sx={{ mb: 0, color: '#d5bc8b', textAlign: 'center' }}
      ></Typography>

      <Container>
        {videos.map((video) => (
          <StyledCard
            key={video.id}
            isopen={openCard === video.id ? 'true' : 'false'}
            onClick={() => handleCardClick(video.id)}
            role="button"
            aria-expanded={openCard === video.id}
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCardClick(video.id);
              }
            }}
          >
            {/* Nur die geöffnete Karte zeigt den Titel */}
            {openCard === video.id && (
              <TitleBox>
                <Typography variant="subtitle1">
                  {video.video_titel || 'Titel fehlt'}
                </Typography>
              </TitleBox>
            )}
            <CardMedia
              component="img"
              image={
                video.thumbnailUrl || 'https://via.placeholder.com/300x180'
              }
              alt={video.video_titel || 'Video Thumbnail'}
              sx={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                borderRadius: '20px', // Stärkere Abrundung der Ecken
              }}
            />
          </StyledCard>
        ))}
      </Container>
    </Box>
  );
};

export default ExpandableCards;

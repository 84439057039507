import React, { useState, useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFireworksPreset } from 'tsparticles-preset-fireworks';

const ControlCenter = () => {
  const [isLive, setIsLive] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);

  const particlesInit = useCallback(async (engine) => {
    // Feuerwerk-Voreinstellung laden
    await loadFireworksPreset(engine);
  }, []);

  const handleClick = () => {
    setIsLive(true);
    setShowFireworks(true);

    // Feuerwerk nach 20 Sekunden stoppen
    setTimeout(() => {
      setShowFireworks(false);
    }, 20000);

    // Hier können weitere Hintergrundfunktionen eingefügt werden
  };

  return (
    <div style={styles.container}>
      {showFireworks && (
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{ preset: 'fireworks' }}
          style={styles.fireworks}
        />
      )}
      <div style={styles.content}>
        <button style={styles.button} onClick={handleClick}>
          {isLive ? 'Plattform ist live!' : 'Plattform live schalten'}
        </button>
        {isLive && (
          <p style={styles.status}>
            Die Plattform wurde erfolgreich live geschaltet.
          </p>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: '#000', // Dunkler Hintergrund für bessere Sichtbarkeit
  },
  fireworks: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    pointerEvents: 'none',
  },
  content: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
  },
  button: {
    padding: '20px 40px',
    fontSize: '24px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#6200ee',
    color: '#ffffff',
    transition: 'background-color 0.3s, transform 0.3s',
  },
  status: {
    marginTop: '20px',
    fontSize: '18px',
    color: '#ffffff',
  },
};

export default ControlCenter;

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import DOMPurify from 'dompurify';

const EmailManager = () => {
  const db = getFirestore();
  const textAreaRef = useRef(null);

  const [template, setTemplate] = useState('welcome');
  const [subject, setSubject] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isPreviewMode, setIsPreviewMode] = useState(true); // Vorschau-Modus aktivieren

  const placeholders = [
    { name: 'Name', value: '{{name}}' },
    { name: 'E-Mail', value: '{{email}}' },
    { name: 'Passwortlink', value: '{{passwordLink}}' },
  ];

  const predefinedTemplates = {
    welcome: {
      subject: 'Willkommen bei KeyToHorses',
      htmlContent: `
        <!DOCTYPE html>
        <html lang="de">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Willkommen bei KeyToHorses</title>
        </head>
        <body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #252833; color: #ffffff;">
          <div style="max-width: 600px; margin: 20px auto; background-color: #252833; border-radius: 10px; overflow: hidden; padding: 20px;">
            <div style="height: 20px;"></div>
            <div style="text-align: center; margin-bottom: 20px;">
              <img src="/images/logos/kth_logo_horse.png" alt="KeyToHorses Logo" style="max-width: 150px; display: block; margin: 0 auto;">
            </div>
            <div style="padding: 20px; text-align: center;">
              <h1 style="font-size: 28px; margin-bottom: 20px; color: #ffffff;">Herzlich Willkommen bei KeyToHorses!</h1>
              <p style="font-size: 16px; line-height: 1.8; margin-bottom: 20px; color: #ffffff;">Wir freuen uns, Dich als Teil der Community begrüßen zu dürfen und sind schon ganz gespannt auf den Weg, den wir zusammen gehen werden.</p>
              <p style="font-size: 16px; line-height: 1.8; margin-bottom: 20px; color: #ffffff;">Damit du dich auf der Seite unter <strong>members.keytohorses.com</strong> einloggen kannst, musst du ein Passwort vergeben. Dies kannst du ganz einfach tun, indem du auf den Button unten klickst.</p>
              <div style="margin-top: 30px;">
                <a href="{{passwordLink}}" style="display: inline-block; padding: 15px 30px; font-size: 16px; font-weight: bold; text-decoration: none; color: #252833; background-color: #d5bc8b; border-radius: 5px;">Jetzt Passwort festlegen</a>
              </div>
            </div>
            <div style="margin-top: 40px; font-size: 14px; text-align: center; color: #a0a0a0; border-top: 1px solid #3a3d4b; padding-top: 20px;">
              <p>&copy; 2024 KeyToHorses. Alle Rechte vorbehalten.</p>
            </div>
          </div>
        </body>
        </html>
      `,
    },
    passwordReset: {
      subject: 'Passwort zurücksetzen',
      htmlContent: `
        <!DOCTYPE html>
        <html lang="de">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Passwort zurücksetzen</title>
        </head>
        <body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #252833; color: #ffffff;">
          <div style="max-width: 600px; margin: 20px auto; background-color: #252833; border-radius: 10px; overflow: hidden; padding: 20px;">
            <div style="height: 20px;"></div>
            <div style="text-align: center; margin-bottom: 20px;">
              <img src="/images/logos/kth_logo_horse.png" alt="KeyToHorses Logo" style="max-width: 150px; display: block; margin: 0 auto;">
            </div>
            <div style="padding: 20px; text-align: center;">
              <h1 style="font-size: 28px; margin-bottom: 20px; color: #ffffff;">Passwort zurücksetzen</h1>
              <p style="font-size: 16px; line-height: 1.8; margin-bottom: 20px; color: #ffffff;">Hallo {{name}},</p>
              <p style="font-size: 16px; line-height: 1.8; margin-bottom: 20px; color: #ffffff;">Du hast eine Anfrage zum Zurücksetzen deines Passworts gestellt. Klicke auf den Button unten, um ein neues Passwort zu vergeben.</p>
              <div style="margin-top: 30px;">
                <a href="{{passwordLink}}" style="display: inline-block; padding: 15px 30px; font-size: 16px; font-weight: bold; text-decoration: none; color: #252833; background-color: #d5bc8b; border-radius: 5px;">Passwort zurücksetzen</a>
              </div>
              <p style="font-size: 14px; line-height: 1.6; margin-top: 20px; color: #ffffff;">Wenn du diese Anfrage nicht gestellt hast, kannst du diese E-Mail ignorieren. Dein Passwort bleibt unverändert.</p>
            </div>
            <div style="margin-top: 40px; font-size: 14px; text-align: center; color: #a0a0a0; border-top: 1px solid #3a3d4b; padding-top: 20px;">
              <p>&copy; 2024 KeyToHorses. Alle Rechte vorbehalten.</p>
            </div>
          </div>
        </body>
        </html>
      `,
    },
  };

  const loadTemplate = async (templateName) => {
    setLoading(true);
    try {
      const docRef = doc(db, 'emailTemplates', templateName);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setSubject(data.subject || predefinedTemplates[templateName].subject);
        setHtmlContent(
          data.htmlContent || predefinedTemplates[templateName].htmlContent
        );
      } else {
        setSubject(predefinedTemplates[templateName].subject);
        setHtmlContent(predefinedTemplates[templateName].htmlContent);
      }
    } catch (error) {
      console.error('Fehler beim Laden der Vorlage:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTemplate(template);
  }, [template]);

  const handleSave = async () => {
    try {
      await setDoc(
        doc(db, 'emailTemplates', template),
        { subject, htmlContent },
        { merge: true }
      );
      setSuccessMsg('E-Mail-Vorlage erfolgreich gespeichert!');
    } catch (error) {
      console.error('Fehler beim Speichern der Vorlage:', error);
      setErrorMsg('Fehler beim Speichern der Vorlage.');
    }
  };

  const togglePreview = () => {
    setIsPreviewMode(!isPreviewMode);
  };

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        width: '100%',
        mx: 'auto',
        mt: 4,
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: '#fff',
      }}
    >
      <Typography variant="h5" gutterBottom>
        E-Mail Vorlagen bearbeiten
      </Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="template-select-label">Vorlage auswählen</InputLabel>
        <Select
          labelId="template-select-label"
          value={template}
          onChange={(e) => setTemplate(e.target.value)}
        >
          <MenuItem value="welcome">Willkommens-E-Mail</MenuItem>
          <MenuItem value="passwordReset">Passwort zurücksetzen</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label="Betreff"
        variant="outlined"
        fullWidth
        required
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        sx={{ mb: 2 }}
      />

      {isPreviewMode ? (
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: 2,
            p: 2,
            mb: 2,
            backgroundColor: '#f9f9f9',
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(htmlContent),
          }}
        />
      ) : (
        <TextField
          label="HTML-Inhalt"
          variant="outlined"
          fullWidth
          multiline
          minRows={10}
          value={htmlContent}
          inputRef={textAreaRef}
          onChange={(e) => setHtmlContent(e.target.value)}
          sx={{ mb: 2, fontFamily: 'monospace' }}
        />
      )}

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            fullWidth
          >
            Vorlage speichern
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="primary"
            onClick={togglePreview}
            fullWidth
          >
            {isPreviewMode ? 'HTML anzeigen' : 'Vorschau anzeigen'}
          </Button>
        </Grid>
      </Grid>

      {successMsg && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {successMsg}
        </Alert>
      )}
      {errorMsg && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMsg}
        </Alert>
      )}
    </Box>
  );
};

export default EmailManager;

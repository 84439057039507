// src/components/System.js

import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { getAuth } from 'firebase/auth';
import { getFirestore, writeBatch, doc, setDoc } from 'firebase/firestore';
import axios from 'axios'; // Für API-Aufrufe

// Zugriff auf die Auth-Instanz und Firestore
const auth = getAuth();
const db = getFirestore();

const System = () => {
  // Zustände für Benutzererstellung
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  const [userSuccessMsg, setUserSuccessMsg] = useState('');
  const [userErrorMsg, setUserErrorMsg] = useState('');

  // Zustände für Firestore-Strukturierung
  const [initLoading, setInitLoading] = useState(false);
  const [initSuccessMsg, setInitSuccessMsg] = useState('');
  const [initErrorMsg, setInitErrorMsg] = useState('');

  // Funktion zum Erstellen eines neuen Benutzers
  const handleCreateUser = async () => {
    setUserLoading(true);
    setUserSuccessMsg('');
    setUserErrorMsg('');

    try {
      // Holen Sie sich den aktuellen Benutzer
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setUserErrorMsg('Keine authentifizierte Sitzung gefunden.');
        setUserLoading(false);
        return;
      }

      // Holen Sie sich den ID-Token des aktuellen Benutzers
      const idToken = await currentUser.getIdToken();

      // Backend-API-Endpunkt (passen Sie die URL entsprechend an)
      const createUserUrl =
        'https://europe-west3-keytohorses-firebase.cloudfunctions.net/api/createUser'; // Ersetzen Sie durch Ihre tatsächliche URL

      // Senden Sie eine POST-Anfrage an die Backend-Route /createUser
      const response = await axios.post(
        createUserUrl,
        {
          email,
          displayName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const data = response.data;

      if (response.status === 200 && data.success) {
        setUserSuccessMsg(
          'Benutzer erfolgreich erstellt und Willkommens-E-Mail gesendet.'
        );
        setEmail('');
        setDisplayName('');
      } else {
        setUserErrorMsg(data.error || 'Fehler beim Erstellen des Benutzers.');
      }
    } catch (error) {
      console.error('Fehler beim Erstellen des Benutzers:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setUserErrorMsg(error.response.data.error);
      } else {
        setUserErrorMsg(
          error.message || 'Es gab einen Fehler beim Erstellen des Benutzers.'
        );
      }
    } finally {
      setUserLoading(false);
    }
  };

  // Funktion zum Initialisieren der Firestore-Struktur direkt aus dem Frontend
  const handleInitializeFirestore = async () => {
    setInitLoading(true);
    setInitSuccessMsg('');
    setInitErrorMsg('');

    try {
      // Holen Sie sich den aktuellen Benutzer
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setInitErrorMsg('Keine authentifizierte Sitzung gefunden.');
        setInitLoading(false);
        return;
      }

      // Optional: Überprüfen Sie, ob der Benutzer ein Administrator ist
      // Dies sollte durch Firestore-Sicherheitsregeln sichergestellt werden

      // Definieren der E-Mail-Vorlagen
      const emailTemplates = {
        welcome: {
          subject: 'Willkommen bei unserem Dienst!',
          body: `
            <p>Hallo {{displayName}},</p>
            <p>Willkommen bei unserem Dienst! Wir freuen uns, Sie an Bord zu haben.</p>
            <p>Bitte klicken Sie auf den folgenden Link, um Ihr Passwort festzulegen:</p>
            <p><a href="http://localhost/reset-password?token={{resetLink}}">Passwort festlegen</a></p>
            <p>Falls der Link nicht funktioniert, kopieren Sie bitte die folgende URL in Ihren Browser:</p>
            <p>http://localhost/reset-password?token={{resetLink}}</p>
            <p>Mit freundlichen Grüßen,<br/>Ihr Team</p>
          `,
        },
        passwordReset: {
          subject: 'Passwort zurücksetzen',
          body: `
            <p>Hallo {{displayName}},</p>
            <p>Sie haben eine Anfrage zum Zurücksetzen Ihres Passworts gestellt.</p>
            <p>Klicken Sie auf den folgenden Link, um Ihr Passwort zurückzusetzen:</p>
            <p><a href="http://localhost/reset-password?token={{resetLink}}">Passwort zurücksetzen</a></p>
            <p>Falls der Link nicht funktioniert, kopieren Sie bitte die folgende URL in Ihren Browser:</p>
            <p>http://localhost/reset-password?token={{resetLink}}</p>
            <p>Mit freundlichen Grüßen,<br/>Ihr Team</p>
          `,
        },
      };

      // Initialisieren des Batches
      const batch = writeBatch(db);

      // Erstellen von Referenzen zu den Dokumenten
      const welcomeDocRef = doc(db, 'emailTemplates', 'welcome');
      const passwordResetDocRef = doc(db, 'emailTemplates', 'passwordReset');

      // Hinzufügen von Schreiboperationen zum Batch
      batch.set(welcomeDocRef, emailTemplates.welcome, { merge: true });
      batch.set(passwordResetDocRef, emailTemplates.passwordReset, {
        merge: true,
      });

      // Commit des Batches
      await batch.commit();

      console.log('Firestore-Struktur erfolgreich eingerichtet.');
      setInitSuccessMsg('Firestore-Struktur erfolgreich eingerichtet.');
    } catch (error) {
      console.error('Fehler beim Einrichten der Firestore-Struktur:', error);
      setInitErrorMsg(
        error.message ||
          'Es gab einen Fehler beim Einrichten der Firestore-Struktur.'
      );
    } finally {
      setInitLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: 'auto',
        mt: 4,
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: '#fff',
      }}
    >
      <Typography variant="h5" gutterBottom>
        Benutzerverwaltung
      </Typography>

      {/* Abschnitt zum Erstellen eines neuen Benutzers */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h6" gutterBottom>
          Neuen Benutzer erstellen
        </Typography>
        <TextField
          label="E-Mail-Adresse"
          variant="outlined"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Anzeigename"
          variant="outlined"
          fullWidth
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleCreateUser}
          disabled={userLoading || !email}
          startIcon={userLoading && <CircularProgress size={20} />}
          sx={{ mb: 2 }}
        >
          {userLoading ? 'Benutzer wird erstellt...' : 'Benutzer erstellen'}
        </Button>
        {userSuccessMsg && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {userSuccessMsg}
          </Alert>
        )}
        {userErrorMsg && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {userErrorMsg}
          </Alert>
        )}
      </Box>

      {/* Abschnitt zum Initialisieren der Firestore-Struktur */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Firestore-Struktur einrichten
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={handleInitializeFirestore}
          disabled={initLoading}
          startIcon={initLoading && <CircularProgress size={20} />}
          sx={{ mb: 2 }}
        >
          {initLoading
            ? 'Einrichtung läuft...'
            : 'Firestore-Struktur einrichten'}
        </Button>
        {initSuccessMsg && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {initSuccessMsg}
          </Alert>
        )}
        {initErrorMsg && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {initErrorMsg}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default System;

// src/components/Administration/TestEditor/TestListModal.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  loadTestsFromDB,
  deleteTestFromDB,
  setLiveTest,
  updateTestInDB,
  saveTestToDB,
  setTestingTest,
} from '../../../firebaseFunctions';
import { FaTrashAlt, FaEdit, FaRocket, FaSave, FaFlask } from 'react-icons/fa';
import '@szhsin/react-menu/dist/index.css';

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 40, 51, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ModalContainer = styled.div`
  background-color: #1f1f1f;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 1000px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  color: #ffffff;
  font-size: 28px;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  font-size: 28px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #ff6b6b;
  }
`;

const SaveSection = styled.div`
  margin-bottom: 20px;
`;

const TestNameInput = styled.input`
  padding: 10px;
  width: calc(100% - 20px);
  font-size: 16px;
  margin-bottom: 10px;
`;

const SaveButton = styled.button`
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-right: 10px;

  &:hover {
    background-color: #218838;
  }
`;

const TestTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #2b2b2b;
  color: #ffffff;
  padding: 15px;
  text-align: left;
  font-weight: 600;
  border-bottom: 2px solid #3d3d3d;
`;

const TableCell = styled.td`
  padding: 15px;
  border-bottom: 1px solid #3d3d3d;
  color: #cccccc;
  vertical-align: middle;
  position: relative;
`;

const StatusBadge = styled.span`
  display: inline-block;
  padding: 6px 12px;
  border-radius: 9999px;
  background-color: ${(props) => {
    switch (props.status) {
      case 'draft':
        return '#6c757d';
      case 'testing':
        return '#17a2b8';
      case 'testing_completed':
        return '#ffc107';
      case 'released':
        return '#28a745';
      case 'live':
        return '#dc3545';
      default:
        return '#6c757d';
    }
  }};
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
`;

const ActionIcon = styled.button`
  background: none;
  border: none;
  color: #cccccc;
  font-size: 18px;
  cursor: pointer;
  padding: 8px;
  transition: color 0.3s;

  &:hover {
    color: #ffffff;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const TestListModal = ({
  isOpen,
  onClose,
  setCurrentTestId,
  nodes,
  edges,
  mode = 'load', // 'load' oder 'save'
  currentTestName,
  setCurrentTestName,
  currentTestStatus,
  setCurrentTestStatus,
}) => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [liveTestId, setLiveTestId] = useState(null);
  const [testName, setTestName] = useState(currentTestName || '');
  const [selectedTestId, setSelectedTestId] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchTests();
    }
  }, [isOpen]);

  const fetchTests = async () => {
    setLoading(true);
    try {
      const loadedTests = await loadTestsFromDB();
      setTests(loadedTests);
      const liveTest = loadedTests.find((test) => test.status === 'live');
      setLiveTestId(liveTest ? liveTest.id : null);
    } catch (error) {
      console.error('Fehler beim Laden der Tests:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Möchtest du dieses Test-Design wirklich löschen?')) {
      try {
        await deleteTestFromDB(id);
        setTests(tests.filter((test) => test.id !== id));
      } catch (error) {
        console.error('Fehler beim Löschen des Tests:', error);
      }
    }
  };

  const handleEdit = (id) => {
    setCurrentTestId(id);
    onClose();
  };

  const handleSetLive = async (id) => {
    try {
      await setLiveTest(id);
      await fetchTests();
      alert('Test auf "live" gesetzt.');
    } catch (error) {
      alert('Fehler beim Setzen des Live-Status.');
    }
  };

  const handleSetTesting = async (id) => {
    try {
      await setTestingTest(id);
      await fetchTests();
      alert('Test auf "testing" gesetzt.');
    } catch (error) {
      alert('Fehler beim Setzen des Testing-Status.');
    }
  };

  const handleSave = async () => {
    if (!testName) {
      alert('Bitte gib einen Testnamen ein.');
      return;
    }

    try {
      if (selectedTestId) {
        // Prüfen, ob der Test überschrieben werden kann
        const testToOverwrite = tests.find((test) => test.id === selectedTestId);
        if (testToOverwrite.status === 'live') {
          alert('Live-Tests können nicht überschrieben werden.');
          return;
        }
        // Versionsnummer erhöhen
        const newVersion = (testToOverwrite.version || 1) + 1;
        await updateTestInDB(selectedTestId, {
          nodes,
          edges,
          testName,
          version: newVersion,
          lastEdited: new Date(),
          status: currentTestStatus || 'draft',
        });
        setCurrentTestId(selectedTestId);
      } else {
        // Neuen Test speichern
        await saveTestToDB({
          nodes,
          edges,
          testName,
          version: 1,
          status: currentTestStatus || 'draft',
          lastEdited: new Date(),
        });
      }
      setCurrentTestName(testName);
      alert('Test erfolgreich gespeichert.');
      onClose();
    } catch (error) {
      console.error('Fehler beim Speichern des Tests:', error);
      alert('Fehler beim Speichern des Tests.');
    }
  };

  return isOpen ? (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>
            {mode === 'save' ? 'Test speichern' : 'Verwalte Einschätzungstests'}
          </ModalTitle>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        {mode === 'save' && (
          <SaveSection>
            <TestNameInput
              type="text"
              placeholder="Testname eingeben"
              value={testName}
              onChange={(e) => setTestName(e.target.value)}
            />
            <SaveButton onClick={handleSave}>
              <FaSave /> Speichern
            </SaveButton>
          </SaveSection>
        )}
        {loading ? (
          <p style={{ color: '#ffffff' }}>Lade Tests...</p>
        ) : (
          <TestTable>
            <thead>
              <tr>
                <TableHeader>Name</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Version</TableHeader>
                <TableHeader>Letzte Bearbeitung</TableHeader>
                <TableHeader style={{ textAlign: 'center' }}>Aktionen</TableHeader>
              </tr>
            </thead>
            <tbody>
              {tests.map((test) => (
                <tr key={test.id}>
                  <TableCell>
                    {mode === 'save' ? (
                      <input
                        type="radio"
                        name="test"
                        value={test.id}
                        disabled={test.status === 'live'}
                        onChange={() => {
                          setSelectedTestId(test.id);
                          setTestName(test.testName);
                        }}
                      />
                    ) : null}
                    {test.testName}
                  </TableCell>
                  <TableCell>
                    <StatusBadge status={test.status}>{test.status}</StatusBadge>
                  </TableCell>
                  <TableCell>{test.version}</TableCell>
                  <TableCell>
                    {test.lastEdited
                      ? new Date(test.lastEdited.toDate()).toLocaleString()
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <ActionsWrapper>
                      {mode === 'load' && (
                        <>
                          <ActionIcon
                            onClick={() => handleEdit(test.id)}
                            title="Bearbeiten"
                          >
                            <FaEdit />
                          </ActionIcon>
                          <ActionIcon
                            onClick={() => handleDelete(test.id)}
                            title="Löschen"
                          >
                            <FaTrashAlt />
                          </ActionIcon>
                          {test.status !== 'live' && (
                            <>
                              <ActionIcon
                                onClick={() => handleSetTesting(test.id)}
                                title="Testing"
                              >
                                <FaFlask />
                              </ActionIcon>
                              <ActionIcon
                                onClick={() => handleSetLive(test.id)}
                                title="Live schalten"
                              >
                                <FaRocket />
                              </ActionIcon>
                            </>
                          )}
                        </>
                      )}
                    </ActionsWrapper>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </TestTable>
        )}
      </ModalContainer>
    </ModalOverlay>
  ) : null;
};

export default TestListModal;

// src/components/Administration/TestEditor/TestEditor.js

import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import styled from 'styled-components';
import 'reactflow/dist/style.css';
import CustomNode from './TestEditor/CustomNode';
import QuestionModal from './TestEditor/QuestionModal';
import TestListModal from './TestEditor/TestListModal';
import {
  FaPlus,
  FaSave,
  FaFolderOpen,
  FaTrash,
  FaUndo,
  FaRedo,
} from 'react-icons/fa';
import { colors } from '../../styles/colors';
import {
  saveTestToDB,
  loadTestById,
  updateTestInDB,
  loadTestsFromDB,
} from '../../firebaseFunctions';

const nodeTypes = {
  questionNode: CustomNode,
};

// Styled Components
const EditorContainer = styled.div`
  height: 80vh;
  width: 100%;
  position: relative;
  background-color: ${colors.background};
`;

const MenuBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #252833;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;

  @media (max-width: 768px) {
    padding: 10px;
    gap: 15px;
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => (props.active ? colors.gold : colors.white)};
  cursor: pointer;
  font-size: 24px;
  transition: color 0.3s, transform 0.2s;

  &:hover {
    color: ${colors.gold};
    transform: scale(1.2);
  }

  &:disabled {
    color: ${colors.grey};
    cursor: not-allowed;
  }
`;

const StyledMiniMap = styled(MiniMap)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 100px;
  width: 100px;
  border: 2px solid ${colors.gold};
  border-radius: 8px;
  background-color: ${colors.darkBlue};
`;

const TestNameDisplay = styled.div`
  color: ${colors.gold};
  font-size: 18px;
  font-weight: bold;
`;

const StatusDisplay = styled.div`
  color: ${(props) =>
    props.status === 'live'
      ? 'green'
      : props.status === 'testing'
      ? 'orange'
      : 'red'};
  font-size: 18px;
  font-weight: bold;
`;

const TestEditor = () => {
  // States and hooks
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodeId, setNodeId] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);

  // History for undo/redo
  const [history, setHistory] = useState([]);
  const [future, setFuture] = useState([]);

  // State for modals
  const [testListModalOpen, setTestListModalOpen] = useState(false);

  // State for current test
  const [currentTestId, setCurrentTestId] = useState(null);
  const [currentTestName, setCurrentTestName] = useState('Unbenannter Test');
  const [currentTestStatus, setCurrentTestStatus] = useState('draft');

  // Funktion zum Speichern des aktuellen Zustands für Undo/Redo
  const saveHistory = () => {
    const currentState = {
      nodes: JSON.parse(JSON.stringify(nodes)),
      edges: JSON.parse(JSON.stringify(edges)),
      testName: currentTestName,
      testStatus: currentTestStatus,
    };
    setHistory((prevHistory) => [...prevHistory, currentState]);
    setFuture([]);
  };

  // Funktion zum Laden eines Tests anhand der ID
  useEffect(() => {
    const fetchTest = async () => {
      if (currentTestId) {
        try {
          const testData = await loadTestById(currentTestId);
          if (testData) {
            setNodes(testData.nodes || []);
            setEdges(testData.edges || []);
            setCurrentTestName(testData.testName || 'Unbenannter Test');
            setCurrentTestStatus(testData.status || 'draft');
            setNodeId(testData.nodes?.length + 1 || 1);
          } else {
            alert('Test nicht gefunden.');
          }
        } catch (error) {
          console.error('Fehler beim Laden des Tests:', error);
          alert('Fehler beim Laden des Tests.');
        }
      }
    };
    fetchTest();
  }, [currentTestId]);

  // Funktion zum Hinzufügen eines neuen Frage-Knotens
  const addQuestionNode = () => {
    const newNode = {
      id: `node_${nodeId}`,
      type: 'questionNode',
      data: {
        label: `Frage ${nodeId}`,
        text: `Frage ${nodeId}`,
        answers: [],
        maxSelectableAnswers: 1,
        isStartNode: false, // Standardmäßig kein Startknoten
      },
      position: { x: Math.random() * 250, y: Math.random() * 250 },
    };
    saveHistory();
    setNodes((nds) => nds.concat(newNode));
    setNodeId(nodeId + 1);
    setSelectedNodeId(newNode.id);
    // Status auf 'draft' setzen, wenn geändert
    if (currentTestStatus !== 'draft') {
      setCurrentTestStatus('draft');
    }
  };

  // Funktion zum Verbinden von Knoten
  const onConnectCallback = useCallback(
    (params) => {
      saveHistory();
      setEdges((eds) =>
        addEdge({ ...params, style: { stroke: colors.gold } }, eds)
      );
      // Status auf 'draft' setzen, wenn geändert
      if (currentTestStatus !== 'draft') {
        setCurrentTestStatus('draft');
      }
    },
    [setEdges, currentTestStatus]
  );

  // Funktion beim Doppelklick auf einen Node
  const onNodeDoubleClick = useCallback((event, node) => {
    if (node.type === 'questionNode' && node.data) {
      setCurrentNode(node);
      setModalOpen(true);
    }
  }, []);

  // Node löschen
  const deleteSelectedNode = () => {
    if (!selectedNodeId) return;
    const confirmDelete = window.confirm(
      'Möchtest du diesen Node wirklich löschen?'
    );
    if (confirmDelete) {
      saveHistory();
      setNodes((nds) => nds.filter((node) => node.id !== selectedNodeId));
      setEdges((eds) =>
        eds.filter(
          (edge) =>
            edge.source !== selectedNodeId && edge.target !== selectedNodeId
        )
      );
      setSelectedNodeId(null);
      // Status auf 'draft' setzen, wenn geändert
      if (currentTestStatus !== 'draft') {
        setCurrentTestStatus('draft');
      }
    }
  };

  // Node auswählen
  const onNodeClick = useCallback((event, node) => {
    setSelectedNodeId(node.id);
  }, []);

  // Speichern der aktualisierten Frage
  const handleSaveQuestion = (updatedData) => {
    saveHistory();

    // Wenn der aktuelle Node als Startknoten festgelegt wurde, setze alle anderen Nodes auf false
    if (updatedData.isStartNode) {
      setNodes((nds) =>
        nds.map((node) => ({
          ...node,
          data: {
            ...node.data,
            ...(node.id === currentNode.id
              ? {
                  ...updatedData,
                  label: updatedData.text,
                  isStartNode: true,
                }
              : {
                  isStartNode: false,
                }),
          },
        }))
      );
    } else {
      // Wenn der aktuelle Node nicht als Startknoten festgelegt wurde, aktualisiere nur die Frage
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === currentNode.id) {
            return {
              ...node,
              data: {
                ...node.data,
                ...updatedData,
                label: updatedData.text,
                isStartNode: false,
              },
            };
          }
          return node;
        })
      );
    }

    // Aktualisiere Verbindungen
    setEdges((eds) => eds.filter((edge) => edge.source !== currentNode.id));

    const newEdges = updatedData.answers
      .filter((answer) => answer.next && answer.next !== 'end')
      .map((answer, index) => ({
        id: `e${currentNode.id}-${answer.next}-${index}`,
        source: currentNode.id,
        target: answer.next,
        label: answer.text,
        animated: true,
        type: 'smoothstep',
        style: { stroke: colors.gold },
        data: {
          answerIndex: index, // Antwortindex speichern
        },
      }));

    setEdges((eds) => eds.concat(newEdges));

    // Status auf 'draft' setzen, wenn geändert
    if (currentTestStatus !== 'draft') {
      setCurrentTestStatus('draft');
    }
  };

  // Undo-Funktion
  const undo = () => {
    if (history.length === 0) return;
    const previousState = history[history.length - 1];
    const newHistory = history.slice(0, history.length - 1);
    setHistory(newHistory);
    setFuture((prevFuture) => [
      {
        nodes: JSON.parse(JSON.stringify(nodes)),
        edges: JSON.parse(JSON.stringify(edges)),
        testName: currentTestName,
        testStatus: currentTestStatus,
      },
      ...prevFuture,
    ]);
    setNodes(previousState.nodes);
    setEdges(previousState.edges);
    setSelectedNodeId(null);
    setCurrentTestName(previousState.testName || 'Unbenannter Test');
    setCurrentTestStatus(previousState.testStatus || 'draft');
  };

  // Redo-Funktion
  const redo = () => {
    if (future.length === 0) return;
    const nextState = future[0];
    const newFuture = future.slice(1);
    setFuture(newFuture);
    setHistory((prevHistory) => [
      ...prevHistory,
      {
        nodes: JSON.parse(JSON.stringify(nodes)),
        edges: JSON.parse(JSON.stringify(edges)),
        testName: currentTestName,
        testStatus: currentTestStatus,
      },
    ]);
    setNodes(nextState.nodes);
    setEdges(nextState.edges);
    setSelectedNodeId(null);
    setCurrentTestName(nextState.testName || 'Unbenannter Test');
    setCurrentTestStatus(nextState.testStatus || 'draft');
  };

  // Funktion zum Öffnen des TestListModal
  const openTestListModal = (mode) => {
    setTestListModalOpen(mode); // mode can be 'save' or 'load'
  };

  return (
    <EditorContainer>
      <MenuBar>
        {/* Linke Seite der Menüleiste */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <IconButton onClick={addQuestionNode} title="Frage hinzufügen">
            <FaPlus />
          </IconButton>
          <IconButton
            onClick={deleteSelectedNode}
            title="Ausgewählten Knoten löschen"
            disabled={!selectedNodeId}
          >
            <FaTrash />
          </IconButton>
          <IconButton
            onClick={undo}
            title="Rückgängig"
            disabled={history.length === 0}
          >
            <FaUndo />
          </IconButton>
          <IconButton
            onClick={redo}
            title="Wiederherstellen"
            disabled={future.length === 0}
          >
            <FaRedo />
          </IconButton>
        </div>
        {/* Mittlerer Teil der Menüleiste */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TestNameDisplay>{currentTestName}</TestNameDisplay>
          <StatusDisplay status={currentTestStatus}>
            Status: {currentTestStatus}
          </StatusDisplay>
        </div>
        {/* Rechte Seite der Menüleiste */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <IconButton
            onClick={() => openTestListModal('save')}
            title="Test speichern"
          >
            <FaSave />
          </IconButton>
          <IconButton
            onClick={() => openTestListModal('load')}
            title="Test laden"
          >
            <FaFolderOpen />
          </IconButton>
          {/* Weitere Buttons können hier hinzugefügt werden */}
        </div>
      </MenuBar>
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          selected: node.id === selectedNodeId,
          hasLinks: edges.some((edge) => edge.source === node.id),
        }))}
        edges={edges}
        onNodesChange={(changes) => {
          onNodesChange(changes);
        }}
        onEdgesChange={(changes) => {
          onEdgesChange(changes);
        }}
        onConnect={onConnectCallback}
        onNodeDoubleClick={onNodeDoubleClick}
        onNodeClick={onNodeClick}
        nodeTypes={nodeTypes}
        defaultViewport={{ x: 0, y: 0, zoom: 0.6 }}
        style={{ background: colors.background }}
        onPaneClick={() => setSelectedNodeId(null)}
      >
        <StyledMiniMap
          nodeColor={(node) => {
            switch (node.type) {
              case 'questionNode':
                return colors.gold;
              default:
                return '#eee';
            }
          }}
        />
        <Controls />
        <Background color={colors.gold} gap={16} />
      </ReactFlow>
      <QuestionModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSaveQuestion}
        question={currentNode?.data}
        questionId={currentNode?.id}
        elements={{ nodes, edges }}
      />
      {/* TestListModal für Speichern und Laden */}
      <TestListModal
        isOpen={testListModalOpen !== false}
        onClose={() => setTestListModalOpen(false)}
        setCurrentTestId={(id) => {
          setCurrentTestId(id);
          setTestListModalOpen(false);
        }}
        nodes={nodes}
        edges={edges}
        mode={testListModalOpen}
        currentTestName={currentTestName}
        setCurrentTestName={setCurrentTestName}
        currentTestStatus={currentTestStatus}
        setCurrentTestStatus={setCurrentTestStatus}
      />
    </EditorContainer>
  );
};

export default TestEditor;

// src/components/pages/Community/UserDashboard_components/LeftSidebar.js
import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

const LeftSidebar = () => {
  const links = [
    { text: 'Pferdinand', path: '/' },
    { text: 'Rosswita', path: '/community' },
    { text: 'Don', path: '/learning' },
    // Weitere Links hier hinzufügen
  ];
};

export default LeftSidebar;

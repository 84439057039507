// src/firebaseFunctions.js

import {
  collection,
  addDoc,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  query,
  where,
  serverTimestamp,
  writeBatch,
  getDoc,
} from 'firebase/firestore';
import { db } from './config/firebase';

// Test speichern
export const saveTestToDB = async (testDesign) => {
  try {
    const docRef = await addDoc(collection(db, 'testDesigns'), {
      ...testDesign,
      lastEdited: serverTimestamp(),
      createdAt: serverTimestamp(),
      status: testDesign.status || 'draft', // Behalte den übergebenen Status bei oder setze 'draft'
      version: testDesign.version || 1, // Behalte die übergebene Version bei oder setze 1
    });
    console.log('Test-Design gespeichert mit ID: ', docRef.id);
    return docRef.id;
  } catch (e) {
    console.error('Fehler beim Speichern des Test-Designs: ', e);
    throw e;
  }
};

// Alle gespeicherten Tests laden
export const loadTestsFromDB = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'testDesigns'));
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (e) {
    console.error('Fehler beim Laden der Test-Designs: ', e);
    throw e;
  }
};

// Test-Design löschen
export const deleteTestFromDB = async (id) => {
  try {
    await deleteDoc(doc(db, 'testDesigns', id));
    console.log('Test-Design gelöscht mit ID: ', id);
  } catch (e) {
    console.error('Fehler beim Löschen des Test-Designs: ', e);
    throw e;
  }
};

// Test-Design updaten
export const updateTestInDB = async (id, updatedData) => {
  try {
    await updateDoc(doc(db, 'testDesigns', id), {
      ...updatedData,
      lastEdited: serverTimestamp(),
    });
    console.log('Test-Design aktualisiert mit ID: ', id);
  } catch (e) {
    console.error('Fehler beim Aktualisieren des Test-Designs: ', e);
    throw e;
  }
};

// Setze einen Test auf "live" und aktualisiere andere Tests
export const setLiveTest = async (id) => {
  try {
    // Lade alle Tests mit dem Status 'live' oder 'testing'
    const q = query(
      collection(db, 'testDesigns'),
      where('status', 'in', ['live', 'testing'])
    );
    const querySnapshot = await getDocs(q);

    // Erstelle einen Batch-Schreibvorgang
    const batch = writeBatch(db);

    // Setze den Status aller anderen Tests auf 'released'
    querySnapshot.forEach((docSnap) => {
      if (docSnap.id !== id) {
        batch.update(doc(db, 'testDesigns', docSnap.id), { status: 'released' });
      }
    });

    // Setze den Status des ausgewählten Tests auf 'live'
    batch.update(doc(db, 'testDesigns', id), { status: 'live' });

    // Führe den Batch-Commit aus
    await batch.commit();
    console.log('Test auf "live" gesetzt und andere Tests aktualisiert.');
  } catch (error) {
    console.error('Fehler beim Setzen des Live-Status:', error);
    throw error;
  }
};

// Setze einen Test auf "testing" und aktualisiere andere Tests
export const setTestingTest = async (id) => {
  try {
    // Lade alle Tests mit dem Status 'testing'
    const q = query(collection(db, 'testDesigns'), where('status', '==', 'testing'));
    const querySnapshot = await getDocs(q);

    // Erstelle einen Batch-Schreibvorgang
    const batch = writeBatch(db);

    // Setze den Status aller anderen 'testing' Tests auf 'released'
    querySnapshot.forEach((docSnap) => {
      if (docSnap.id !== id) {
        batch.update(doc(db, 'testDesigns', docSnap.id), { status: 'released' });
      }
    });

    // Setze den Status des ausgewählten Tests auf 'testing'
    batch.update(doc(db, 'testDesigns', id), { status: 'testing' });

    // Führe den Batch-Commit aus
    await batch.commit();
    console.log('Test auf "testing" gesetzt und andere Tests aktualisiert.');
  } catch (error) {
    console.error('Fehler beim Setzen des Testing-Status:', error);
    throw error;
  }
};

// Laden eines einzelnen Tests anhand der ID
export const loadTestById = async (id) => {
  try {
    const docRef = doc(db, 'testDesigns', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log('Kein Dokument gefunden!');
      return null;
    }
  } catch (error) {
    console.error('Fehler beim Laden des Tests:', error);
    throw error;
  }
};

// Laden des "live" Tests aus der Datenbank
export const loadLiveTestFromDB = async () => {
  try {
    const testsRef = collection(db, 'testDesigns');
    const q = query(testsRef, where('status', '==', 'live'));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('Kein "live" Test gefunden.');
      return null;
    }

    // Es wird angenommen, dass es nur einen 'live' Test gibt
    const liveTestDoc = querySnapshot.docs[0];
    return { id: liveTestDoc.id, ...liveTestDoc.data() };
  } catch (error) {
    console.error('Fehler beim Laden des "live" Tests aus der Datenbank:', error);
    throw error;
  }
};

// Laden des "testing" Tests aus der Datenbank
export const loadTestingTestFromDB = async () => {
  try {
    const testsRef = collection(db, 'testDesigns');
    const q = query(testsRef, where('status', '==', 'testing'));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('Kein "testing" Test gefunden.');
      return null;
    }

    // Es wird angenommen, dass es nur einen 'testing' Test gibt
    const testingTestDoc = querySnapshot.docs[0];
    return { id: testingTestDoc.id, ...testingTestDoc.data() };
  } catch (error) {
    console.error('Fehler beim Laden des "testing" Tests aus der Datenbank:', error);
    throw error;
  }
};

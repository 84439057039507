// src/components/pages/Community/UserDashboard_components/NewsFeed.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { db } from '../../../../config/firebase';
import Post from './post';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Chip,
  Stack,
  Typography,
  Snackbar,
  Alert,
  Skeleton,
} from '@mui/material';
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
} from 'firebase/firestore';
import CreatePost from './CreatePost';
import { styled } from '@mui/material/styles';

const NewsFeedBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px', // 20px Abstand zu den Sidebars
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px', // Angepasster Abstand für kleinere Bildschirme
  },
}));

// Skeleton placeholder für das Laden
const PostSkeleton = () => (
  <Box sx={{ mb: 2 }}>
    <Skeleton
      variant="rectangular"
      height={150}
      sx={{ mb: 1, borderRadius: '20px' }}
    />
    <Skeleton variant="text" height={20} width="80%" />
    <Skeleton variant="text" height={15} width="60%" />
  </Box>
);

const NewsFeed = () => {
  const [posts, setPosts] = useState([]);
  const lastPostRef = useRef(null); // useRef zum Speichern des letzten Posts
  const [hasMore, setHasMore] = useState(true);
  const [mainTags, setMainTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null); // Einzelner ausgewählter Tag
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // Funktion zum Abrufen der Haupt-Tags aus Firestore
  const fetchMainTags = useCallback(async () => {
    try {
      const tagsSnapshot = await getDocs(collection(db, 'mainTags'));
      const tags = tagsSnapshot.docs.map((doc) => ({
        label: doc.data().name,
        id: doc.id,
      }));
      setMainTags(tags);
    } catch (error) {
      console.error('Fehler beim Abrufen der Haupt-Tags:', error);
      setSnackbar({
        open: true,
        message: 'Fehler beim Laden der Tags.',
        severity: 'error',
      });
    }
  }, []);

  // Funktion zum Entfernen eines Beitrags aus dem lokalen Zustand
  const removePost = useCallback((postId) => {
    setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  }, []);

  // Abrufen der Posts mit Paginierung und Filterung
  const fetchPosts = useCallback(
    async (reset = false) => {
      if (reset) {
        setIsLoading(true);
        // Entferne das sofortige Löschen der Posts
        lastPostRef.current = null;
        setHasMore(true);
      } else {
        setIsLoadingMore(true);
      }

      try {
        let q = query(
          collection(db, 'posts'),
          orderBy('timestamp', 'desc'),
          limit(10)
        );

        if (selectedTag) {
          const tagLabel = selectedTag.label;
          q = query(
            collection(db, 'posts'),
            where('mainTags', 'array-contains-any', [tagLabel]),
            orderBy('timestamp', 'desc'),
            limit(10)
          );
        }

        if (!reset && lastPostRef.current) {
          q = query(q, startAfter(lastPostRef.current), limit(10));
        }

        const snapshot = await getDocs(q);
        const newPosts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (reset) {
          setPosts(newPosts);
        } else {
          setPosts((prevPosts) => [
            ...prevPosts,
            ...newPosts.filter(
              (post) => !prevPosts.some((p) => p.id === post.id)
            ),
          ]);
        }

        if (newPosts.length < 10) setHasMore(false);
        lastPostRef.current = snapshot.docs[snapshot.docs.length - 1];
      } catch (error) {
        console.error('Fehler beim Laden der Beiträge:', error);
        setSnackbar({
          open: true,
          message: 'Fehler beim Laden der Beiträge.',
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    },
    [selectedTag]
  );

  // Initiales Abrufen der Tags und Posts
  useEffect(() => {
    const initialize = async () => {
      await fetchMainTags();
      await fetchPosts(true);
    };
    initialize();
  }, [fetchMainTags, fetchPosts]);

  // Handle Tag-Filter-Änderungen
  const handleTagClick = (tag) => {
    setSelectedTag((prevTag) =>
      prevTag && prevTag.id === tag.id ? null : tag
    );
    fetchPosts(true);
  };

  // Snackbar schließen
  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      <NewsFeedBox>
        {/* Filter-Chips */}
        <Box
          sx={{
            mb: 2,
            //position: 'sticky',
            top: 0,
            zIndex: 1,
            //backgroundColor: '#252833', // Dunkler Hintergrund für den Filterbereich
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: '10px', // Abgerundete Ecken für den Filterbereich
          }}
        >
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {mainTags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.label}
                clickable
                onClick={() => handleTagClick(tag)}
                sx={{
                  mb: 1,
                  backgroundColor:
                    selectedTag && selectedTag.id === tag.id
                      ? '#d5bc8b'
                      : '#3f4b54',
                  color:
                    selectedTag && selectedTag.id === tag.id ? '#000' : '#fff',
                  '&:hover': {
                    backgroundColor:
                      selectedTag && selectedTag.id === tag.id
                        ? '#d5bc8b'
                        : '#3f4b54',
                  },
                }}
              />
            ))}
            {selectedTag && (
              <Chip
                label="Filter löschen"
                onClick={() => {
                  setSelectedTag(null);
                  fetchPosts(true);
                }}
                color="secondary"
                variant="outlined"
                sx={{ mb: 1, cursor: 'pointer' }}
              />
            )}
          </Stack>
        </Box>

        {/* CreatePost Komponente */}
        <CreatePost
          onPostCreated={() => {
            fetchPosts(true);
          }}
        />

        {/* InfiniteScroll und Laden */}
        <InfiniteScroll
          dataLength={posts.length}
          next={() => fetchPosts(false)}
          hasMore={hasMore}
          loader={
            isLoadingMore ? (
              <>
                <PostSkeleton />
                <PostSkeleton />
                <PostSkeleton />
              </>
            ) : null
          }
          endMessage={
            <Typography
              variant="body2"
              align="center"
              sx={{ mt: 2, color: '#fff' }}
            >
              <b>Keine weiteren Beiträge</b>
            </Typography>
          }
          // Setze den scrollableTarget auf den übergeordneten Container
          scrollableTarget="dashboardContainer"
          // Entferne die scrollbar für InfiniteScroll selbst
          style={{ overflow: 'hidden' }}
        >
          {isLoading && posts.length === 0 ? (
            <>
              <PostSkeleton />
              <PostSkeleton />
              <PostSkeleton />
            </>
          ) : (
            posts.map((post) => (
              <Post key={post.id} post={post} onDelete={removePost} />
            ))
          )}
        </InfiniteScroll>

        {/* Snackbar für Fehlermeldungen und Benachrichtigungen */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: '100%', backgroundColor: '#252833', color: '#fff' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </NewsFeedBox>
    </Box>
  );
};

export default NewsFeed;

// src/components/Administration/TestEditor/QuestionModal.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { FaHorse } from 'react-icons/fa'; // Beispiel für ein passendes Icon

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 40, 51, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: ${colors.background};
  padding: 20px;
  border-radius: 10px;
  width: 800px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  color: ${colors.gold};
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const HorseIconStyled = styled(FaHorse)`
  margin-right: 10px;
  color: ${colors.gold};
  font-size: 24px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${colors.gold};
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: ${colors.darkGold};
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: ${colors.gold};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
  border: 1px solid ${colors.gold};
  border-radius: 6px;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: ${colors.darkGold};
    box-shadow: 0 0 5px ${colors.darkGold};
  }
`;

const NumberInput = styled.input`
  width: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
  border: 1px solid ${colors.gold};
  border-radius: 6px;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: ${colors.darkGold};
    box-shadow: 0 0 5px ${colors.darkGold};
  }
`;

const AnswersSection = styled.div`
  margin-bottom: 25px;
`;

const AnswerHeader = styled.h3`
  color: ${colors.gold};
  margin-bottom: 15px;
`;

const AnswerItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

const AnswerInput = styled.input`
  flex: 2;
  padding: 8px 10px;
  border: 1px solid ${colors.gold};
  border-radius: 6px;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: ${colors.darkGold};
    box-shadow: 0 0 5px ${colors.darkGold};
  }
`;

const AnswerSelect = styled.select`
  flex: 1;
  padding: 8px 10px;
  border: 1px solid ${colors.gold};
  border-radius: 6px;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: ${colors.darkGold};
    box-shadow: 0 0 5px ${colors.darkGold};
  }
`;

const RemoveButton = styled.button`
  padding: 8px 12px;
  background-color: ${colors.red};
  color: ${colors.white};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${colors.darkRed};
    color: ${colors.gold};
  }

  &:active {
    transform: scale(0.98);
  }
`;

const AddAnswerButton = styled.button`
  padding: 10px 16px;
  background-color: ${colors.gold};
  color: ${colors.background};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${colors.darkGold};
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 25px;
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  background-color: ${colors.gold};
  color: ${colors.background};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${colors.darkGold};
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const CancelButton = styled.button`
  padding: 10px 20px;
  background-color: ${colors.grey};
  color: ${colors.white};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${colors.darkGrey};
    color: ${colors.gold};
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CheckboxLabel = styled.label`
  margin-left: 10px;
  color: ${colors.gold};
  font-weight: bold;
`;

const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  accent-color: ${colors.gold};
`;

const QuestionModal = ({ isOpen, onClose, onSave, question, questionId, elements }) => {
  const [text, setText] = useState('');
  const [answers, setAnswers] = useState([]);
  const [maxSelectableAnswers, setMaxSelectableAnswers] = useState(1);
  const [isStartNode, setIsStartNode] = useState(false);

  useEffect(() => {
    if (question) {
      setText(question.text || '');
      setAnswers(question.answers || []);
      setMaxSelectableAnswers(question.maxSelectableAnswers || 1);
      setIsStartNode(question.isStartNode || false);
    }
  }, [question]);

  const addAnswer = () => {
    setAnswers([...answers, { text: '', next: '' }]);
  };

  const handleAnswerChange = (index, field, value) => {
    const updatedAnswers = answers.map((ans, i) =>
      i === index ? { ...ans, [field]: value } : ans
    );
    setAnswers(updatedAnswers);
  };

  const handleRemoveAnswer = (index) => {
    const updatedAnswers = answers.filter((_, i) => i !== index);
    setAnswers(updatedAnswers);
  };

  const handleSave = () => {
    // Validierung: Frage darf nicht leer sein, maxSelectableAnswers muss positiv sein,
    // und jede Antwort muss einen Text haben
    if (text.trim() === '') {
      alert('Fragentext darf nicht leer sein.');
      return;
    }
    if (maxSelectableAnswers < 1) {
      alert('Die maximale Anzahl an auswählbaren Antworten muss mindestens 1 sein.');
      return;
    }
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].text.trim() === '') {
        alert(`Antwort ${i + 1} darf nicht leer sein.`);
        return;
      }
    }
    onSave({ text, answers, maxSelectableAnswers, isStartNode });
    onClose();
  };

  if (!isOpen) return null;

  // Extrahiere mögliche nächste Knoten-IDs und deren Labels für das Dropdown
  const availableNextNodes = elements.nodes
    .filter((el) => el.type === 'questionNode' && el.id !== questionId)
    .map((el) => ({ id: el.id, label: el.data.label }));

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>
            <HorseIconStyled />
            Frage bearbeiten
          </ModalTitle>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>

        <FormGroup>
          <Label>Fragentext:</Label>
          <Input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Gib die Frage ein"
          />
        </FormGroup>

        <FormGroup>
          <Label>Maximale Anzahl an auswählbaren Antworten:</Label>
          <NumberInput
            type="number"
            min="1"
            value={maxSelectableAnswers}
            onChange={(e) =>
              setMaxSelectableAnswers(parseInt(e.target.value, 10) || 1)
            }
            placeholder="1"
          />
        </FormGroup>

        {/* Checkbox für Startknoten */}
        <CheckboxContainer>
          <CheckboxInput
            type="checkbox"
            checked={isStartNode}
            onChange={(e) => setIsStartNode(e.target.checked)}
          />
          <CheckboxLabel>Als Startknoten festlegen</CheckboxLabel>
        </CheckboxContainer>

        <AnswersSection>
          <AnswerHeader>Antwortmöglichkeiten</AnswerHeader>
          {answers.map((answer, index) => (
            <AnswerItem key={index}>
              <AnswerInput
                type="text"
                value={answer.text}
                onChange={(e) =>
                  handleAnswerChange(index, 'text', e.target.value)
                }
                placeholder={`Antwort ${index + 1}`}
              />
              <AnswerSelect
                value={answer.next}
                onChange={(e) =>
                  handleAnswerChange(index, 'next', e.target.value)
                }
              >
                <option value="">-- Nächste Frage --</option>
                {availableNextNodes.map((node) => (
                  <option key={node.id} value={node.id}>
                    {node.label}
                  </option>
                ))}
                <option value="end">Ende</option>
              </AnswerSelect>
              <RemoveButton onClick={() => handleRemoveAnswer(index)}>
                Entfernen
              </RemoveButton>
            </AnswerItem>
          ))}
          <AddAnswerButton onClick={addAnswer}>
            + Antwort hinzufügen
          </AddAnswerButton>
        </AnswersSection>
        <ModalActions>
          <SaveButton onClick={handleSave}>Speichern</SaveButton>
          <CancelButton onClick={onClose}>Abbrechen</CancelButton>
        </ModalActions>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default QuestionModal;

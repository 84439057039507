// src/components/Administration/VideoManagement/VideoManagement.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListIcon from '@mui/icons-material/List';
import SchoolIcon from '@mui/icons-material/School';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import WorkIcon from '@mui/icons-material/Work';
import { db, storage } from '../../../config/firebase';
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  doc,
  writeBatch,
  updateDoc,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import imageCompression from 'browser-image-compression';
import AddVideoModal from './AddVideoModal';
import AddChapterModal from './AddChapterModal';
import AddThemeModal from './AddThemeModal';

// Platzhalterbilder für Themenbereiche, Kapitel und Videos ohne Bild (4:3 Verhältnis)
const PLACEHOLDER_THEMENBEREICH = 'https://via.placeholder.com/400x300';
const PLACEHOLDER_KAPITEL = 'https://via.placeholder.com/400x300';
const PLACEHOLDER_THUMBNAIL = 'https://via.placeholder.com/160x120';

// Statusoptionen mit den gewünschten Farben
const STATUS_OPTIONS = [
  { value: 'Entwurf', color: '#007BFF' },
  { value: 'veröffentlicht', color: '#28a745' },
  { value: 'inaktiv', color: '#6c757d' },
];

// Kapiteltypen mit zugehörigen Icons
const KAPITEL_TYPEN_WITH_ICONS = {
  'unsortierte Liste': <ListIcon />,
  Trainingsreihe: <SchoolIcon />,
  Serie: <OndemandVideoIcon />,
  Praxistransfer: <WorkIcon />,
};

// Funktion zur Sortierung von Objekten nach dem 'order'-Feld
const sortByOrder = (a, b) => a.order - b.order;

// Hilfsfunktion zur Normalisierung von Strings (für Schlüssel)
const normalizeString = (str) => str?.trim().toLowerCase() || 'unbekannt';

const VideoManagement = () => {
  // State-Variablen
  const [themenbereiche, setThemenbereiche] = useState([]);
  const [kapitelList, setKapitelList] = useState([]);
  const [videosByThemaAndKapitel, setVideosByThemaAndKapitel] = useState({});
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loadingThemenbereiche, setLoadingThemenbereiche] = useState(false);
  const [loadingKapitel, setLoadingKapitel] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [errorThemenbereiche, setErrorThemenbereiche] = useState(null);
  const [errorKapitel, setErrorKapitel] = useState(null);
  const [errorVideos, setErrorVideos] = useState(null);

  // State für Modals
  const [openAddVideoModal, setOpenAddVideoModal] = useState(false);
  const [selectedThemenbereich, setSelectedThemenbereich] = useState(null);
  const [selectedKapitel, setSelectedKapitel] = useState(null);

  const [openAddChapterModal, setOpenAddChapterModal] = useState(false);
  const [selectedThemenbereichForChapter, setSelectedThemenbereichForChapter] =
    useState(null);

  const [openAddThemeModal, setOpenAddThemeModal] = useState(false);

  // Funktion zum Abrufen der Themenbereiche
  const fetchThemenbereiche = async () => {
    setLoadingThemenbereiche(true);
    setErrorThemenbereiche(null);
    try {
      const themenRef = collection(db, 'settings', 'themenbereiche', 'list');
      const themenSnapshot = await getDocs(themenRef);
      const themenList = themenSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          name: doc.data()?.name?.trim(),
          order:
            typeof doc.data()?.order === 'number'
              ? doc.data().order
              : undefined,
          imageUrl: doc.data()?.imageUrl || undefined,
        }))
        .sort(sortByOrder);
      setThemenbereiche(themenList);
      console.log('Themenbereiche erfolgreich abgerufen:', themenList);
    } catch (error) {
      console.error('Fehler beim Abrufen der Themenbereiche:', error);
      setErrorThemenbereiche('Fehler beim Laden der Themenbereiche.');
    } finally {
      setLoadingThemenbereiche(false);
    }
  };

  // Funktion zum Abrufen der Kapitel
  const fetchKapitel = async () => {
    setLoadingKapitel(true);
    setErrorKapitel(null);
    try {
      const kapitelRef = collection(db, 'settings', 'kapitel', 'list');
      const kapitelSnapshot = await getDocs(kapitelRef);
      const kapitelListFetched = kapitelSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          name: doc.data()?.name?.trim(),
          order:
            typeof doc.data()?.order === 'number'
              ? doc.data().order
              : undefined,
          themenbereich: doc.data()?.themenbereich?.trim(),
          imageUrl: doc.data()?.imageUrl || undefined,
          typ: doc.data()?.typ?.trim(),
        }))
        .sort(sortByOrder);
      setKapitelList(kapitelListFetched);
      console.log('Kapitel erfolgreich abgerufen:', kapitelListFetched);
    } catch (error) {
      console.error('Fehler beim Abrufen der Kapitel:', error);
      setErrorKapitel('Fehler beim Laden der Kapitel.');
    } finally {
      setLoadingKapitel(false);
    }
  };

  // Funktion zum Abrufen der Videos
  const fetchVideos = async () => {
    setLoadingVideos(true);
    setErrorVideos(null);
    try {
      const videosRef = collection(db, 'videos');
      const videosSnapshot = await getDocs(videosRef);
      const groupedVideos = {};

      videosSnapshot.forEach((doc) => {
        const videoData = doc.data();
        const themenbereichKey = normalizeString(videoData?.themenbereich);
        const kapitelKey = normalizeString(videoData?.kapitel);
        const order =
          typeof videoData?.order === 'number' ? videoData.order : undefined;
        const status = videoData?.status || undefined;

        if (!groupedVideos[themenbereichKey]) {
          groupedVideos[themenbereichKey] = {};
        }

        if (!groupedVideos[themenbereichKey][kapitelKey]) {
          groupedVideos[themenbereichKey][kapitelKey] = {
            kapitelName: videoData?.kapitel,
            videos: [],
          };
        }

        groupedVideos[themenbereichKey][kapitelKey].videos.push({
          id: doc.id,
          video_titel: videoData?.video_titel,
          order: order,
          thumbnailUrl: videoData?.thumbnailUrl || PLACEHOLDER_THUMBNAIL,
          status: status,
        });
      });

      // Sortieren der Videos innerhalb jedes Kapitels
      for (const thema in groupedVideos) {
        for (const kapitel in groupedVideos[thema]) {
          groupedVideos[thema][kapitel].videos.sort(sortByOrder);
        }
      }

      setVideosByThemaAndKapitel(groupedVideos);
      console.log('Videos erfolgreich abgerufen und gruppiert:', groupedVideos);
    } catch (error) {
      console.error('Fehler beim Abrufen der Videos:', error);
      setErrorVideos('Fehler beim Laden der Videos.');
    } finally {
      setLoadingVideos(false);
    }
  };

  // useEffect zum Abrufen der Daten beim Mounten der Komponente
  useEffect(() => {
    console.log('VideoManagement: useEffect [] gestartet');
    fetchThemenbereiche();
    fetchKapitel();
    fetchVideos();
  }, []);

  // Handler für Typänderung eines Kapitels
  const handleKapitelTypChange = (kapitelId) => async (e) => {
    const newTyp = e.target.value;
    console.log(
      `handleKapitelTypChange: kapitelId="${kapitelId}", newTyp="${newTyp}"`
    );

    // Überprüfen, ob kapitelId gültig ist
    if (!kapitelId) {
      console.error('Kapitel-ID fehlt.');
      setMessage('Kapitel-ID fehlt.');
      setSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const kapitelDocRef = doc(db, 'settings', 'kapitel', 'list', kapitelId);
      // Verwende setDoc mit merge: true, um das Dokument zu aktualisieren
      await setDoc(kapitelDocRef, { typ: newTyp }, { merge: true });

      // Lokalen State aktualisieren
      setKapitelList((prevList) =>
        prevList.map((kapitel) =>
          kapitel.id === kapitelId ? { ...kapitel, typ: newTyp } : kapitel
        )
      );

      setMessage(`Kapiteltyp erfolgreich auf "${newTyp}" gesetzt.`);
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Kapitel-Typs:', error);
      setMessage('Fehler beim Aktualisieren des Kapitel-Typs.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Funktion zum Hochladen und Aktualisieren des Bildes
  const handleImageUpload = async (type, id, file) => {
    try {
      console.log(
        `handleImageUpload: type="${type}", id="${id}", file="${file.name}"`
      );
      // Bildkomprimierung
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      });

      // Speicherort definieren
      const imageRef = ref(storage, `${type}/${id}/image.jpg`);

      // Bild hochladen
      await uploadBytes(imageRef, compressedFile);

      // Download-URL erhalten
      const downloadURL = await getDownloadURL(imageRef);
      console.log(`Bild hochgeladen und Download-URL erhalten: ${downloadURL}`);

      // Firestore-Dokument aktualisieren
      const docRef = doc(db, 'settings', type, 'list', id);
      await setDoc(docRef, { imageUrl: downloadURL }, { merge: true });

      // Lokalen State aktualisieren
      if (type === 'themenbereiche') {
        setThemenbereiche((prev) =>
          prev.map((tb) =>
            tb.id === id ? { ...tb, imageUrl: downloadURL } : tb
          )
        );
      } else if (type === 'kapitel') {
        setKapitelList((prev) =>
          prev.map((kap) =>
            kap.id === id ? { ...kap, imageUrl: downloadURL } : kap
          )
        );
      }

      setMessage(`Bild erfolgreich für ${type} aktualisiert.`);
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Hochladen des Bildes:', error);
      setMessage('Fehler beim Hochladen des Bildes.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Handler für das Schließen des Snackbars
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  // Handler für das Beenden des Drag-and-Drop
  const handleDragEnd = async (result) => {
    const { source, destination, type } = result;
    console.log('handleDragEnd: result=', result);

    // Abbrechen, wenn kein Ziel angegeben ist
    if (!destination) return;

    // Abbrechen, wenn die Position unverändert ist
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Unterscheidung nach Typ (Kapitel oder Video)
    if (type === 'kapitel') {
      // Anpassungen für Kapitel-DnD (falls benötigt)
    } else if (type === 'video') {
      const [themenbereichKey, kapitelKey] = source.droppableId
        .split('||')
        .map(normalizeString);
      console.log(
        'handleDragEnd: Typ "video", themenbereichKey=',
        themenbereichKey,
        ', kapitelKey=',
        kapitelKey
      );

      // Überprüfen, ob Themenbereich und Kapitel existieren
      if (
        videosByThemaAndKapitel[themenbereichKey] &&
        videosByThemaAndKapitel[themenbereichKey][kapitelKey]
      ) {
        const currentVideos = Array.from(
          videosByThemaAndKapitel[themenbereichKey][kapitelKey].videos
        );
        console.log(
          'Videos vor Verschiebung:',
          currentVideos.map((v) => v.video_titel)
        );

        const [movedVideo] = currentVideos.splice(source.index, 1);
        currentVideos.splice(destination.index, 0, movedVideo);

        console.log(
          'Videos nach Verschiebung:',
          currentVideos.map((v) => v.video_titel)
        );

        // Aktualisieren des lokalen States
        setVideosByThemaAndKapitel((prevState) => ({
          ...prevState,
          [themenbereichKey]: {
            ...prevState[themenbereichKey],
            [kapitelKey]: {
              ...prevState[themenbereichKey][kapitelKey],
              videos: currentVideos,
            },
          },
        }));

        // Aktualisieren der Reihenfolge in Firestore mit Batch-Updates
        try {
          const batch = writeBatch(db);

          currentVideos.forEach((video, index) => {
            const videoDocRef = doc(db, 'videos', video.id);
            batch.update(videoDocRef, { order: index + 1 });
          });

          await batch.commit();

          setMessage(
            `Reihenfolge der Videos in Kapitel "${
              videosByThemaAndKapitel[themenbereichKey][kapitelKey]
                ?.kapitelName || 'Unbekannt'
            }" aktualisiert.`
          );
          setSeverity('success');
          setOpenSnackbar(true);
        } catch (error) {
          console.error(
            'Fehler beim Aktualisieren der Video-Reihenfolge:',
            error
          );
          setMessage('Fehler beim Aktualisieren der Video-Reihenfolge.');
          setSeverity('error');
          setOpenSnackbar(true);
        }
      } else {
        console.warn(
          'Themenbereich oder Kapitel nicht gefunden beim Verschieben von Videos.'
        );
      }
    }
  };

  // Funktion, um Kapitel mit Videos für einen Themenbereich zu erhalten
  const getKapitelWithVideos = (themenbereich) => {
    const themenbereichKey = normalizeString(themenbereich);
    const kapitelObj = videosByThemaAndKapitel[themenbereichKey] || {};
    const kapitelEntries = Object.entries(kapitelObj);

    // Zusätzliche Infos aus kapitelList hinzufügen
    const kapitelMitVideos = kapitelEntries.map(([kapitelKey, kapitelData]) => {
      const kapitelInfo = kapitelList.find(
        (k) =>
          normalizeString(k.name) === kapitelKey &&
          normalizeString(k.themenbereich) === themenbereichKey
      );

      // Sicherstellen, dass kapitelInfo gefunden wurde
      if (!kapitelInfo) {
        console.warn(
          `Kapitel "${kapitelKey}" in Themenbereich "${themenbereich}" existiert nicht in der Kapitelliste.`
        );
        return {
          name: kapitelData.kapitelName || undefined,
          imageUrl: undefined, // Keine Bild-URL vorhanden
          id: kapitelKey, // Verwenden des kapitelKey als ID, wenn kapitelInfo nicht gefunden wurde
          typ: undefined, // Kein Typ vorhanden
          videoCount: kapitelData.videos?.length || 0,
          key: kapitelKey,
        };
      }

      return {
        name: kapitelData.kapitelName || kapitelInfo.name || undefined,
        imageUrl: kapitelInfo.imageUrl || undefined,
        id: kapitelInfo.id, // Sicherstellen, dass die korrekte Firestore-Dokument-ID verwendet wird
        typ: kapitelInfo.typ || undefined,
        videoCount: kapitelData.videos?.length || 0,
        key: kapitelKey,
      };
    });

    return kapitelMitVideos.sort((a, b) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });
  };

  // Funktion, um die Anzahl der Kapitel für einen Themenbereich zu erhalten
  const getKapitelCountForThemenbereich = (themenbereich) => {
    const themenbereichKey = normalizeString(themenbereich);
    const kapitelObj = videosByThemaAndKapitel[themenbereichKey] || {};
    return Object.keys(kapitelObj).length;
  };

  // Handler für das Ändern des Status eines Videos
  const handleStatusChange = (themenbereich, kapitel, videoId) => async (e) => {
    const newStatus = e.target.value;
    console.log(
      `handleStatusChange: Themenbereich="${themenbereich}", Kapitel="${kapitel}", videoId="${videoId}", newStatus="${newStatus}"`
    );
    try {
      const videoDocRef = doc(db, 'videos', videoId);
      await updateDoc(videoDocRef, { status: newStatus });

      // Aktualisieren des lokalen States
      const themenbereichKey = normalizeString(themenbereich);
      const kapitelKey = normalizeString(kapitel);

      setVideosByThemaAndKapitel((prevState) => {
        if (
          !prevState[themenbereichKey] ||
          !prevState[themenbereichKey][kapitelKey]
        ) {
          console.warn(
            'Themenbereich oder Kapitel existiert nicht im lokalen State.'
          );
          return prevState;
        }

        const updatedVideos = prevState[themenbereichKey][
          kapitelKey
        ].videos.map((video) =>
          video.id === videoId ? { ...video, status: newStatus } : video
        );
        return {
          ...prevState,
          [themenbereichKey]: {
            ...prevState[themenbereichKey],
            [kapitelKey]: {
              ...prevState[themenbereichKey][kapitelKey],
              videos: updatedVideos,
            },
          },
        };
      });

      setMessage(`Status des Videos erfolgreich auf "${newStatus}" gesetzt.`);
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Video-Status:', error);
      setMessage('Fehler beim Aktualisieren des Video-Status.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Handler zum Öffnen des AddVideoModals
  const handleOpenAddVideoModal = (themenbereich, kapitel) => {
    setSelectedThemenbereich(themenbereich);
    setSelectedKapitel(kapitel);
    setOpenAddVideoModal(true);
  };

  // Handler zum Schließen des AddVideoModals
  const handleCloseAddVideoModal = () => {
    setOpenAddVideoModal(false);
  };

  // Handler zum Öffnen des AddChapterModals
  const handleOpenAddChapterModal = (themenbereich) => {
    setSelectedThemenbereichForChapter(themenbereich);
    setOpenAddChapterModal(true);
  };

  // Handler zum Schließen des AddChapterModals
  const handleCloseAddChapterModal = () => {
    setOpenAddChapterModal(false);
  };

  // Handler zum Öffnen des AddThemeModals
  const handleOpenAddThemeModal = () => {
    setOpenAddThemeModal(true);
  };

  // Handler zum Schließen des AddThemeModals
  const handleCloseAddThemeModal = () => {
    setOpenAddThemeModal(false);
  };

  // Handler für das Hinzufügen eines neuen Videos
  const handleAddVideo = async (data) => {
    try {
      const {
        video_titel,
        status,
        themenbereiche,
        text,
        vimeoId,
        thumbnailUrl,
        abschnitte,
        // Weitere Felder
        // ...
      } = data;

      const themenbereich = selectedThemenbereich;
      const kapitel = selectedKapitel;

      if (!themenbereich || !kapitel) {
        setMessage('Themenbereich und Kapitel müssen ausgewählt sein.');
        setSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      const themenbereichKey = normalizeString(themenbereich);
      const kapitelKey = normalizeString(kapitel);

      const existingVideos =
        videosByThemaAndKapitel[themenbereichKey]?.[kapitelKey]?.videos || [];
      const nextOrder =
        existingVideos.length > 0
          ? Math.max(...existingVideos.map((v) => v.order || 0)) + 1
          : 1;

      // Neues Video hinzufügen
      const videosRef = collection(db, 'videos');
      await addDoc(videosRef, {
        video_titel: video_titel?.trim(),
        themenbereich: themenbereich,
        kapitel: kapitel,
        order: nextOrder,
        thumbnailUrl: thumbnailUrl || undefined,
        status: status || undefined,
        themenbereiche: themenbereiche,
        text: text,
        vimeoId: vimeoId,
        abschnitte: abschnitte,
        // Weitere Felder hinzufügen
        // ...
      });

      setMessage(
        `Video erfolgreich zu "${themenbereich}" und Kapitel "${kapitel}" hinzugefügt.`
      );
      setSeverity('success');
      setOpenSnackbar(true);

      // Aktualisiere die Videosliste
      await fetchVideos();

      // Modal schließen
      handleCloseAddVideoModal();
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Videos:', error);
      setMessage(error.message || 'Fehler beim Hinzufügen des Videos.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Handler für das Hinzufügen eines neuen Kapitels
  const handleAddChapter = async (data) => {
    try {
      const { name, typ, imageFile } = data;
      const themenbereich = selectedThemenbereichForChapter;

      if (!themenbereich) {
        setMessage('Themenbereich muss ausgewählt sein.');
        setSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      let imageUrl = undefined;
      if (imageFile) {
        // Bildkomprimierung
        const compressedFile = await imageCompression(imageFile, {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        });

        // Speicherort definieren
        const imageRef = ref(
          storage,
          `kapitel/${Date.now()}_${imageFile.name}`
        );
        // Bild hochladen
        await uploadBytes(imageRef, compressedFile);
        // Download-URL erhalten
        imageUrl = await getDownloadURL(imageRef);
      }

      // Neues Kapitel hinzufügen
      const kapitelRef = collection(db, 'settings', 'kapitel', 'list');
      await addDoc(kapitelRef, {
        name: name?.trim(),
        typ: typ?.trim(),
        imageUrl: imageUrl,
        themenbereich: themenbereich,
        order: kapitelList.length + 1,
      });

      setMessage(
        `Kapitel "${name}" erfolgreich zu "${themenbereich}" hinzugefügt.`
      );
      setSeverity('success');
      setOpenSnackbar(true);

      // Aktualisiere die Kapitelliste
      await fetchKapitel();

      // Modal schließen
      handleCloseAddChapterModal();
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Kapitels:', error);
      setMessage(error.message || 'Fehler beim Hinzufügen des Kapitels.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Handler für das Hinzufügen eines neuen Themenbereichs
  const handleAddTheme = async (data) => {
    try {
      const { name, imageFile } = data;

      let imageUrl = undefined;
      if (imageFile) {
        // Bildkomprimierung
        const compressedFile = await imageCompression(imageFile, {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        });

        // Speicherort definieren
        const imageRef = ref(
          storage,
          `themenbereiche/${Date.now()}_${imageFile.name}`
        );
        // Bild hochladen
        await uploadBytes(imageRef, compressedFile);
        // Download-URL erhalten
        imageUrl = await getDownloadURL(imageRef);
      }

      // Neuen Themenbereich hinzufügen
      const themenRef = collection(db, 'settings', 'themenbereiche', 'list');
      await addDoc(themenRef, {
        name: name?.trim(),
        imageUrl: imageUrl,
        order: themenbereiche.length + 1,
      });

      setMessage(`Themenbereich "${name}" erfolgreich hinzugefügt.`);
      setSeverity('success');
      setOpenSnackbar(true);

      // Aktualisiere die Themenbereichsliste
      await fetchThemenbereiche();

      // Modal schließen
      handleCloseAddThemeModal();
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Themenbereichs:', error);
      setMessage(error.message || 'Fehler beim Hinzufügen des Themenbereichs.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Rendern der Komponenten
  return (
    <Box sx={{ p: 4, backgroundColor: '#f0ebe1', minHeight: '100vh' }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ mb: 4, color: '#333', textAlign: 'center' }}
      >
        Video Management
      </Typography>

      {(loadingThemenbereiche || loadingKapitel || loadingVideos) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {errorThemenbereiche && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorThemenbereiche}
        </Typography>
      )}
      {errorKapitel && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorKapitel}
        </Typography>
      )}
      {errorVideos && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorVideos}
        </Typography>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        {themenbereiche.map((themenbereich, index) => {
          console.log(`Rendering Themenbereich: ${themenbereich.name}`);
          const kapitelMitVideos = getKapitelWithVideos(themenbereich.name);

          // Wenn keine Kapitel mit Videos vorhanden sind, überspringen
          if (kapitelMitVideos.length === 0) {
            console.log(
              `Keine Kapitel mit Videos für Themenbereich "${themenbereich.name}" gefunden.`
            );
            return null;
          }

          const kapitelCount = getKapitelCountForThemenbereich(
            themenbereich.name
          );

          return (
            <Box key={themenbereich.id} sx={{ mb: 3 }}>
              <Accordion
                sx={{
                  boxShadow: 2,
                  borderRadius: '8px',
                  backgroundColor: '#e8e4d9',
                  transition: 'box-shadow 0.3s ease, border 0.3s ease',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-content-${index}`}
                  id={`panel-header-${index}`}
                  sx={{
                    backgroundColor: '#d5bc8b',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    margin: 0,
                    minHeight: '80px', // Fixierte Höhe
                    '& .MuiAccordionSummary-content': {
                      margin: 0,
                      alignItems: 'center',
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                      transform: 'none',
                      marginRight: '8px',
                    },
                  }}
                >
                  {/* Bild für den Themenbereich */}
                  <Box
                    sx={{
                      position: 'relative',
                      flex: '0 0 auto',
                      width: '120px',
                      height: '100%',
                      overflow: 'hidden',
                      borderRadius: '8px 0 0 8px',
                    }}
                  >
                    <Avatar
                      src={themenbereich.imageUrl || PLACEHOLDER_THEMENBEREICH}
                      alt={themenbereich.name || 'Themenbereich ohne Name'}
                      variant="square"
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    {/* Overlay mit Stift-Symbol beim Hover */}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        opacity: 0,
                        transition: 'opacity 0.3s ease',
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 1,
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        const fileInput = document.createElement('input');
                        fileInput.type = 'file';
                        fileInput.accept = 'image/*';
                        fileInput.onchange = (event) => {
                          if (event.target.files && event.target.files[0]) {
                            handleImageUpload(
                              'themenbereiche',
                              themenbereich.id,
                              event.target.files[0]
                            );
                          }
                        };
                        fileInput.click();
                      }}
                    >
                      <EditIcon sx={{ color: '#fff' }} />
                    </Box>
                  </Box>
                  {/* Themenbereich Titel und Kapitelanzahl */}
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      pl: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: '#252833',
                        textAlign: 'left',
                        flexGrow: 1,
                      }}
                    >
                      {themenbereich.name || 'Unbenannter Themenbereich'}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#555',
                        textAlign: 'center',
                        marginRight: '8px',
                      }}
                    >
                      {kapitelCount} Kapitel
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  {/* Liste der Kapitel */}
                  {kapitelMitVideos.map((kapitel, kapitelIndex) => {
                    console.log(`Rendering Kapitel: ${kapitel.name}`);
                    const themenbereichKey = normalizeString(
                      themenbereich.name
                    );
                    const kapitelKey = kapitel.key;
                    const videos =
                      videosByThemaAndKapitel[themenbereichKey]?.[kapitelKey]
                        ?.videos || [];

                    return (
                      <Box
                        key={`${themenbereich.id}-${kapitel.id}`}
                        sx={{ ml: 4, mt: kapitelIndex === 0 ? 2 : 0 }}
                      >
                        <Card
                          sx={{
                            mb: 2,
                            boxShadow: 1,
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            transition:
                              'box-shadow 0.2s ease, border 0.2s ease',
                          }}
                        >
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`kapitel-content-${kapitel.id}`}
                              id={`kapitel-header-${kapitel.id}`}
                              sx={{
                                padding: 0,
                                margin: 0,
                                minHeight: '80px', // Fixierte Höhe
                                display: 'flex',
                                alignItems: 'center',
                                '& .MuiAccordionSummary-content': {
                                  margin: 0,
                                  alignItems: 'center',
                                },
                                '& .MuiAccordionSummary-expandIconWrapper': {
                                  transform: 'none',
                                  marginRight: '8px',
                                },
                              }}
                            >
                              {/* Bild für das Kapitel */}
                              <Box
                                sx={{
                                  position: 'relative',
                                  flex: '0 0 auto',
                                  width: '120px',
                                  height: '100%',
                                  overflow: 'hidden',
                                  borderRadius: '8px 0 0 8px',
                                }}
                              >
                                <Avatar
                                  src={kapitel.imageUrl || PLACEHOLDER_KAPITEL}
                                  alt={kapitel.name || 'Kapitel ohne Name'}
                                  variant="square"
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                                {/* Overlay mit Stift-Symbol beim Hover */}
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    opacity: 0,
                                    transition: 'opacity 0.3s ease',
                                    cursor: 'pointer',
                                    '&:hover': {
                                      opacity: 1,
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const fileInput =
                                      document.createElement('input');
                                    fileInput.type = 'file';
                                    fileInput.accept = 'image/*';
                                    fileInput.onchange = (event) => {
                                      if (
                                        event.target.files &&
                                        event.target.files[0]
                                      ) {
                                        handleImageUpload(
                                          'kapitel',
                                          kapitel.id,
                                          event.target.files[0]
                                        );
                                      }
                                    };
                                    fileInput.click();
                                  }}
                                >
                                  <EditIcon sx={{ color: '#fff' }} />
                                </Box>
                              </Box>
                              {/* Kapitel Titel, Typ Icon und Videoanzahl */}
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  pl: 2,
                                }}
                              >
                                {/* Kapitel Typ Icon */}
                                {kapitel.typ && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      mr: 1,
                                    }}
                                  >
                                    {KAPITEL_TYPEN_WITH_ICONS[kapitel.typ]}
                                  </Box>
                                )}
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontWeight: 'bold',
                                    color: '#252833',
                                    textAlign: 'left',
                                    flexGrow: 1,
                                  }}
                                >
                                  {kapitel.name || 'Unbenanntes Kapitel'}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: '#555',
                                    textAlign: 'center',
                                    marginRight: '8px',
                                  }}
                                >
                                  {kapitel.videoCount} Videos
                                </Typography>
                              </Box>
                              {/* Kapitel Typ Dropdown */}
                              <Box
                                sx={{
                                  flexShrink: 0,
                                  marginRight: 2,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  sx={{ minWidth: 180 }} // Erhöhte Breite
                                >
                                  <InputLabel
                                    id={`kapitel-typ-label-${kapitel.id}`}
                                  >
                                    Kapiteltyp
                                  </InputLabel>
                                  <Select
                                    labelId={`kapitel-typ-label-${kapitel.id}`}
                                    id={`kapitel-typ-select-${kapitel.id}`}
                                    value={kapitel.typ || ''}
                                    onChange={handleKapitelTypChange(
                                      kapitel.id
                                    )}
                                    label="Kapiteltyp"
                                    renderValue={(selected) => (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {KAPITEL_TYPEN_WITH_ICONS[selected]}
                                        <Typography sx={{ ml: 1 }}>
                                          {selected}
                                        </Typography>
                                      </Box>
                                    )}
                                  >
                                    {Object.keys(KAPITEL_TYPEN_WITH_ICONS).map(
                                      (typ) => (
                                        <MenuItem key={typ} value={typ}>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            {KAPITEL_TYPEN_WITH_ICONS[typ]}
                                            <Typography sx={{ ml: 1 }}>
                                              {typ}
                                            </Typography>
                                          </Box>
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              {/* Liste der Videos */}
                              <Droppable
                                droppableId={`${themenbereichKey}||${kapitelKey}`}
                                type="video"
                              >
                                {(provided) => (
                                  <List
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    sx={{
                                      backgroundColor: '#f0f8ff',
                                      borderRadius: '8px',
                                      padding: '16px',
                                    }}
                                  >
                                    {videos.map((video, vidIndex) => {
                                      console.log(
                                        `Rendering Video: ${video.video_titel}, Thumbnail URL: ${video.thumbnailUrl}`
                                      );
                                      return (
                                        <Draggable
                                          key={video.id}
                                          draggableId={video.id}
                                          index={vidIndex}
                                        >
                                          {(provided, snapshot) => (
                                            <ListItem
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              sx={{
                                                backgroundColor:
                                                  snapshot.isDragging
                                                    ? '#dcdcdc'
                                                    : '#fff',
                                                marginBottom: '8px',
                                                borderRadius: '4px',
                                                boxShadow: snapshot.isDragging
                                                  ? '0 4px 12px rgba(0,0,0,0.1)'
                                                  : 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                transition:
                                                  'background-color 0.3s ease, box-shadow 0.3s ease',
                                              }}
                                            >
                                              {/* Thumbnail als img-Tag */}
                                              <img
                                                src={
                                                  video.thumbnailUrl ||
                                                  PLACEHOLDER_THUMBNAIL
                                                }
                                                alt={
                                                  video.video_titel ||
                                                  'Video ohne Titel'
                                                }
                                                style={{
                                                  width: '50px',
                                                  height: '50px',
                                                  marginRight: '16px',
                                                  objectFit: 'cover',
                                                  borderRadius: '4px',
                                                }}
                                                onError={(e) => {
                                                  e.target.onerror = null; // Verhindert endlose Schleife bei Fehler
                                                  e.target.src =
                                                    PLACEHOLDER_THUMBNAIL;
                                                }}
                                              />
                                              {/* Videotitel */}
                                              <ListItemText
                                                primary={
                                                  video.video_titel ||
                                                  'Unbenanntes Video'
                                                }
                                                sx={{
                                                  fontSize: '16px',
                                                  color: '#333',
                                                  textAlign: 'left',
                                                }}
                                              />
                                              {/* Status Dropdown */}
                                              <Box
                                                sx={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  ml: 'auto',
                                                }}
                                              >
                                                <FormControl
                                                  variant="outlined"
                                                  size="small"
                                                >
                                                  <InputLabel
                                                    id={`status-label-${video.id}`}
                                                  >
                                                    Status
                                                  </InputLabel>
                                                  <Select
                                                    labelId={`status-label-${video.id}`}
                                                    id={`status-select-${video.id}`}
                                                    value={video.status || ''}
                                                    onChange={handleStatusChange(
                                                      themenbereich.name,
                                                      kapitel.name,
                                                      video.id
                                                    )}
                                                    label="Status"
                                                    sx={{ minWidth: 140 }}
                                                  >
                                                    {STATUS_OPTIONS.map(
                                                      (option) => (
                                                        <MenuItem
                                                          key={option.value}
                                                          value={option.value}
                                                        >
                                                          <Box
                                                            sx={{
                                                              display: 'flex',
                                                              alignItems:
                                                                'center',
                                                            }}
                                                          >
                                                            <Box
                                                              sx={{
                                                                width: 12,
                                                                height: 12,
                                                                borderRadius:
                                                                  '50%',
                                                                backgroundColor:
                                                                  option.color,
                                                                mr: 1,
                                                              }}
                                                            />
                                                            <Typography variant="body2">
                                                              {option.value}
                                                            </Typography>
                                                          </Box>
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  </Select>
                                                </FormControl>
                                              </Box>
                                            </ListItem>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </List>
                                )}
                              </Droppable>
                              {/* Symbol zum Hinzufügen eines neuen Videos */}
                              <Box sx={{ textAlign: 'center', mt: 2 }}>
                                <IconButton
                                  onClick={() =>
                                    handleOpenAddVideoModal(
                                      themenbereich.name,
                                      kapitel.name
                                    )
                                  }
                                  sx={{
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                    },
                                    flexDirection: 'column',
                                    color: '#757575',
                                  }}
                                >
                                  <AddCircleIcon sx={{ fontSize: 40 }} />
                                  <Typography variant="caption">
                                    Video hinzufügen
                                  </Typography>
                                </IconButton>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </Card>
                      </Box>
                    );
                  })}
                  {/* Symbol zum Hinzufügen eines neuen Kapitels */}
                  <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <IconButton
                      onClick={() =>
                        handleOpenAddChapterModal(themenbereich.name)
                      }
                      sx={{
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        flexDirection: 'column',
                        color: '#757575',
                      }}
                    >
                      <AddCircleIcon sx={{ fontSize: 50 }} />
                      <Typography variant="caption">
                        Kapitel hinzufügen
                      </Typography>
                    </IconButton>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          ); // Ende des Themenbereichs-Map
        })}

        {/* Symbol zum Hinzufügen eines neuen Themenbereichs */}
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <IconButton
            onClick={handleOpenAddThemeModal}
            sx={{
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              flexDirection: 'column',
              color: '#757575',
            }}
          >
            <AddCircleIcon sx={{ fontSize: 60 }} />
            <Typography variant="caption">Themenbereich hinzufügen</Typography>
          </IconButton>
        </Box>
      </DragDropContext>

      {/* Modals */}
      <AddVideoModal
        open={openAddVideoModal}
        onClose={handleCloseAddVideoModal}
        onSubmit={handleAddVideo}
        themenbereich={selectedThemenbereich}
        kapitel={selectedKapitel}
      />
      <AddChapterModal
        open={openAddChapterModal}
        onClose={handleCloseAddChapterModal}
        onSubmit={handleAddChapter}
        themenbereich={selectedThemenbereichForChapter}
      />
      <AddThemeModal
        open={openAddThemeModal}
        onClose={handleCloseAddThemeModal}
        onSubmit={handleAddTheme}
      />

      {/* Snackbar für Benutzerfeedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VideoManagement;

// src/components/Administration/AdminDashboard.js

import React, { useState } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  VideoLibrary as VideoLibraryIcon,
  Upload as UploadIcon,
  Stream as StreamIcon,
  PlayArrow as PlayArrowIcon,
  Assignment as AssignmentIcon,
  Build as BuildIcon, // Icon für Wartung
  DownloadForOffline as DownloadForOfflineIcon, // Icon für Datenexport
} from '@mui/icons-material';
import { styled } from '@mui/system';

// Importiere die neuen Komponenten als Default Export
import HostArea from '../../Administration/Livestream/HostArea';
// Entferne den Import von MeetingCalendar
// import MeetingCalendar from '../../Administration/Livestream/MeetingCalendar';
// Füge den Import von MeetingManagement hinzu
import MeetingManagement from '../../Administration/Livestream/MeetingManagement';
import UserManagementTable from '../../Administration/UserManagement';
import ZoomAdminPanel from '../../Administration/ZoomAdminPanel';
import VideoManagementTable from '../../Administration/VideoManagement_Table';
import AdminDashboardChart from '../../Administration/AdminDashboard_Chart';
import ProductManagement from '../../Administration/ProductManagement';
import ThumbnailUpdate from '../../Administration/ThumnailUpdate';
import TestEditor from '../../Administration/TestEditor';
import VimeoUpload from '../../Administration/VideoUpload/VideoUploadForm';
import CsvUploader from '../../Administration/VideoUpload/CsvUploader';
import VideoManagement from '../../Administration/VideoManagement/VideoManagement'; // VideoManagement importiert
import UpdateSettings from '../../Administration/UpdateSettings'; // UpdateSettings importiert
import System from '../../Administration/System';
import EmailManager from '../../Administration/EmailManager';

// Erstelle ein einfaches Dashboard als Standardansicht
const DefaultDashboard = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Administrator-Dashboard
      </Typography>
      <AdminDashboardChart />
    </Box>
  );
};

// Definiere die Breite des Drawers
const drawerWidth = 240;

// Styled Container für den Drawer und den Hauptinhalt
const DrawerContainer = styled(Box)({
  display: 'flex',
});

// Styled ListItem zur Leistungsoptimierung und für Animationen
const AnimatedListItem = styled(({ selected, ...other }) => (
  <ListItem {...other} />
))(({ selected }) => ({
  margin: '4px 0',
  transition: '0.3s',
  backgroundColor: selected ? '#d5bc8b' : 'transparent',
  color: selected ? '#fff' : 'inherit',
  '&:hover': {
    backgroundColor: '#3f4b54',
    transform: 'scale(1.05)',
    color: '#fff',
  },
}));

// Hauptkomponente des Admin-Dashboards
const AdminDashboard = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Funktion zum Handhaben von Klicks auf die Menüelemente
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  // Funktion zum Rendern des Inhalts basierend auf dem ausgewählten Menüpunkt
  const renderContent = () => {
    switch (selectedIndex) {
      case 0:
        return <DefaultDashboard />;
      case 1:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Benutzerverwaltung
            </Typography>
            <UserManagementTable />
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Daten exportieren
            </Typography>
            {/* Füge hier den Inhalt für Datenexport hinzu */}
            <Typography>
              Funktion zum Exportieren von Daten ist noch nicht implementiert.
            </Typography>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Livestream Planung
            </Typography>
            <MeetingManagement />{' '}
            {/* Ersetze MeetingCalendar durch MeetingManagement */}
          </>
        );
      case 4:
        return <ZoomAdminPanel />;
      case 5:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              System
            </Typography>
            <System />
          </>
        );
      case 6:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Email Template Manager
            </Typography>
            <EmailManager />
          </>
        );
      case 7:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Benutzerrollen
            </Typography>
            <Typography>Benutzerrollen Inhalt hier</Typography>
          </>
        );
      case 8:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Beiträge
            </Typography>
            <Typography>Beiträge Inhalt hier</Typography>
          </>
        );
      case 9:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Mitarbeiterverwaltung
            </Typography>
            <ProductManagement />
          </>
        );
      case 10:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Emails
            </Typography>
            <Typography>Emails Inhalt hier</Typography>
          </>
        );
      case 11:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Ticketsystem
            </Typography>
            <Typography>Ticketsystem Inhalt hier</Typography>
          </>
        );
      case 12:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Einstellungen
            </Typography>
            <Typography>Einstellungen Inhalt hier</Typography>
          </>
        );
      case 13:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Test-Editor
            </Typography>
            <TestEditor />
          </>
        );
      case 14:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Host-Bereich
            </Typography>
            <HostArea />
          </>
        );
      case 15:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Video Management
            </Typography>
            <VideoManagement /> {/* VideoManagement rendern */}
          </>
        );
      case 16:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Einstellungen aktualisieren
            </Typography>
            <UpdateSettings /> {/* UpdateSettings rendern */}
          </>
        );
      default:
        return <Typography>Inhalt nicht gefunden</Typography>;
    }
  };

  // Definiere den Drawer mit allen Menüelementen
  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Hauptmenü */}
      <Box>
        <Divider />

        {/* Dashboard Button */}
        <List>
          <AnimatedListItem
            button
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon>
              <DashboardIcon
                sx={{ color: selectedIndex === 0 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </AnimatedListItem>
        </List>

        <Divider />

        {/* Benutzerverwaltung */}
        <Typography variant="h6" sx={{ padding: '16px', color: '#fff' }}>
          Benutzer
        </Typography>
        <List>
          <AnimatedListItem
            button
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon>
              <PeopleIcon
                sx={{ color: selectedIndex === 1 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="Benutzerverwaltung" />
          </AnimatedListItem>
          {/* Das Menüelement für Case 2 wurde durch "Daten exportieren" ersetzt */}
          <AnimatedListItem
            button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemIcon>
              <DownloadForOfflineIcon
                sx={{ color: selectedIndex === 2 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="Daten exportieren" />
          </AnimatedListItem>
        </List>

        <Divider />

        {/* Livestreams */}
        <Typography variant="h6" sx={{ padding: '16px', color: '#fff' }}>
          Livestreams
        </Typography>
        <List>
          <AnimatedListItem
            button
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
          >
            <ListItemIcon>
              <StreamIcon
                sx={{ color: selectedIndex === 3 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="Planung" />
          </AnimatedListItem>
          <AnimatedListItem
            button
            selected={selectedIndex === 4}
            onClick={(event) => handleListItemClick(event, 4)}
          >
            <ListItemIcon>
              <PlayArrowIcon
                sx={{ color: selectedIndex === 4 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="Live-Gehen" />
          </AnimatedListItem>
        </List>

        <Divider />

        {/* Lernplattform */}
        <Typography variant="h6" sx={{ padding: '16px', color: '#fff' }}>
          Lernplattform
        </Typography>
        <List>
          <AnimatedListItem
            button
            selected={selectedIndex === 5}
            onClick={(event) => handleListItemClick(event, 5)}
          >
            <ListItemIcon>
              <VideoLibraryIcon
                sx={{ color: selectedIndex === 5 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="System-Einstellungen" />
          </AnimatedListItem>
          <AnimatedListItem
            button
            selected={selectedIndex === 6}
            onClick={(event) => handleListItemClick(event, 6)}
          >
            <ListItemIcon>
              <UploadIcon
                sx={{ color: selectedIndex === 6 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="Email-Template Manager" />
          </AnimatedListItem>
          <AnimatedListItem
            button
            selected={selectedIndex === 15} // Video Management hinzufügen
            onClick={(event) => handleListItemClick(event, 15)}
          >
            <ListItemIcon>
              <VideoLibraryIcon
                sx={{ color: selectedIndex === 15 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="Video Management" />
          </AnimatedListItem>
          <AnimatedListItem
            button
            selected={selectedIndex === 13} // Test-Editor hinzufügen
            onClick={(event) => handleListItemClick(event, 13)}
          >
            <ListItemIcon>
              <AssignmentIcon
                sx={{ color: selectedIndex === 13 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="Test-Editor" />
          </AnimatedListItem>
        </List>

        <Divider />
      </Box>

      {/* Wartung */}
      <Box>
        <Divider />
        <Typography variant="h6" sx={{ padding: '16px', color: '#fff' }}>
          Wartung
        </Typography>
        <List>
          <AnimatedListItem
            button
            selected={selectedIndex === 16}
            onClick={(event) => handleListItemClick(event, 16)}
          >
            <ListItemIcon>
              <BuildIcon
                sx={{ color: selectedIndex === 16 ? '#fff' : '#d5bc8b' }}
              />
            </ListItemIcon>
            <ListItemText primary="Settings Aktualisieren" />
          </AnimatedListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <DrawerContainer
      sx={{
        backgroundImage: `url('/images/backgrounds/admin_background.webp')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            marginTop: '69px', // Anpassung je nach Header-Höhe
            backgroundColor: '#252833',
            color: '#fff',
          },
        }}
      >
        {drawer}
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {renderContent()}
      </Box>
    </DrawerContainer>
  );
};

// Exportiere die AdminDashboard-Komponente mit React.memo zur Leistungsoptimierung
export default React.memo(AdminDashboard);

import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import LeftSidebar from './UserDashboard_components/LeftSidebar';
import RightSidebar from './UserDashboard_components/RightSidebar';
import { getUserData } from './UserDashboard_components/userService';
import { onAuthStateChanged } from 'firebase/auth';
import ExpandableCards from './UserDashboard_components/ExpandableCards';
import { useNavigate } from 'react-router-dom';
import MapComponent from './User_map/MapComponent';

const Container = styled(Box)({
  display: 'flex',
  height: '100vh',
  backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  overflow: 'auto',
});

const Sidebar = styled(Box)({
  flex: '0.5',
  backgroundColor: '#252833',
  maxWidth: '300px',
  padding: '10px',
  overflowY: 'auto',
});

const MainContent = styled(Box)({
  flex: '2',
  padding: '20px',
  backgroundImage: `url('/images/backgrounds/muster.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  margin: '0px 80px 0px 80px',
});

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 255, 0.9)',
  transition: 'opacity 0.3s ease',
  pointerEvents: 'none',
  zIndex: 10,
}));

const MapWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  overflow: 'hidden',
});

const Title = styled(Typography)({
  textAlign: 'left',
  fontSize: '1.5rem',
  fontFamily: 'Jost, sans-serif', // Jost-Schriftart
  color: '#d5bc8b',
  fontWeight: 400, // Nicht fett
  position: 'relative',
  paddingBottom: '4px', // Geringerer Abstand zur Linie
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%', // Durchgängige Unterstreichung
    height: '1px', // Dünnere Linie
    backgroundColor: '#d5bc8b',
  },
});

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchUserData(currentUser.uid);
      } else {
        // Optional: navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchUserData = async (uid) => {
    try {
      const data = await getUserData(uid);
      setUserData(data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Benutzerdaten:', error);
    }
  };

  return (
    <Container id="dashboardContainer">
      <Sidebar>
        <LeftSidebar />
      </Sidebar>
      <MainContent>
        {user && userData ? (
          <Typography variant="h5" sx={{ color: '#d5bc8b', mb: 2 }}>
            Willkommen, {userData.firstName} {userData.lastName}!
          </Typography>
        ) : (
          <Typography variant="h5" sx={{ color: '#d5bc8b', mb: 2 }}>
            Willkommen!
          </Typography>
        )}

        {/* Unterstrichene Überschrift */}
        <Title>Neueste Videos</Title>
        <ExpandableCards />

        {/* Zweite unterstrichene Überschrift */}
        <Box sx={{ mt: 4 }}>
          <Title>Neuigkeiten</Title>
        </Box>
      </MainContent>
      <Sidebar>
        <RightSidebar />

        {/* Nutzerstandorte */}
        <Box
          sx={{
            mt: 2,
            cursor: 'pointer',
            position: 'relative',
            '&:hover .overlay': {
              opacity: 0,
            },
          }}
          onClick={() => navigate('/map')}
        >
          <Typography variant="h6" sx={{ color: '#d5bc8b', mb: 1 }}>
            Nutzerstandorte
          </Typography>
          <MapWrapper
            sx={{
              width: '100%',
              aspectRatio: '1 / 1',
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              position: 'relative',
            }}
          >
            <MapComponent smallMap />
            <Overlay className="overlay" />
          </MapWrapper>
        </Box>
      </Sidebar>
    </Container>
  );
};

export default Dashboard;

// CommentSection.js
import React, { useState, useEffect } from 'react';
import { db } from '../../../../config/firebase'; // Passe den Importpfad an
import Comment from './Comment';
import {
  TextField,
  Button,
  List,
  ListItem,
  Box,
} from '@mui/material';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { useAuth } from '../../../context/AuthContext'; // Passe den Pfad an
import { getUserData } from './userService'; // Importiere getUserData

const CommentSection = ({ postId }) => {
  const { currentUser } = useAuth();
  const [comments, setComments] = useState([]);
  const [commentInput, setCommentInput] = useState('');
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const data = await getUserData(currentUser.uid);
        setUserData(data);
      }
    };
    fetchUserData();
  }, [currentUser]);

  // Echtzeit-Aktualisierung der Kommentare
  useEffect(() => {
    const commentsRef = collection(db, 'posts', postId, 'comments');
    const q = query(commentsRef, orderBy('timestamp', 'asc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const commentsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setComments(commentsData);
    });

    return () => unsubscribe();
  }, [postId]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!commentInput.trim()) return;

    if (!currentUser) {
      alert('Bitte melde dich an, um einen Kommentar zu schreiben.');
      return;
    }

    if (!userData) {
      console.error('Benutzerdaten nicht verfügbar.');
      return;
    }

    try {
      await addDoc(collection(db, 'posts', postId, 'comments'), {
        content: commentInput,
        timestamp: serverTimestamp(),
        authorId: currentUser.uid,
        authorFirstName: userData.firstName || 'Anonym',
        authorLastName: userData.lastName || '',
        authorAvatarPath: userData.profilePicturePath || '',
        likes: [], // Initialisiere likes als leeres Array
      });
      setCommentInput('');
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Kommentars:', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <form onSubmit={handleCommentSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          label="Kommentieren"
          value={commentInput}
          onChange={(e) => setCommentInput(e.target.value)}
        />
        <Button type="submit" variant="contained" sx={{ mt: 1 }}>
          Absenden
        </Button>
      </form>
      <List>
        {comments.map((comment) => (
          <ListItem key={comment.id} alignItems="flex-start">
            <Comment comment={comment} postId={postId} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CommentSection;

// src/components/Administration/UpdateSettings.jsx

import React, { useState } from 'react';
import { db } from '../../config/firebase';
import { 
  collection, 
  getDocs, 
  query, 
  where, 
  addDoc 
} from 'firebase/firestore';
import { Button, Typography, Box, Snackbar, Alert, CircularProgress } from '@mui/material';

const UpdateSettings = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    console.log('UpdateSettings: Aktualisierung gestartet.');

    try {
      const videosRef = collection(db, 'videos');
      const videosSnapshot = await getDocs(videosRef);
      console.log(`UpdateSettings: ${videosSnapshot.size} Videos gefunden.`);

      const themenbereicheSet = new Set();
      const kapitelSet = new Set();

      // Iteriere über jedes Video
      for (const videoDoc of videosSnapshot.docs) {
        const videoId = videoDoc.id;
        console.log(`UpdateSettings: Verarbeite Video ID: ${videoId}`);

        const videoData = videoDoc.data();

        // Extrahiere themenbereich und kapitel
        if (videoData.themenbereich) {
          const themenbereich = videoData.themenbereich.trim();
          themenbereicheSet.add(themenbereich);
          console.log(`UpdateSettings: Themenbereich gefunden - "${themenbereich}"`);
        } else {
          console.warn(`UpdateSettings: Kein 'themenbereich' in Video ID: ${videoId}`);
        }

        if (videoData.kapitel) {
          const kapitel = videoData.kapitel.trim();
          kapitelSet.add(kapitel);
          console.log(`UpdateSettings: Kapitel gefunden - "${kapitel}"`);
        } else {
          console.warn(`UpdateSettings: Kein 'kapitel' in Video ID: ${videoId}`);
        }
      }

      console.log(`UpdateSettings: Insgesamt ${themenbereicheSet.size} eindeutige Themenbereiche und ${kapitelSet.size} eindeutige Kapitel gesammelt.`);

      // Aktualisiere Themenbereiche in settings
      const themenbereicheTargetRef = collection(db, 'settings', 'themenbereiche', 'list');
      for (const name of themenbereicheSet) {
        console.log(`UpdateSettings: Überprüfe Themenbereich: "${name}"`);
        const q = query(themenbereicheTargetRef, where('name', '==', name));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          await addDoc(themenbereicheTargetRef, { name });
          console.log(`UpdateSettings: Themenbereich hinzugefügt - "${name}"`);
        } else {
          console.log(`UpdateSettings: Themenbereich bereits vorhanden - "${name}"`);
        }
      }

      // Aktualisiere Kapitel in settings
      const kapitelTargetRef = collection(db, 'settings', 'kapitel', 'list');
      for (const name of kapitelSet) {
        console.log(`UpdateSettings: Überprüfe Kapitel: "${name}"`);
        const q = query(kapitelTargetRef, where('name', '==', name));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          await addDoc(kapitelTargetRef, { name });
          console.log(`UpdateSettings: Kapitel hinzugefügt - "${name}"`);
        } else {
          console.log(`UpdateSettings: Kapitel bereits vorhanden - "${name}"`);
        }
      }

      console.log('UpdateSettings: Settings wurden erfolgreich aktualisiert.');
      setMessage('Settings wurden erfolgreich aktualisiert.');
      setSeverity('success');
      setOpen(true);
    } catch (error) {
      console.error('UpdateSettings: Fehler beim Aktualisieren der Settings:', error);
      setMessage('Fehler beim Aktualisieren der Settings.');
      setSeverity('error');
      setOpen(true);
    } finally {
      setLoading(false);
      console.log('UpdateSettings: Aktualisierung abgeschlossen.');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Settings Aktualisieren
      </Typography>
      <Typography variant="body1" gutterBottom>
        Diese Aktion durchsucht alle vorhandenen Videos und fügt die in den Videos verwendeten Themenbereiche und Kapitel zur Settings-Sammlung hinzu, falls sie dort noch nicht vorhanden sind.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleUpdate}
        disabled={loading}
      >
        Settings Aktualisieren
      </Button>
      {loading && <CircularProgress size={24} sx={{ ml: 2 }} />}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default React.memo(UpdateSettings);

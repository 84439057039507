// src/components/Administration/VideoManagement/AddVideoModal.jsx

import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  LinearProgress,
  Snackbar,
  IconButton,
  Chip,
  OutlinedInput,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
} from '@mui/material';
import { storage, db } from '../../../config/firebase'; // Passen Sie den Importpfad an Ihre Projektstruktur an
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, getDocs } from 'firebase/firestore';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';

const STATUS_OPTIONS = [
  { value: 'Entwurf', color: '#007BFF' },
  { value: 'veröffentlicht', color: '#28a745' },
  { value: 'inaktiv', color: '#6c757d' },
];

// Liste der Optionen für "Was wird durch die Übung gefördert"
const WAS_GEFOERDET_OPTIONS = [
  'feinere Hilfen',
  'mehr Sicherheit',
  'mehr Motivation',
  'mehr Entspannung',
  'mehr Abwechslung',
  'meine Körpersprache',
  'Einschätzen von Situationen',
  'klare Signale geben',
  'verbessertes Druckverständnis',
  'Sicher Stoppen',
  'Gelassenheit mit Gegenständen und Situationen',
  'mehr Neugierde',
  'Umgang mit Berührung',
  'Kontrollierbarkeit',
  'Hilfe zur Deeskalation',
  'Ansprechbarkeit',
  'Gymnastizierung des Pferdes',
  'Körpergefühl',
  'Wissen über Training',
  'Mentale Einstellung des Menschens',
  'mehr Impulsion',
  'Zuverlässige Freiarbeit',
  'Komplexere Kommunikation',
  'Spiel und Spaß',
  'Energie bündeln',
  'Sicheres Reitpferd',
  'Umgang mit Futter',
  'mein Timing',
];

// Liste der Optionen für "Körperlicher Anspruch"
const KOERPERLICHER_ANSPRUCH_OPTIONS = [
  'Schritt',
  'Trab',
  'Galopp',
  'enge Wendung',
  'dynamische Bewegung',
  'anspruchsvoller Untergrund',
  'Biegung',
  'Sprung',
  'Seitliche Bewegung',
  'Freilauf',
  'Isolierte Aktivität der Vorderbeine',
  'Isolierte Aktivität der Hinterbeine',
  'Reiten (ausschließlich)',
  'Rückwärts',
  'Kreisbahn',
  'Direkter Stopp',
  'Reiten (teilweise)',
  'Seitliche Bewegung',
];

// Liste der Optionen für "Elemente der Übung"
const ELEMENTE_DER_UEBUNG_OPTIONS = [
  'Vorhand weichen',
  'Hinterhand weichen',
  'Stoppen',
  'Stehen bleiben',
  'Rückwärts gehen',
  'Vorhand herkommen',
  'Hinterhand herkommen',
  'Schneller innerhalb der Gangart',
  'Langsamer innerhalb der Gangart',
  'Einladen',
  'Seitlichen Abstand vergrößern',
  'Seitlichen Abstand verkleinern',
  'Gangart erhöhen',
  'Gangart verringern',
  'Tempo halten',
  'Mittelhand weichen',
  'Antreten',
  'Umgang mit Gegenständen, welche auf das Pferd zukommen',
  'Stellung oder Biegung im Hals',
  'Biegung um den inneren Schenkel',
  'Mittelhand herkommen',
  'Vorderbein anheben',
  'Hinterbein anheben',
];

// Liste der Optionen für "Elemente der Übung - Reiten"
const ELEMENTE_DER_UEBUNG_REITEN_OPTIONS = [
  'Antreten',
  'Schulterkontrolle',
  'Hinterhandkontrolle',
  'Stoppen',
  'Stehen bleiben',
  'Rückwärts gehen',
  'Schneller innerhalb der Gangart',
  'Langsamer innerhalb der Gangart',
  'Gangart erhöhen',
  'Gangart verringern',
  'Tempo halten',
  'Schenkel weichen',
  'Stellung oder Biegung im Hals',
  'Biegung um den inneren Schenkel',
  'Unter das Gewicht treten',
];

const AddVideoModal = ({ open, onClose, onSubmit, themenbereich, kapitel }) => {
  const playerRef = useRef(null);
  const inputFileRef = useRef(null); // Ref für das Datei-Input

  // Video upload states
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoURL, setVideoURL] = useState('');
  const [vimeoID, setVimeoID] = useState('');
  const [vimeoLink, setVimeoLink] = useState('');

  // Snackbar state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Confirmation Dialog state
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // Input field states
  const [formData, setFormData] = useState({
    video_titel: '',
    status: 'veröffentlicht',
    themenbereiche: [],
    text: '',
    was_gefoerdert: [],
    p_koerperlicher_anspruch: [],
    p_elemente: [],
    p_elemente_reiten: [],
    // Weitere Felder hinzufügen
    // ...
  });

  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  // States for chapters (sections)
  const [abschnitte, setAbschnitte] = useState([]);

  // Video control states
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seeking, setSeeking] = useState(false);

  // Current upload step
  const [uploadStep, setUploadStep] = useState('');

  // Storage path of the uploaded video for deletion
  const [uploadedStoragePath, setUploadedStoragePath] = useState(null);

  // Load options from the database
  const [themenbereicheOptions, setThemenbereicheOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch options from the database (/settings/themenbereiche/list)
        const settingsRef = collection(db, 'settings', 'themenbereiche', 'list');
        const settingsSnapshot = await getDocs(settingsRef);
        const options = settingsSnapshot.docs.map((doc) => doc.data().name);
        setThemenbereicheOptions(options);
      } catch (error) {
        console.error('Fehler beim Laden der Themenbereiche:', error);
      }
    };
    fetchOptions();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Sanitization-Funktion für den Titel
  const sanitizeTitle = (title) => {
    // Ersetzen Sie alle nicht-alphanumerischen Zeichen durch Unterstriche und konvertieren Sie in Kleinbuchstaben
    return title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  };

  // Function to upload the video
  const handleVideoUpload = (file) => {
    if (!file) return;

    const sanitizedTitle = sanitizeTitle(formData.video_titel.trim());
    const originalExtension = file.name.substring(file.name.lastIndexOf('.')); // Beibehalten der Original-Dateiendung
    const newFileName = `${sanitizedTitle}${originalExtension}`;
    const storagePath = `videos/${sanitizedTitle}/${newFileName}`;
    const storageRefObj = ref(storage, storagePath);
    const uploadTask = uploadBytesResumable(storageRefObj, file);

    setUploadStep('Starte den Upload des Videos...');

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (
          (snapshot.bytesTransferred / snapshot.totalBytes) *
          100
        ).toFixed(2);
        setUploadProgress(progress);
        setUploadStep(`Upload läuft: ${progress}% abgeschlossen.`);
      },
      (error) => {
        console.error('Fehler beim Hochladen:', error);
        setSnackbarMessage('Fehler beim Hochladen des Videos.');
        setOpenSnackbar(true);
        setUploadStep('');
        // Reset file input nach Fehler
        if (inputFileRef.current) {
          inputFileRef.current.value = null;
        }
      },
      async () => {
        try {
          setUploadStep('Upload abgeschlossen. Hole die Download-URL...');
          // Get file URL from Firebase Storage
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setVideoURL(downloadURL);

          setUploadStep('Sende Video zur Verarbeitung an Vimeo...');
          // Call the Cloud Function to upload to Vimeo
          const response = await fetch(
            'https://europe-west3-keytohorses-firebase.cloudfunctions.net/api/uploadToVimeo',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 
                storagePath, 
                fileName: newFileName,
                title: formData.video_titel.trim() // Hinzufügen des Titels
              }),
            }
          );

          if (response.ok) {
            setUploadStep('Verarbeite die Antwort von Vimeo...');
            const data = await response.json();
            const vimeoUrl = data.vimeoUrl;

            // Verarbeitung des erhaltenen vimeoUrl
            let fullVimeoUrl = vimeoUrl;

            // Überprüfen, ob vimeoUrl ein relativer Pfad ist
            if (vimeoUrl.startsWith('/videos/')) {
              const videoId = vimeoUrl.split('/videos/')[1];
              fullVimeoUrl = `https://vimeo.com/${videoId}`;
            }

            setVimeoLink(fullVimeoUrl);
            setVimeoID(fullVimeoUrl); // Save Vimeo ID in gewünschtem Format
            setUploadStep('Video erfolgreich zu Vimeo hochgeladen!');
            setSnackbarMessage('Video erfolgreich zu Vimeo hochgeladen!');
            setOpenSnackbar(true);

            // Speichern des Speicherpfads für die spätere Löschung
            setUploadedStoragePath(storagePath);
          } else {
            console.error(
              'Fehler beim Upload zu Vimeo:',
              response.statusText
            );
            setSnackbarMessage(`Fehler beim Upload zu Vimeo: ${response.statusText}`);
            setUploadStep('');
          }
        } catch (error) {
          console.error('Fehler beim Upload zu Vimeo:', error);
          setSnackbarMessage(`Fehler beim Upload zu Vimeo: ${error.message}`);
          setUploadStep('');
        } finally {
          // Reset file input nach Abschluss (erfolgreich oder nicht)
          if (inputFileRef.current) {
            inputFileRef.current.value = null;
          }
        }
      }
    );
  };

  // Function to delete the video from Firebase Storage
  const deleteUploadedVideo = async () => {
    if (!uploadedStoragePath) return;

    const videoRef = ref(storage, uploadedStoragePath);
    try {
      await deleteObject(videoRef);
      console.log('Video erfolgreich von Firebase Storage gelöscht.');
    } catch (error) {
      console.error('Fehler beim Löschen des Videos:', error);
      // Optional: Setzen Sie eine Snackbar-Nachricht, um den Benutzer über das Problem zu informieren
      setSnackbarMessage('Fehler beim Löschen des Videos von Firebase Storage.');
      setOpenSnackbar(true);
    } finally {
      setUploadedStoragePath(null);
    }
  };

  // Function to delete the video from UI and Firebase
  const handleVideoDelete = () => {
    setVideoURL('');
    setUploadProgress(0);
    setVimeoLink('');
    setVimeoID('');
    setThumbnailUrl(null);
    setUploadStep('');
    // Reset file input
    if (inputFileRef.current) {
      inputFileRef.current.value = null;
    }
    // Löschen des hochgeladenen Videos von Firebase Storage
    deleteUploadedVideo();
  };

  // Functions for chapters (sections)
  const handleAddAbschnitt = () => {
    const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : 0;
    setAbschnitte([
      ...abschnitte,
      { start: currentTime, titel: '', beschreibung: '' },
    ]);
  };

  const handleAbschnittChange = (index, field, value) => {
    const newAbschnitte = [...abschnitte];
    newAbschnitte[index][field] = value;
    setAbschnitte(newAbschnitte);
  };

  const handleRemoveAbschnitt = (index) => {
    const newAbschnitte = abschnitte.filter((_, i) => i !== index);
    setAbschnitte(newAbschnitte);
  };

  // Video control functions
  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleProgress = (state) => {
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    playerRef.current.seekTo(parseFloat(e.target.value));
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Sicherstellen, dass ein Titel eingegeben wurde
    if (formData.video_titel.trim() === '') {
      setSnackbarMessage('Bitte geben Sie einen Videotitel ein.');
      setOpenSnackbar(true);
      return;
    }

    // Sicherstellen, dass das Video erfolgreich hochgeladen wurde
    if (!vimeoID) {
      setSnackbarMessage('Bitte laden Sie ein Video hoch, bevor Sie das Formular absenden.');
      setOpenSnackbar(true);
      return;
    }

    // Formdaten zusammenstellen
    const videoData = {
      video_titel: formData.video_titel.trim(),
      status: formData.status,
      themenbereich: themenbereich,
      kapitel: kapitel,
      themenbereiche: formData.themenbereiche,
      text: formData.text,
      was_gefoerdert: formData.was_gefoerdert,
      p_koerperlicher_anspruch: formData.p_koerperlicher_anspruch,
      p_elemente: formData.p_elemente,
      p_elemente_reiten: formData.p_elemente_reiten,
      vimeoId: vimeoID,
      thumbnailUrl: thumbnailUrl,
      abschnitte: abschnitte,
      // Weitere Felder hinzufügen
      // ...
    };

    onSubmit(videoData);
    // Reset form after submission
    setFormData({
      video_titel: '',
      status: 'veröffentlicht',
      themenbereiche: [],
      text: '',
      was_gefoerdert: [],
      p_koerperlicher_anspruch: [],
      p_elemente: [],
      p_elemente_reiten: [],
      // Weitere Felder zurücksetzen
      // ...
    });
    handleVideoDelete();
  };

  // Handle Modal Close with Confirmation
  const handleCloseModal = () => {
    if (
      formData.video_titel ||
      formData.status !== 'veröffentlicht' ||
      formData.themenbereiche.length > 0 ||
      formData.text ||
      formData.was_gefoerdert.length > 0 ||
      formData.p_koerperlicher_anspruch.length > 0 ||
      formData.p_elemente.length > 0 ||
      formData.p_elemente_reiten.length > 0 ||
      abschnitte.length > 0 ||
      videoURL
    ) {
      setOpenConfirmDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirmClose = async () => {
    // Löschen des hochgeladenen Videos von Firebase Storage
    await deleteUploadedVideo();

    // Reset all states
    setFormData({
      video_titel: '',
      status: 'veröffentlicht',
      themenbereiche: [],
      text: '',
      was_gefoerdert: [],
      p_koerperlicher_anspruch: [],
      p_elemente: [],
      p_elemente_reiten: [],
      // Weitere Felder zurücksetzen
      // ...
    });
    setVideoURL('');
    setUploadProgress(0);
    setVimeoLink('');
    setVimeoID('');
    setThumbnailUrl(null);
    setAbschnitte([]);
    setUploadStep('');
    setOpenConfirmDialog(false);
    onClose();
    // Reset file input
    if (inputFileRef.current) {
      inputFileRef.current.value = null;
    }
  };

  const handleCancelClose = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '95%', md: '80%', lg: '60%' },
            maxHeight: '95vh',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* Header mit Titel und Close-Button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Neues Video hinzufügen</Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid container spacing={4}>
            {/* Linke Spalte */}
            <Grid item xs={12} md={6}>
              {/* Themenbereich und Kapitel Informationen */}
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="subtitle1" color="text.secondary">
                    <strong>Themenbereich:</strong> {themenbereich}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    <strong>Kapitel:</strong> {kapitel}
                  </Typography>
                </CardContent>
              </Card>

              <form onSubmit={handleSubmit}>
                <TextField
                  label="Videotitel"
                  name="video_titel"
                  value={formData.video_titel}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="normal"
                />

                {/* Status Dropdown */}
                <FormControl fullWidth margin="normal">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    label="Status"
                  >
                    {STATUS_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Themenbereiche (Multiple Selection with Chips) */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="themenbereiche-label">Themenbereiche</InputLabel>
                  <Select
                    labelId="themenbereiche-label"
                    name="themenbereiche"
                    multiple
                    value={formData.themenbereiche}
                    onChange={handleChange}
                    input={<OutlinedInput label="Themenbereiche" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() =>
                              setFormData((prev) => ({
                                ...prev,
                                themenbereiche: prev.themenbereiche.filter(
                                  (v) => v !== value
                                ),
                              }))
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {themenbereicheOptions.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                    {/* Option zum Hinzufügen neuer Themenbereiche kann hier eingefügt werden */}
                  </Select>
                </FormControl>

                {/* Was wird durch die Übung gefördert (Multiple Selection with Chips) */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="was-gefoerdert-label">Was wird durch die Übung gefördert</InputLabel>
                  <Select
                    labelId="was-gefoerdert-label"
                    name="was_gefoerdert"
                    multiple
                    value={formData.was_gefoerdert}
                    onChange={handleChange}
                    input={<OutlinedInput label="Was wird durch die Übung gefördert" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() =>
                              setFormData((prev) => ({
                                ...prev,
                                was_gefoerdert: prev.was_gefoerdert.filter(
                                  (v) => v !== value
                                ),
                              }))
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {WAS_GEFOERDET_OPTIONS.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Körperlicher Anspruch (Multiple Selection with Chips) */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="koerperlicher-anspruch-label">Körperlicher Anspruch</InputLabel>
                  <Select
                    labelId="koerperlicher-anspruch-label"
                    name="p_koerperlicher_anspruch"
                    multiple
                    value={formData.p_koerperlicher_anspruch}
                    onChange={handleChange}
                    input={<OutlinedInput label="Körperlicher Anspruch" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() =>
                              setFormData((prev) => ({
                                ...prev,
                                p_koerperlicher_anspruch: prev.p_koerperlicher_anspruch.filter(
                                  (v) => v !== value
                                ),
                              }))
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {KOERPERLICHER_ANSPRUCH_OPTIONS.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Elemente der Übung (Multiple Selection with Chips) */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="elemente-der-uebung-label">Elemente der Übung</InputLabel>
                  <Select
                    labelId="elemente-der-uebung-label"
                    name="p_elemente"
                    multiple
                    value={formData.p_elemente}
                    onChange={handleChange}
                    input={<OutlinedInput label="Elemente der Übung" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() =>
                              setFormData((prev) => ({
                                ...prev,
                                p_elemente: prev.p_elemente.filter(
                                  (v) => v !== value
                                ),
                              }))
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {ELEMENTE_DER_UEBUNG_OPTIONS.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Elemente der Übung - Reiten (Multiple Selection with Chips) */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="elemente-der-uebung-reiten-label">Elemente der Übung - Reiten</InputLabel>
                  <Select
                    labelId="elemente-der-uebung-reiten-label"
                    name="p_elemente_reiten"
                    multiple
                    value={formData.p_elemente_reiten}
                    onChange={handleChange}
                    input={<OutlinedInput label="Elemente der Übung - Reiten" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() =>
                              setFormData((prev) => ({
                                ...prev,
                                p_elemente_reiten: prev.p_elemente_reiten.filter(
                                  (v) => v !== value
                                ),
                              }))
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {ELEMENTE_DER_UEBUNG_REITEN_OPTIONS.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Textfeld für Videobeschreibung */}
                <TextField
                  label="Videobeschreibung"
                  name="text"
                  value={formData.text}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                />

                {/* Weitere Felder hinzufügen */}
                {/* ... */}

                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Hinzufügen
                </Button>
              </form>
            </Grid>

            {/* Rechte Spalte für Videoupload und Vorschau */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: '#f0f0f0',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  p: 2,
                }}
              >
                {/* Upload-Button */}
                {!videoURL && (
                  <Tooltip
                    title={
                      formData.video_titel.trim() === ''
                        ? 'Bitte geben Sie zuerst einen Videotitel ein.'
                        : 'Video hochladen'
                    }
                  >
                    <span>
                      {/* Wrapper <span> für Tooltip mit disabled IconButton */}
                      <IconButton
                        color="primary"
                        aria-label="upload video"
                        component="label"
                        disabled={formData.video_titel.trim() === ''}
                        sx={{
                          width: 100,
                          height: 100,
                          bgcolor: 'white',
                          boxShadow: 3,
                          borderRadius: '50%',
                          '&:hover': {
                            bgcolor: 'primary.light',
                          },
                          position: 'relative',
                        }}
                      >
                        <input
                          type="file"
                          accept="video/*"
                          hidden
                          ref={inputFileRef}
                          onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                              const file = e.target.files[0];
                              handleVideoUpload(file); // Direktes Übergeben der Datei
                            }
                          }}
                        />
                        <UploadIcon sx={{ fontSize: 50 }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

                {/* LinearProgress unterhalb des Upload-Buttons */}
                {uploadProgress > 0 && uploadProgress < 100 && (
                  <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      {uploadStep}
                    </Typography>
                    <LinearProgress variant="determinate" value={Number(uploadProgress)} />
                  </Box>
                )}

                {/* Videovorschau */}
                {videoURL && (
                  <Box sx={{ width: '100%', height: '100%', position: 'relative', mt: 2 }}>
                    <ReactPlayer
                      url={videoURL}
                      playing={playing}
                      ref={playerRef}
                      width="100%"
                      height="100%"
                      controls={true}
                      onProgress={handleProgress}
                      onDuration={handleDuration}
                    />
                    {/* Delete-Button über dem Video */}
                    <IconButton
                      onClick={handleVideoDelete}
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        bgcolor: 'rgba(255,255,255,0.7)',
                        '&:hover': {
                          bgcolor: 'rgba(255,255,255,1)',
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          {/* Snackbar */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={() => setOpenSnackbar(false)}
            message={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleIcon style={{ marginRight: 8 }} />
                {snackbarMessage || 'Video erfolgreich hochgeladen!'}
              </span>
            }
            action={
              <IconButton
                size="small"
                color="inherit"
                onClick={() => setOpenSnackbar(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />

          {/* Bestätigungsdialog beim Schließen */}
          <Dialog
            open={openConfirmDialog}
            onClose={handleCancelClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle id="confirm-dialog-title">Modal schließen?</DialogTitle>
            <DialogContent>
              <DialogContentText id="confirm-dialog-description">
                Sind Sie sicher, dass Sie das Modal schließen möchten? Alle eingegebenen Informationen gehen verloren.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClose} color="primary">
                Abbrechen
              </Button>
              <Button onClick={handleConfirmClose} color="secondary" autoFocus>
                Schließen
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
    </>
  );
};

export default AddVideoModal;

// src/components/Administration/Livestream/CreateMeeting.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Box,
  Avatar,
  Typography,
  Slider,
  LinearProgress,
} from '@mui/material';
import {
  collection,
  setDoc,
  Timestamp,
  doc,
  getDocs,
  query,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../../config/firebase';
import { v4 as uuidv4 } from 'uuid';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../../components/Livestream/cropImage';
import { useNavigate } from 'react-router-dom';

const CreateMeeting = ({ open, handleClose, meetingToEdit }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const isEditMode = Boolean(meetingToEdit);

  // Form state
  const [title, setTitle] = useState('');
  const [startTime, setStartTime] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [meetingPassword, setMeetingPassword] = useState('');
  const [trainerId, setTrainerId] = useState('');
  const [trainerName, setTrainerName] = useState('');
  const [trainers, setTrainers] = useState([]);
  const [loadingTrainers, setLoadingTrainers] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  // Image state
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [headerImageUrl, setHeaderImageUrl] = useState('');

  // Fetch Trainer Daten beim Mounten
  useEffect(() => {
    const fetchTrainers = async () => {
      setLoadingTrainers(true);
      try {
        const q = query(collection(db, 'users'));
        const snapshot = await getDocs(q);
        const trainersData = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          trainersData.push({
            id: doc.id,
            firstName: data.firstName,
            lastName: data.lastName,
            avatarUrl: data.avatarUrl || '/images/default-avatar.png',
          });
        });
        setTrainers(trainersData);
        console.log('Trainer Daten geladen:', trainersData);
      } catch (error) {
        console.error('Fehler beim Abrufen der Trainerdaten:', error);
      } finally {
        setLoadingTrainers(false);
      }
    };

    fetchTrainers();
  }, []);

  // Formulareinstellungen für den Edit-Modus
  useEffect(() => {
    if (isEditMode && meetingToEdit) {
      setTitle(meetingToEdit.title || '');
      setStartTime(formatDateForInput(meetingToEdit.start_time.toDate()));
      setMeetingLink(meetingToEdit.meetingLink || '');
      setMeetingPassword(meetingToEdit.meetingPassword || '');
      setTrainerId(meetingToEdit.trainerId || '');
      setTrainerName(meetingToEdit.trainerName || '');

      if (meetingToEdit.headerImageUrl) {
        setHeaderImageUrl(meetingToEdit.headerImageUrl);
      }
    }
  }, [isEditMode, meetingToEdit]);

  // Formatieren des Datums für das Eingabefeld
  const formatDateForInput = (date) => {
    if (!(date instanceof Date)) {
      console.error('Expected a Date object');
      return '';
    }
    const pad = (num) => num.toString().padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Handler für das Formular-Submit
  const handleSubmit = async () => {
    console.log('handleSubmit aufgerufen');
    if (!title || !startTime || !meetingLink || !trainerId) {
      alert('Bitte fülle alle Pflichtfelder aus.');
      console.log('Fehlende Pflichtfelder.');
      return;
    }

    const date = new Date(startTime);
    if (isNaN(date.getTime())) {
      alert('Bitte ein gültiges Datum/Zeit-Format verwenden.');
      console.log('Ungültiges Datum/Zeit-Format.');
      return;
    }

    const startTimestamp = Timestamp.fromDate(date);

    // Holen des Trainer-Namens
    const selectedTrainer = trainers.find(
      (trainer) => trainer.id === trainerId
    );
    const trainerFullName = selectedTrainer
      ? `${selectedTrainer.firstName} ${selectedTrainer.lastName}`
      : '';

    const meetingData = {
      title,
      start_time: startTimestamp,
      meetingLink,
      meetingPassword: meetingPassword || '',
      trainerId,
      trainerName: trainerFullName,
      updated_at: Timestamp.now(),
      headerImagePath: '', // Wird später aktualisiert
      headerImageUrl: '', // Wird später aktualisiert
      created_at: isEditMode ? meetingToEdit.created_at : Timestamp.now(),
      creatorId: currentUser ? currentUser.uid : null,
    };

    setSubmitting(true);
    setUploadingImage(true);
    setUploadProgress(0);

    try {
      let meetingId;

      if (isEditMode) {
        // Edit-Modus: Meeting aktualisieren
        console.log('Aktualisiere bestehendes Meeting:', meetingToEdit.id);
        meetingId = meetingToEdit.id;
        const meetingRef = doc(db, 'meetings', meetingId);
        await setDoc(meetingRef, meetingData, { merge: true });
        console.log('Meeting aktualisiert mit ID:', meetingId);
      } else {
        // Erstellungsmodus: Neues Meeting mit manueller meetingId erstellen
        meetingId = uuidv4();
        console.log('Erstelle neues Meeting mit ID:', meetingId);
        console.log('Erstelle neues Meeting mit Daten:', meetingData);

        const meetingDocRef = doc(db, 'meetings', meetingId);
        await setDoc(meetingDocRef, meetingData);
        console.log('Meeting erstellt mit ID:', meetingDocRef.id);
        console.log('Meeting ID:', meetingId);
      }

      // Wenn ein Bild ausgewählt ist, lade es hoch
      if (imageSrc) {
        const blob = await getCroppedImg(imageSrc, croppedAreaPixels);
        console.log('croppedImage blob:', blob);

        const uniqueId = uuidv4();
        const storageRefObj = ref(
          storage,
          `Livemeetings/header_pictures/header_${uniqueId}.jpg`
        );

        const uploadTask = uploadBytesResumable(storageRefObj, blob);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            console.log('Upload is ' + progress + '% done');
          },
          (error) => {
            console.error('Fehler beim Hochladen des Bildes:', error);
            alert(
              'Fehler beim Hochladen des Bildes. Siehe Konsole für Details.'
            );
            setUploadingImage(false);
            setSubmitting(false);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log('downloadURL:', downloadURL);

            // Aktualisiere das Meeting-Dokument mit dem Bildpfad und der URL
            const meetingDocRef = doc(db, 'meetings', meetingId);
            await updateDoc(meetingDocRef, {
              headerImagePath: `Livemeetings/header_pictures/header_${uniqueId}.jpg`,
              headerImageUrl: downloadURL,
              updated_at: serverTimestamp(),
            });
            console.log('Firestore-Dokument aktualisiert mit Bild.');

            setHeaderImageUrl(downloadURL);
            alert('Meeting erfolgreich erstellt und Bild hochgeladen.');
            setUploadingImage(false);
            setSubmitting(false);
            handleClose();
            navigate(`/meetings/${meetingId}`);
          }
        );
      } else {
        alert('Meeting erfolgreich erstellt.');
        setUploadingImage(false);
        setSubmitting(false);
        handleClose();
        navigate(`/meetings/${meetingId}`);
      }
    } catch (error) {
      console.error('Fehler beim Erstellen/Aktualisieren des Meetings:', error);
      alert(
        'Fehler beim Erstellen/Aktualisieren des Meetings. Siehe Konsole für Details.'
      );
      setUploadingImage(false);
      setSubmitting(false);
    }
  };

  // Event-Handler für das Abschließen des Croppings
  const onCropCompleteHandler = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
      console.log('Cropped Area Pixels:', croppedAreaPixels);
    },
    []
  );

  // Formular zurücksetzen, wenn der Dialog geschlossen wird
  useEffect(() => {
    if (!open) {
      setTitle('');
      setStartTime('');
      setMeetingLink('');
      setMeetingPassword('');
      setTrainerId('');
      setTrainerName('');
      setImageSrc(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedAreaPixels(null);
      setHeaderImageUrl('');
      setUploadProgress(0);
      console.log('Formular zurückgesetzt.');
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: '1.5rem' }}>
        {isEditMode ? 'Meeting bearbeiten' : 'Neues Meeting erstellen'}
      </DialogTitle>
      <DialogContent>
        {/* Bildauswahl */}
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 300,
            backgroundColor: '#f0f0f0',
            borderRadius: '8px',
            mb: 2,
          }}
        >
          {imageSrc ? (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropCompleteHandler}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => document.getElementById('image-input').click()}
            >
              <Typography variant="h6" color="#d5bc8b">
                Klicke hier, um ein Header-Bild auszuwählen
              </Typography>
              <input
                type="file"
                accept="image/*"
                id="image-input"
                hidden
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                      setImageSrc(reader.result);
                      console.log('Bild geladen:', reader.result);
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
            </Box>
          )}
        </Box>

        {/* Zoom Slider */}
        {imageSrc && (
          <Box sx={{ width: '100%', mb: 2 }}>
            <Typography gutterBottom>Zoom</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, zoom) => setZoom(zoom)}
              aria-labelledby="Zoom"
              sx={{ color: '#d5bc8b' }}
            />
          </Box>
        )}

        {/* Upload Progress */}
        {uploadingImage && (
          <Box sx={{ width: '100%', mb: 2 }}>
            <Typography gutterBottom>
              Bild wird hochgeladen: {Math.round(uploadProgress)}%
            </Typography>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Box>
        )}

        {/* Formularfelder */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Titel"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            sx={{ fontSize: '1.2rem' }}
          />
          <TextField
            label="Startzeit"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            required
          />
          <TextField
            label="Meeting-Link"
            type="url"
            value={meetingLink}
            onChange={(e) => setMeetingLink(e.target.value)}
            required
          />
          <TextField
            label="Meeting-Passwort"
            type="text"
            value={meetingPassword}
            onChange={(e) => setMeetingPassword(e.target.value)}
            helperText="Optional"
          />
          <FormControl required>
            <InputLabel id="trainer-select-label">Trainer</InputLabel>
            <Select
              labelId="trainer-select-label"
              value={trainerId}
              label="Trainer"
              onChange={(e) => setTrainerId(e.target.value)}
            >
              {loadingTrainers ? (
                <MenuItem value="">
                  <em>Lade Trainer...</em>
                </MenuItem>
              ) : (
                trainers.map((trainer) => (
                  <MenuItem key={trainer.id} value={trainer.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={trainer.avatarUrl}
                        alt={`${trainer.firstName} ${trainer.lastName}`}
                        sx={{ mr: 1 }}
                      />
                      <Typography>
                        {trainer.firstName} {trainer.lastName}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
          disabled={submitting || uploadingImage}
        >
          Abbrechen
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={submitting || uploadingImage}
        >
          {submitting || uploadingImage ? (
            <CircularProgress size={24} />
          ) : isEditMode ? (
            'Aktualisieren'
          ) : (
            'Erstellen'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMeeting;

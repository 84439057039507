// src/components/pages/ErrorPage.js

import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Fehler beim Laden der Seite
      </Typography>
      <Typography variant="body1" gutterBottom>
        Es scheint, als ob einige benötigte Daten fehlen. Bitte starte den Test
        erneut.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/Einschaetzungstest')}
        sx={{ mt: 3 }}
      >
        Test starten
      </Button>
    </Box>
  );
};

export default ErrorPage;
